import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RouteConfig from '../../config/RouteConfig';
import { FormContext, LoginResponseCode, RegisterAction } from '../../providers/FormProvider';
import { getEwayObject } from '../ewayObjectHelper';

const useLogin = () => {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const { setRegisterAction } = useContext(FormContext);
    const navigate = useNavigate();
    const eway = getEwayObject();

    const onLogin = (wsUrl?: string, userName?: string) => {
        setIsLoggingIn(true);
        setRegisterAction(RegisterAction.SignIn);

        eway.login(
            (loginResponse) => {
                // This callback is only for desktop Outlook

                setIsLoggingIn(false);

                console.log(`Login response: ${loginResponse}`); // TODO: Remove after #45637 is resolved

                if (loginResponse === null || loginResponse === LoginResponseCode.Invalid || !eway.isOutlookClient()) {
                    // Do nothing, invalid login is handled in eWay.
                    console.warn(`Login failed.`);
                    return;
                }

                switch (loginResponse) {
                    case LoginResponseCode.Success:
                        setRegisterAction(RegisterAction.SignInNoImport);
                        break;

                    case LoginResponseCode.SuccessFree:
                        setRegisterAction(RegisterAction.SignInNoImportFree);
                        break;

                    case LoginResponseCode.SuccessContinueWithImport:
                        setRegisterAction(RegisterAction.SignIn);
                        break;

                    case LoginResponseCode.SuccessContinueWithImportFree:
                        setRegisterAction(RegisterAction.SignInFree);
                        break;
                }

                eway.createLocalDatabase &&
                eway.createLocalDatabase(
                    0, // Blank database
                    null,
                    null,
                    null,
                    null,
                    null
                );
                navigate(RouteConfig.outlookPages.finishConfiguration);
            },
            wsUrl,
            userName
        );
    };

    return { isLoggingIn, onLogin };
};

export default useLogin;
