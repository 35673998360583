import RouteConfig from './config/RouteConfig';
import { Route, Routes, Navigate } from 'react-router-dom';
import IntroPage from './pages/IntroPage/IntroPage';
import EmailVerificationPage from './pages/EmailVerificationPage/EmailVerificationPage';
import UserInfoPage from './pages/UserInfoPage/UserInfoPage';
import ChoosePasswordPage from './pages/ChoosePasswordPage/ChoosePasswordPage';
import CompanyInfoPage from './pages/CompanyInfoPage/CompanyInfoPage';
import FinalizationPage from './pages/FinalizationPage/FinalizationPage';
import PageProgress from './components/PageProgress/PageProgress';
import FinalizationFailedPage from './pages/FinalizationFailedPage/FinalizationFailedPage';
import { getEwayObject } from './helpers/ewayObjectHelper';
import { useEffect } from 'react';
import FinishConfigurationPage from './pages/FinishConfigurationPage/FinishConfigurationPage';
import ScrollToTop from './helpers/ScrollToTop';
import ImportDataPage from './pages/ImportDataPage/ImportDataPage';
import ImportFromOutlookPage from './pages/ImportFromOutlookPage/ImportFromOutlookPage';
import ImportProgressPage from './pages/ImportProgressPage/ImportProgressPage';
import EwaySuccessPage from './pages/EwaySuccessPage/EwaySuccessPage';
import Strings from './localization/strings';
import LoginPage from './pages/LoginPage/LoginPage';
import PageErrorBoundary from './components/PageErrorBoundary';
import WebServiceDeletedPage from './pages/WebServiceDeletedPage/WebServiceDeletedPage';
import useCheckWebServiceDeleted from './pages/WebServiceDeletedPage/useCheckWebServiceDeleted';
import CompanyInfoTwoPage from './pages/CompanyInfoPage/CompanyInfoTwoPage';

function App() {
    const eway = getEwayObject();
    const isOutlookClient = eway.isOutlookClient();
    useCheckWebServiceDeleted();

    useEffect(() => {
        if (isOutlookClient) {
            return;
        }

        // Load cookie consent JS
        const script = document.createElement("script");
        script.src = "//eu.cookie-script.com/s/29af4141b3cce99b41db011159005092.js";
        script.type = "text/javascript";
        script.id = "external";
        script.async = true;

        document.body.appendChild(script);
    }, [isOutlookClient]);

    return (
        <>
            {!isOutlookClient && <PageProgress />}
            <ScrollToTop />
            <Routes>
                <Route path={RouteConfig.pages.intro} element={<IntroPage />} />
                <Route path={RouteConfig.pages.emailVerification} element={<EmailVerificationPage />} />
                <Route path={RouteConfig.pages.userInfo} element={<UserInfoPage />} />
                <Route path={RouteConfig.pages.choosePassword} element={<ChoosePasswordPage />} />
                <Route path={RouteConfig.pages.companyInfo} element={<CompanyInfoPage />} />
                <Route path={RouteConfig.pages.companyInfoTwo} element={<CompanyInfoTwoPage />} />
                <Route path={RouteConfig.pages.finalization} element={<PageErrorBoundary><FinalizationPage /></PageErrorBoundary>} />
                <Route path={RouteConfig.outlookPages.finishConfiguration} element={<FinishConfigurationPage />} />
                <Route path={RouteConfig.otherPages.finalizationFailed} element={<FinalizationFailedPage />} />
                <Route path={RouteConfig.outlookPages.importData} element={<PageErrorBoundary><ImportDataPage /></PageErrorBoundary>} />
                <Route path={RouteConfig.outlookPages.importFromOutlook} element={<PageErrorBoundary><ImportFromOutlookPage /></PageErrorBoundary>} />
                <Route path={RouteConfig.outlookPages.importProgress} element={<PageErrorBoundary><ImportProgressPage /></PageErrorBoundary>} />
                <Route path={RouteConfig.outlookPages.importSuccess} element={<EwaySuccessPage title={Strings.importSuccessPage.title} subtitle={Strings.importSuccessPage.subtitle} startBtnTitle={Strings.startBtn}/>} />
                <Route path={RouteConfig.otherPages.loginSuccess} element={<EwaySuccessPage title={Strings.loginSuccessPage.title} subtitle={Strings.loginSuccessPage.subtitle} startBtnTitle={Strings.startBtn} />} />
                <Route path={RouteConfig.otherPages.login} element={<LoginPage />} />
                <Route path={RouteConfig.otherPages.wsUnavailable} element={<WebServiceDeletedPage />} />
                <Route path="*" element={<Navigate to={RouteConfig.pages.intro} />} />
            </Routes>
            {isOutlookClient && <PageProgress />}
        </>
    );
}

export default App;
