import { FormikProps } from "formik";

export default class FluentV8FieldHelper {
    private static readonly DROPDOWN_CALLOUT_PROPS = { calloutMaxHeight: 250 };

    static readonly getDropdownFormikProps = <T extends Record<string, unknown>>(formik: FormikProps<T>, fieldName: string) => {
        return {
            errorMessage: formik.touched[fieldName] ? formik.errors[fieldName] : undefined,
            onBlur: () => {
                void formik.setFieldTouched(fieldName);
            },
            calloutProps: FluentV8FieldHelper.DROPDOWN_CALLOUT_PROPS
        };
    };
}