import './TextDivider.scss';

const TextDivider: React.FC = ({children}) => {
    const text = children?.toString().toUpperCase();

    return (
        <div className="text-divider">
            {text}
        </div>
    );
};

export default TextDivider;