const defaultLanguages = [
    {
        key: 'en',
        text: 'English',
        isSignupLanguage: true
    },
    {
        key: 'cs',
        text: 'Čeština',
        isSignupLanguage: true
    },
    {
        key: 'de',
        text: 'Deutsch',
        isSignupLanguage: false
    },
    {
        key: 'ru',
        text: 'Русский',
        isSignupLanguage: false
    },
    {
        key: 'sk',
        text: 'Slovenčina',
        isSignupLanguage: true
    },
    {
        key: 'no',
        text: 'Norsk',
        isSignupLanguage: false
    },
];

export default defaultLanguages;
