import { useEffect, useState } from 'react';

const SHOW_SENT_TIME = 1500;

const useEmailVerificationCodeSending = (onResendCode: (email: string, cb: () => void) => Promise<void>) => {
    const [isSendingCodeAnimation, setIsSendingCodeAnimation] = useState(false);
    const [isSendingFinished, setIsSendingFinished] = useState(false);
    const [showSent, setShowSent] = useState(false);

    useEffect(() => {
        if (isSendingFinished && !showSent) {
            setIsSendingFinished(false);
            setShowSent(true);

            setTimeout(() => {
                setShowSent(false);
            }, SHOW_SENT_TIME);
        }
    }, [isSendingFinished, showSent]);

    const resendCode = (email: string) => {
        setIsSendingCodeAnimation(true);
        void onResendCode(email, () => {
            setIsSendingCodeAnimation(false);
            setIsSendingFinished(true);
        });
    };

    return { isSendingCodeAnimation, isSendingFinished, showSent, resendCode };
};

export default useEmailVerificationCodeSending;
