import { ChoiceGroup, DefaultButton, IChoiceGroupOption, PrimaryButton, Toggle } from '@fluentui/react';
import { useContext, useEffect, useMemo } from 'react';
import PageBase from '../../components/PageBase/PageBase';
import PageTitle from '../../components/PageTitle/PageTitle';
import { getEwayObject } from '../../helpers/ewayObjectHelper';
import Strings from '../../localization/strings';
import { FormContext } from '../../providers/FormProvider';
import './ImportFromOutlookPage.scss';
import exportOutlookToEwayImg from '../../img/graphic-design/Export_outlook.svg';
import syncOutlookToEwayImg from '../../img/graphic-design/Sync_outlook_eway.svg';
import { CategoriesPicker } from '@eway-crm/gui';
import HeaderButtons from '../../components/PageBase/Header/HeaderButtons';
import RegisterActionHelper from '../../helpers/RegisterActionHelper';

const CATEGORIES_STYLES = { marginTop: '1rem' };

export const OutlookImportType = {
    OneWay: 'OneWay',
    TwoWay: 'TwoWay',
};

const ImportFromOutlookPage = () => {
    const { importFromOutlookFormik, registerAction } = useContext(FormContext);
    const myStrings = Strings.importFromOutlookPage;
    const eway = getEwayObject();
    const isFreePlan = RegisterActionHelper.isFreePlan(registerAction);

    const importTypeOptions: IChoiceGroupOption[] = useMemo(
        () => [
            { key: OutlookImportType.OneWay, text: myStrings.oneWayImport },
            { key: OutlookImportType.TwoWay, text: myStrings.twoWayImport },
        ],
        [myStrings]
    );

    useEffect(() => {
        if (!importFromOutlookFormik.values.importType) {
            void importFromOutlookFormik.setFieldValue('importType', importTypeOptions[0]);
        }
    }, [importFromOutlookFormik, importTypeOptions]);

    return (
        <>
            <HeaderButtons />
            <PageBase imgSrc={importFromOutlookFormik.values.importType?.key === OutlookImportType.TwoWay ? syncOutlookToEwayImg : exportOutlookToEwayImg} imgClassName="import-from-outlook-page__img">
                <form className="import-from-outlook-page" onSubmit={importFromOutlookFormik.handleSubmit}>
                    <PageTitle title={myStrings.title} subtitle={isFreePlan ? myStrings.subtitleFree : myStrings.subtitle} />
                    <div className="import-from-outlook-page__body">
                        {!isFreePlan && (
                            <ChoiceGroup
                                key={importFromOutlookFormik.values.importType?.key}
                                selectedKey={importFromOutlookFormik.values.importType?.key}
                                options={importTypeOptions}
                                onChange={(e, o) => {
                                    void importFromOutlookFormik.setFieldValue('importType', o ?? null);
                                }}
                                label={myStrings.importType}
                                required
                            />
                        )}
                        <Toggle
                            className="import-from-outlook-page__toggle"
                            label={myStrings.automaticallyCreateCompanies}
                            {...importFromOutlookFormik.getFieldProps('automaticallyCreateCompanies')}
                            onChange={(e, checked) => void importFromOutlookFormik.setFieldValue('automaticallyCreateCompanies', !!checked)}
                            checked={importFromOutlookFormik.values.automaticallyCreateCompanies}
                        />
                        <CategoriesPicker 
                            value={importFromOutlookFormik.values.importedContactsCategories} 
                            onValueChange={(value) => void importFromOutlookFormik.setFieldValue('importedContactsCategories', value)}
                            styles={CATEGORIES_STYLES}
                        />
                        <div>
                            <PrimaryButton className="page-base__next-btn" disabled={!importFromOutlookFormik.isValid} onClick={() => void importFromOutlookFormik.submitForm()}>
                                {myStrings.startImportBtn}
                            </PrimaryButton>
                            <DefaultButton className="import-from-outlook-page__skip-btn" onClick={() => eway.start()}>
                                {myStrings.skipImportBtn}
                            </DefaultButton>
                        </div>
                    </div>
                </form>
            </PageBase>
        </>
    );
};

export default ImportFromOutlookPage;
