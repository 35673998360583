import { FontIcon, IconButton, ITextField, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import Strings from '../../localization/strings';
import { FormContext } from '../../providers/FormProvider';
import GrowingInput from './GrowingInput';
import useEmailVerificationCodeSending from './useEmailVerificationCodeSending';
import * as Yup from 'yup';
import YupHelper from '../../helpers/YupHelper';

const ICON_BUTTON_STYLES = { root: { width: '28px', height: '28px', display: 'inline', paddingTop: '4px' } };

type TSubtitleProps = {
    onResendCode: (email: string, cb: () => void) => Promise<void>;
    onEdit: () => void;
    timeBeforeNextSend: number;
};

const EmailVerificationPageSubtitle: React.FC<TSubtitleProps> = ({ onResendCode, onEdit, timeBeforeNextSend }) => {
    const { introFormik } = useContext(FormContext);
    const [isEditing, setIsEditing] = useState(false);
    const { isSendingCodeAnimation, resendCode, showSent } = useEmailVerificationCodeSending(onResendCode);
    const inputRef = useRef<ITextField>(null);
    const myStrings = Strings.emailVerificationPage;

    const subtitleFormik = useFormik<{ newEmail: string }>({
        initialValues: {
            newEmail: introFormik.values.email,
        },
        validationSchema: Yup.object({
            newEmail: YupHelper.validateEmail,
        }),
        onSubmit: () => {
            return;
        },
    });

    const onResend = async () => {
        if (isSendingCodeAnimation || showSent || !subtitleFormik.isValid || Boolean(timeBeforeNextSend)) {
            return;
        }
        setIsEditing(false);
        await introFormik.setFieldValue('email', subtitleFormik.values.newEmail);
        await introFormik.setFieldValue('username', subtitleFormik.values.newEmail);
        resendCode(subtitleFormik.values.newEmail);
    };

    const handleEdit = () => {
        onEdit();
        setIsEditing(true);
    };

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
        }
    }, [isEditing]);

    if (isEditing) {
        return (
            <>
                {Strings.formatString(
                    myStrings.subtitle,
                    <>
                        <div className="email-verification-page__subtitle-input-wrap">
                            <GrowingInput
                                value={subtitleFormik.values.newEmail}
                                onValueChange={(newVal) => void subtitleFormik.setFieldValue('newEmail', newVal)}
                                onSubmit={() => void onResend()}
                                invalid={!subtitleFormik.isValid}
                                errorMessage={subtitleFormik.errors.newEmail}
                            />
                            <TooltipHost content={!!timeBeforeNextSend ? (Strings.formatString(myStrings.timeBeforeNextSend, timeBeforeNextSend) as string) : undefined}>
                                <IconButton
                                    iconProps={{ iconName: 'Accept' }}
                                    title={!timeBeforeNextSend ? myStrings.accept : undefined}
                                    onClick={() => void onResend()}
                                    styles={ICON_BUTTON_STYLES}
                                    disabled={!subtitleFormik.isValid || !!timeBeforeNextSend}
                                />
                            </TooltipHost>
                            <IconButton iconProps={{ iconName: 'Cancel' }} title={myStrings.cancel} onClick={() => setIsEditing(false)} styles={ICON_BUTTON_STYLES} />
                        </div>
                        <br />
                    </>
                )}
            </>
        );
    }

    return (
        <>
            {Strings.formatString(
                myStrings.subtitle,
                <>
                    <div className="email-verification-page__subtitle">
                        {introFormik.values.email}
                        {isSendingCodeAnimation && <Spinner size={SpinnerSize.medium} className="email-verification-page__subtitle__spinner" />}
                        {showSent && !isSendingCodeAnimation && <FontIcon className="email-verification-page__subtitle__checkmark" iconName={'CompletedSolid'} />}
                        {!showSent && !isSendingCodeAnimation && <IconButton onClick={handleEdit} className="email-verification-page__subtitle__edit-btn" iconProps={{ iconName: 'Edit' }} />}
                    </div>
                    <br />
                </>
            )}
        </>
    );
};

export default EmailVerificationPageSubtitle;
