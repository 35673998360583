import { mergeStyles, ProgressIndicator } from '@fluentui/react';
import { matchPath, useLocation, useMatch } from 'react-router';
import RouteConfig from '../../config/RouteConfig';
import { getEwayObject } from '../../helpers/ewayObjectHelper';
import './PageProgress.scss';

const PageProgress = () => {
    const matchIntro = useMatch(RouteConfig.pages.intro);
    const location = useLocation();
    const eway = getEwayObject();

    const isOnIntro = matchIntro?.pathname === RouteConfig.pages.intro;

    const getPercent = () => {
        let percent = 0;
        const platformPages = eway.isOutlookClient() ? RouteConfig.outlookPages : null;
        const allRoutes = { ...RouteConfig.pages, ...platformPages };
        const allRoutesLength = Object.keys(allRoutes).length;
        Object.values(allRoutes).forEach((val, idx) => {
            const match = matchPath(val, location.pathname);
            if (match) {
                percent = idx / (allRoutesLength - 1);
            }
        });

        return percent;
    };

    const percentComplete = getPercent();
    const isVisible = !isOnIntro;

    return <ProgressIndicator percentComplete={percentComplete} className={mergeStyles('page-progress', eway.isOutlookClient() && 'isOutlook', isVisible && 'isVisible')} />;
};

export default PageProgress;
