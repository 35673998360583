import { CookiesHelper } from "@eway-crm/gui";

export default class CookieHelper extends CookiesHelper {
    static names = {
        browserId: 'browserid',
        signUpEmailAddress: 'signUpEmailAddress',
        isSignUp: 'isSignUp',
    };

    static setCookieObj<T>(name: string, value: T | null | undefined, exhours: number) {
        let resultValue = '';
        if (value) {
            let resultObj;
            const currentObj = this.getCookieObj(name);
            if (currentObj) {
                resultObj = { ...currentObj, ...value };
            } else {
                resultObj = value;
            }
            resultValue = JSON.stringify(resultObj);
        }
        CookiesHelper.setCookieText(name, resultValue, exhours);
    }

    static getCookieObj<T>(name: string): Partial<T> {
        const textValue = CookiesHelper.getCookieText(name);
        if (!textValue) {
            return {};
        }
        try {
            return JSON.parse(textValue) as Partial<T>;
        } catch (_) {
            return {};
        }
    }
}
