import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { HashRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import FormProvider from './providers/FormProvider';
import FinalizationProvider from './providers/FinalizationProvider/FinalizationProvider';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { getMsalConfig } from './config/authConfig';
import UrlHelper from './helpers/UrlHelper';
import LanguageProvider from './providers/LanguageProvider';

if (!window.location.href.includes("#")) {
    // Redirect to URL with hash if we are not starting on one
    window.location.replace(UrlHelper.getFixedUrl());
}

initializeIcons();

const mainPageUri = window.location.href.split("#")[0];
const msalConfig = getMsalConfig(mainPageUri);
const msalInstance = new PublicClientApplication(msalConfig);

const renderMain = () => {
    ReactDOM.render(
        <React.StrictMode>
            <HashRouter>
                <MsalProvider instance={msalInstance}>
                    <LanguageProvider>
                        <FormProvider>
                            <FinalizationProvider>
                                <App />
                            </FinalizationProvider>
                        </FormProvider>
                    </LanguageProvider>
                </MsalProvider>
            </HashRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

if (window.CefSharp && !window.eway) {
    console.log('Waiting for eway object to bind...');

    void window.CefSharp.BindObjectAsync('eway').then((result) => {
        if (result.Success) {
            renderMain();
        } else {
            console.log('Binding of eway object failed...');

            const errorPanel = document.getElementById('error-panel');
            if (errorPanel) {
                errorPanel.innerText = 'Page is currently not available, please try to restart Outlook or contact eWay-CRM support.';
            }

            window.CefSharp?.PostMessage('BoundObjectInitializationFailed');
        }
    });
} else {
    renderMain();
}
