import { useContext, useEffect, useState } from "react";
import { FinalizationContext } from "../../../providers/FinalizationProvider/FinalizationProvider";
import { TFinalizationState } from "../Finalization/Finalization";

/**
 * Synchronizes Finalization state to react state  
 */
const useFinalizationState = () => {
    const { finalization } = useContext(FinalizationContext);
    const [finalizationState, setFinalizationState] = useState<TFinalizationState | null>(() => finalization?.selectDataExternally(x => x) ?? null);

    useEffect(() => {
        if (finalization) {
            finalization?.subscribe(setFinalizationState);
        }

        return () => {
            finalization?.unsubscribe(setFinalizationState);
        };
    }, [finalization]);

    if (!finalizationState) {
        throw new Error("Finalization was not present even when it was expected.");
    }

    return finalizationState;
};

export default useFinalizationState;