import { FontIcon, Link, mergeStyles } from '@fluentui/react';
import Strings from '../../localization/strings';
import useEmailVerificationCodeSending from './useEmailVerificationCodeSending';
import ResendCodeStaticImg from '../../img/resend-code/ResendCodeStatic.gif';
import ResendCodeImg from '../../img/resend-code/ResendCode.gif';
import './EmailVerificationPage.scss';
import { FormContext } from '../../providers/FormProvider';
import { useContext } from 'react';

type TResendCodeButtonProps = {
    onResendCode: (email: string, cb: () => void) => Promise<void>;
    timeBeforeNextSend: number;
};

const ResendCodeButton: React.FC<TResendCodeButtonProps> = ({ onResendCode, timeBeforeNextSend }) => {
    const { introFormik } = useContext(FormContext);
    const { isSendingCodeAnimation, resendCode, isSendingFinished, showSent } = useEmailVerificationCodeSending(onResendCode);
    const myStrings = Strings.emailVerificationPage;

    const handleClick = () => {
        if (isSendingCodeAnimation || isSendingFinished || showSent) {
            // Do not resend while already sending or showing sent icon
            return;
        }

        resendCode(introFormik.values.email);
    };

    return (
        <div className="email-verification-page__resend-code">
            {showSent && <FontIcon className="email-verification-page__resend-code__checkmark" iconName={'CompletedSolid'} />}
            <Link className="email-verification-page__resend-code__btn" onClick={handleClick} disabled={!!timeBeforeNextSend}>
                {!showSent && (
                    <img
                        src={isSendingCodeAnimation ? ResendCodeImg : ResendCodeStaticImg}
                        alt={'resend code'}
                        className={mergeStyles('email-verification-page__resend-code__img', !!timeBeforeNextSend && 'email-verification-page__resend-code__img--disabled')}
                    />
                )}
                <span>{myStrings.resendCode}</span>
                {Boolean(timeBeforeNextSend) && <span className="email-verification-page__resend-code__time">({timeBeforeNextSend})</span>}
            </Link>
        </div>
    );
};

export default ResendCodeButton;
