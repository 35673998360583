import { PrimaryButton, mergeStyles, Text, Spinner, SpinnerSize } from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PageBase from '../../components/PageBase/PageBase';
import RouteConfig from '../../config/RouteConfig';
import './EmailVerificationPage.scss';
import Strings from '../../localization/strings';
import PageTitle from '../../components/PageTitle/PageTitle';
import pageImg from '../../img/graphic-design/2-02.svg';
import useValidatePreviousFormikOnMount from '../../helpers/hooks/useValidatePreviousFormikOnMount';
import { FormContext } from '../../providers/FormProvider';
import { PasscodeControl } from '@eway-crm/hostingdaemon-js-lib';
import useLogin from '../../helpers/hooks/useLogin';
import EmailVerificationPageSubtitle from './EmailVerificationSubtitle';
import ResendCodeButton from './ResendCodeButton';

const DELAY_BEFORE_NEXT_SEND = 10; // In seconds

type TAlreadyCreatedHosting = {
    Url: string;
    UserName: string;
};

const EmailVerificationPage: React.FC = () => {
    const [passcode, setPasscode] = useState('');
    const [authorizationCode, setAuthorizationCode] = useState<string | null>(null);
    const [isInvalid, setIsInvalid] = useState(false);
    const [alreadyCreatedHosting, setAlreadyCreatedHosting] = useState<TAlreadyCreatedHosting | null>(null);
    const [showError, setShowError] = useState(false);
    const [timeBeforeNextSend, setTimeBeforeNextSend] = useState<number>(0);
    const navigate = useNavigate();
    const myStrings = Strings.emailVerificationPage;
    const { introFormik, setVerifiedEmail, hostingApi } = useContext(FormContext);
    const { isLoggingIn, onLogin } = useLogin();
    const [isInitialCodeSent, setIsInitialCodeSent] = useState(false);

    useValidatePreviousFormikOnMount(introFormik);

    const sendVerificationCode = useCallback(
        async (email: string) => {
            setShowError(false);
            const response = await hostingApi.generateUserEmailVerificationCode({ email, lang: Strings.getLanguage(), allowUnknown: true });
            if (response && !response.IsErrorResponse) {
                setAuthorizationCode(response.AuthorizationCode);
            } else {
                setShowError(true);
                console.error(response?.message);
            }

            return;
        },
        [hostingApi]
    );

    useEffect(() => {
        if (authorizationCode || isInitialCodeSent) {
            return;
        }

        setIsInitialCodeSent(true);
        void sendVerificationCode(introFormik.values.email);
    }, [introFormik.values.email, authorizationCode, sendVerificationCode, isInitialCodeSent]);

    useEffect(() => {
        if (passcode.length !== 6 || !authorizationCode) {
            return;
        }

        const checkVerificationCode = async () => {
            setIsInvalid(false);
            setAlreadyCreatedHosting(null);
            const response = await hostingApi.getHostingsByEmail({ email: introFormik.values.email, authorizationCode, passcode });

            if (response && !response.IsErrorResponse) {
                setVerifiedEmail(introFormik.values.email);

                if (response.HostingsUrls?.length) {
                    setAlreadyCreatedHosting(response.HostingsUrls[0]);
                    setVerifiedEmail('');
                } else {
                    navigate(RouteConfig.pages.userInfo, { replace: true });
                }
            } else {
                setIsInvalid(true);
                console.error(response?.message);
            }
        };

        void checkVerificationCode();
    }, [passcode, introFormik.values.email, authorizationCode, navigate, setVerifiedEmail, hostingApi]);

    const onResendCode = async (email: string, cb: () => void) => {
        if (timeBeforeNextSend) {
            // Do not resend code before time is up
            return;
        }

        await sendVerificationCode(email);
        startCountDown();
        cb();
    };

    const startCountDown = () => {
        setTimeBeforeNextSend(DELAY_BEFORE_NEXT_SEND);

        const intervalId = setInterval(() => {
            setTimeBeforeNextSend((prevTime) => {
                if (prevTime === 1) {
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    };

    return (
        <PageBase imgSrc={pageImg} maxContentWidth={"40rem"}>
            <form onSubmit={(e) => e.preventDefault()}>
                {!alreadyCreatedHosting ? (
                    <>
                        <PageTitle title={myStrings.title} subtitle={<EmailVerificationPageSubtitle onResendCode={onResendCode} onEdit={() => setShowError(false)} timeBeforeNextSend={timeBeforeNextSend} />} />
                        <div className="email-verification-page__passcode-wrap">
                            <div className={mergeStyles('email-verification-page__passcode-control', (isInvalid || showError) && 'invalid')}>
                                <PasscodeControl onPasscodeChange={setPasscode} customValidityMessage={myStrings.enterPasscodeMessage} />
                                <Text block variant={showError ? 'medium' : 'small'} className="email-verification-page__passcode-invalid">
                                    {isInvalid && myStrings.passcodeInvalid}
                                    {showError && myStrings.unableToGenerateVerificationCode}
                                </Text>
                            </div>
                        </div>
                        <div className="email-verification-page__button-wrap">
                            <ResendCodeButton onResendCode={onResendCode} timeBeforeNextSend={timeBeforeNextSend} />
                            <PrimaryButton disabled={true}>{Strings.next}</PrimaryButton>
                        </div>
                    </>
                ) : (
                    <>
                        <PageTitle title={myStrings.hostingExistsTitle} subtitle={myStrings.hostingExistsSubtitle} />
                        <PrimaryButton
                            className="email-verification-page__login-btn"
                            onClick={() => {
                                onLogin(alreadyCreatedHosting.Url, alreadyCreatedHosting.UserName);
                            }}
                        >
                            {isLoggingIn ? <Spinner size={SpinnerSize.medium} className="email-verification-page__login-spinner" /> : myStrings.login}
                        </PrimaryButton>
                    </>
                )}
            </form>
        </PageBase>
    );
};

export default EmailVerificationPage;

