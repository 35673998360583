import ApiConnection, { Feature, FolderNames, HttpMethod, IApiDataResponse, IApiDatumResponse, IApiGroup, IApiLicense, IApiLicenseBundleBase, IApiResult, IApiSaveResponse, IApiUser, RelationTypes, TUnionError } from '@eway-crm/connector';
import { getEwayObject } from '../helpers/ewayObjectHelper';
import { PasswordHelper } from '../helpers/PasswordHelper';
import { compare } from 'compare-versions';
import { StringHelper } from '../helpers/StringHelper';
import { TCompanyInfoPageFormikValues, TFeaturesFormikValues } from '../providers/FormProvider';
import { ApiMethods, IApiLoginResponse } from '@eway-crm/connector';
import md5 from 'md5';
import { TUserToCreate } from '../pages/FinalizationPage/Finalization/Finalization';
import roleTranslationStrings from '../localization/roleTranslationStrings';
import { OUTLOOK_GUID_NAME_MAP } from '../pages/CompanyInfoPage/data/getCompanyInfoTwoDropdownOptions';

export const RETRY_TIMEOUT = 200;

class Connection {
    public readonly apiConnection: ApiConnection;
    private readonly clientVersion: string;

    constructor(serviceUrl: string, username: string, passwordHash: string, errorCb: (err: Error) => void, clientVersion: string) {
        this.clientVersion = clientVersion;
        this.apiConnection = Connection.createApiConnection(
            serviceUrl,
            username,
            passwordHash,
            errorCb
        );
    }

    /**
     * Creates ApiConnection. Retries creating once again on error.
     */
    private static createApiConnection = (serviceUrl: string, username: string, passwordHash: string, errorCb: (err: Error) => void, doNotRetry?: boolean): ApiConnection => {
        const eway = getEwayObject();
        return ApiConnection.create(
            serviceUrl,
            username,
            passwordHash,
            `${process.env.REACT_APP_NAME}-${process.env.NODE_ENV}-v${process.env.REACT_APP_VERSION}`,
            eway.getClientMachineIdentifier(serviceUrl),
            eway.getClientMachineName(),
            errorCb
        );
    };

    /**
     * Automatically retries API request once if it fails
     */
    askMethodWithRetry = async <TResult extends IApiResult>(methodName: string, data: Parameters<typeof this.apiConnection.askMethod>["1"], doNotRetry?: boolean): Promise<TResult> => {
        try {
            return await this.apiConnection.askMethod<TResult>(methodName, data);
        } catch(err) {
            if (!doNotRetry) {
                throw err;
            }

            await new Promise((res) => setTimeout(res, RETRY_TIMEOUT));
            return this.askMethodWithRetry(methodName, data, true);
        }
    };

    createUsersAndAssignRelations = async (users: TUserToCreate[], wsAddressUrl: string, companyInfoPageFormikValues: TCompanyInfoPageFormikValues, isLoggedViaMs: boolean, featuresFormikValues: TFeaturesFormikValues, outlookType: string[]) => {
        const blockingErrors: string[] = [];
        const softErrors: string[] = [];

        const licenseRes = await this.apiConnection.askMethod<IApiDatumResponse<IApiLicense>>('GetLicense', { doReload: false });
        const licenseBundles = licenseRes.Datum.AvailableBundles;
        if (!licenseBundles) {
            softErrors.push(`License bundles were not loaded. They will not be assigned to any user.`);
        }

        /**
         * Gets license to assign for Old version of GetLicense 
         */
        const legacyGetAvailableLicense = (userName: string) => {
            const firstAvailableBundle = licenseBundles.find((b) => b.FreeSlotsCount && b.Quantity > b.UsedSlotsCount);
            if (!firstAvailableBundle) {
                softErrors.push(`No more available licenses to assign. User ${userName} will be created without any license.`);
                return null;
            }

            const license = {
                Code: firstAvailableBundle.Code,
                ContainsOutlook: firstAvailableBundle.ContainsOutlook,
                ContainsMobile: firstAvailableBundle.ContainsMobile,
                ContainsWeb: firstAvailableBundle.ContainsWeb,
            };

            firstAvailableBundle.FreeSlotsCount -= 1;
            firstAvailableBundle.UsedSlotsCount += 1;

            return [license];
        };

        const getAvailableLicenses = (userName: string) => {
            if (compare(this.clientVersion, "7.3", "<") || !licenseRes.Datum.Features) {
                // Old version of GetLicense
                return legacyGetAvailableLicense(userName);
            }

            const licenses: (IApiLicenseBundleBase & { feature: Feature})[] = [];
            const getAvailableBundle = (featureName: Feature) => {
                const hasLicenseWithThisFeature = licenses.some(l => l.feature === featureName);
                if (hasLicenseWithThisFeature) {
                    // User already have this feature license assigned
                    return;
                }

                const availableBundle = licenseBundles.find((b) => b.FreeSlotsCount && b.Quantity > b.UsedSlotsCount && b.Features.find((f) => f.Feature === featureName));
                if (!availableBundle) {
                    softErrors.push(`No more available licenses to assign for feature ${featureName}. User ${userName} will be created without this license.`);
                    return;
                }

                licenses.push({
                    Code: availableBundle.Code,
                    ContainsOutlook: availableBundle.ContainsOutlook,
                    ContainsWeb: availableBundle.ContainsWeb,
                    ContainsMobile: availableBundle.ContainsMobile,
                    feature: featureName
                });

                availableBundle.FreeSlotsCount -= 1;
                availableBundle.UsedSlotsCount += 1;
            };

            Object.entries(featuresFormikValues).forEach(([featureName, isOn]) => {
                if (!isOn) {
                    return;
                }

                if (featureName === 'contactsAndCompanies') {
                    getAvailableBundle(Feature.ContactsAndCompanies);
                } else if (featureName === 'projects') {
                    getAvailableBundle(Feature.Projects);
                } else if (featureName === 'sales') {
                    getAvailableBundle(Feature.Sales);
                } else if (featureName === 'marketingCampaigns') {
                    getAvailableBundle(Feature.Marketing);
                }
            });

            return licenses;
        };

        const userItems: Partial<IApiUser>[] = users.map(({ username, email, firstName, lastName, password, itemGuid }) => {
            const licenses = getAvailableLicenses(username);
            return {
                ItemGUID: itemGuid,
                Username: username,
                IsActive: true,
                Email1Address: email,
                IsApiUser: false,
                IsSystem: false,
                FirstName: firstName ?? null,
                LastName: lastName ?? null,
                Server_Password: isLoggedViaMs ? undefined : password ?? PasswordHelper.generate(12),
                Server_LicensingBundlesList: licenses,
            };
        });

        try {
            await this.askMethodWithRetry<IApiSaveResponse>('SaveUsers', { transmitObjects: userItems });
        } catch (e) {
            const _userItems = [...userItems];
            _userItems.forEach(u => {
                // Remove password from error log
                delete u.Server_Password;
            });
            blockingErrors.push(StringHelper.prepareErrorLog(e as Error, `Unable to save users: ${JSON.stringify(_userItems)}`));
        }

        const groupsRes = await this.askMethodWithRetry<IApiDataResponse<IApiGroup>>('GetGroups', {});
        roleTranslationStrings.setLanguage(companyInfoPageFormikValues.language!);

        const relations = users
            .map((u) => {
                const localizedGroupName = roleTranslationStrings[u.group as keyof typeof roleTranslationStrings] as string;
                const groupToAssign = groupsRes.Data.find((g) => g.GroupName === localizedGroupName);

                if (!groupToAssign) {
                    softErrors.push(`Group ${u.group} (${localizedGroupName}) not found. It will not be assigned to user ${u.email}.`);
                    return null;
                }

                return {
                    ItemGUID1: groupToAssign.ItemGUID,
                    ItemGUID2: u.itemGuid,
                    FolderName1: FolderNames.groups,
                    FolderName2: FolderNames.users,
                    RelationType: RelationTypes.group,
                    DifferDirection: false,
                };
            })
            .filter((rels) => !!rels);

        try {
            await this.askMethodWithRetry<IApiSaveResponse>('SaveRelations', { transmitObjects: relations });
        } catch (e) {
            softErrors.push(StringHelper.prepareErrorLog(e as Error, `Unable to save group relations to users. ${JSON.stringify(relations)}`));
        }

        const emailItems = userItems.map((user, idx) => {
            if (idx === 0) {
                // Do not send server password of first user
                user.Server_Password = undefined;
            }

            const emailItem: Record<string, unknown> = {
                MessageType: 'Welcome',
                UserTransmitObject: user,
                InvitedByName: idx !== 0 ? userItems[0].FirstName ?? null : null,
            };

            if (compare(this.clientVersion, "7.2", ">=")) {
                emailItem["IsUsingMsLogin"] = isLoggedViaMs;
            }

            if (compare(this.clientVersion, "8.0", ">=")) {
                emailItem["OutlookTypes"] = outlookType.map(ot => OUTLOOK_GUID_NAME_MAP[ot]);
                emailItem["IsSelfServiceCustomer"] = companyInfoPageFormikValues.teamSize!.key as number <= 1;
            }

            return emailItem;
        });

        try {
            await this.askMethodWithRetry<IApiSaveResponse>('SendWelcomeEmails', { transmitObjects: emailItems, webServiceAddress: wsAddressUrl });
        } catch (e) {
            softErrors.push(StringHelper.prepareErrorLog(e as Error, `Unable to send welcome emails to users. \nWsUrl: ${wsAddressUrl}. \nEmail items: ${JSON.stringify(emailItems)}`));
        }

        return {
            softErrors,
            blockingErrors,
        };
    };

    static GetNewSessionId = (webServiceUrl: string, userName: string, password: string, clientVersion: string, newSessionIdGetterCallback: (sessionId: string | null) => void) => {
        const createApiErrorCallback = (err: Error) => {
            console.error('Unable to create connection to create new session.' + JSON.stringify(err));
        };
        
        const connection = ApiConnection.createAnonymous(webServiceUrl, createApiErrorCallback);
        
        const inputData = {
            userName: userName,
            passwordHash: md5(password),
            appVersion: `WEB ACCESS - ${clientVersion}`,
            clientMachineIdentifier: '',
            clientMachineName: window.navigator.userAgent,
            createSessionCookie: connection.supportsGetItemPreviewMethod
        };

        const loginSuccessfulCallback = (result: IApiLoginResponse) => {
            newSessionIdGetterCallback(result.SessionId);
        };

        const loginUnsuccessfulCallback = (result: IApiLoginResponse) => {
            console.error('Unable to login to create new session.' + JSON.stringify(result));
            newSessionIdGetterCallback(null);
        };

        const loginErrorCallback = (err: TUnionError) => {
            console.error('Unable to login to create new session.' + JSON.stringify(err));
            newSessionIdGetterCallback(null);
        };

        connection.callWithoutSession(
            ApiMethods.logIn,
            inputData,
            loginSuccessfulCallback,
            loginUnsuccessfulCallback,
            null,
            HttpMethod.post,
            loginErrorCallback
        );
    };
}

export default Connection;
