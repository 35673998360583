import { useEffect } from "react";
import gif1 from '../../img/finalization/1_install.gif';
import gif2 from '../../img/finalization/2_install.gif';
import gif3 from '../../img/finalization/3_install.gif';
import gif4 from '../../img/finalization/4_install.gif';
import gif5 from '../../img/finalization/5_install.gif';
import gif6 from '../../img/finalization/6_install.gif';
import gif7 from '../../img/finalization/7_install.gif';

/**
 * Preload images before rendering them
 */

const usePreloadImages = () => {
    useEffect(() => {
        if (window.innerWidth < 900) {
            // No need to load animation images on smaller devices
            return;
        }

        // Set timeout to make cookie consent think that everything on page is already loaded
        setTimeout(() => {
            const preloadImages = async (imgSrc: string[]) => {
                const promiseMap = imgSrc.map(
                    (src) =>
                        new Promise((resolve) => {
                            const img = new Image();
                            img.onload = () => {
                                // when it finishes loading
                                resolve(img);
                            };
                            img.src = src;
                        })
                );
    
                await Promise.all(promiseMap);
            };

            void preloadImages([gif1, gif2, gif3, gif4, gif5, gif6, gif7]);
        }, 2000);
    }, []);
};

export default usePreloadImages;