/**
 * List of popular free email domains.
 * Honza L. got these from our deals (used at least 6x in past)
 */
export const popularEmailDomains  = [
    "gmail.com",
    "outlook.com",
    "hotmail.com",
    "seznam.cz",
    "yahoo.com",
    "mail.ru",
    "centrum.cz",
    "email.cz",
    "live.com",
    "msn.com",
    "yandex.ru",
    "aol.com",
    "outlook.cz",
    "volny.cz",
    "comcast.net",
    "icloud.com",
    "gmx.de",
    "me.com",
    "hotmail.co.uk",
    "atlas.cz",
    "t-online.de",
    "web.de",
    "post.cz",
    "bk.ru",
    "btinternet.com",
    "ymail.com",
    "bigpond.com",
    "centrum.sk",
    "bellsouth.net",
    "sbcglobal.net",
    "azet.sk",
    "googlemail.com",
    "hotmail.cz",
    "yahoo.fr",
    "mail.com",
    "verizon.net",
    "yahoo.co.uk",
    "gmx.net",
    "outlook.fr",
    "outlook.de",
    "gmx.com",
    "att.net",
    "mailinator.com",
    "outlook.pt",
    "rediffmail.com",
    "zoznam.sk",
    "yahoo.co.in",
    "rambler.ru",
    "live.co.uk",
    "yahoo.in",
    "ukr.net",
    "yahoo.gr",
    "gmx.at",
    "ya.ru",
    "quick.cz",
    "rocketmail.com",
    "windowslive.com",
    "list.ru",
    "163.com",
    "pobox.sk",
    "live.com.au",
    "email.com",
    "live.ca",
    "live.de",
    "yahoo.com.ar",
    "outlook.it",
    "freenet.de",
    "free.fr",
    "yahoo.com.au",
    "onmicrosoft.com"
];