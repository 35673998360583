import { DefaultButton, FontIcon } from '@fluentui/react';
import PageTitle from '../../components/PageTitle/PageTitle';
import { getEwayObject } from '../../helpers/ewayObjectHelper';
import './EwaySuccessPage.scss';

import React from 'react';
import PageBase from '../../components/PageBase/PageBase';

type TEwaySuccessPageProps = {
    title: string;
    subtitle: string;
    startBtnTitle: string;
};

const EwaySuccessPage: React.FC<TEwaySuccessPageProps> = ({title, subtitle, startBtnTitle}) => {
    const eway = getEwayObject();

    return (
        <PageBase hideBackButton>
            <div className="eway-success-page">
                <PageTitle title={title} subtitle={subtitle} variant={'large'} />
                <FontIcon className="eway-success-page__completed-icon" iconName="CompletedSolid" />
                <DefaultButton className="eway-success-page__start-btn" onClick={() => eway.start()}>
                    {startBtnTitle}
                </DefaultButton>
            </div>
        </PageBase>
    );
};

export default EwaySuccessPage;
