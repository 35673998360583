import './FinishConfigurationPage.scss';
import React, { useContext, useEffect } from 'react';
import PageBase from '../../components/PageBase/PageBase';
import PageTitle from '../../components/PageTitle/PageTitle';
import Strings from '../../localization/strings';
import { Dropdown, PrimaryButton, ResponsiveMode, Toggle } from '@fluentui/react';
import defaultLanguages from '../CompanyInfoPage/data/defaultLanguages';
import { FormContext } from '../../providers/FormProvider';
import pageImg from '../../img/graphic-design/9-02.svg';
import { LanguageContext } from '../../providers/LanguageProvider';
import RegisterActionHelper from '../../helpers/RegisterActionHelper';

const FinishConfigurationPage: React.FC = () => {
    const myStrings = Strings.finishConfigurationPage;
    const { finishConfigurationFormik, companyInfoFormik, registerAction } = useContext(FormContext);
    const { language } = useContext(LanguageContext);

    const isSignIn = RegisterActionHelper.isSignIn(registerAction);
    const isFreePlan =  RegisterActionHelper.isFreePlan(registerAction);

    useEffect(() => {
        if (companyInfoFormik.values.language && !finishConfigurationFormik.values.preferredLanguage) {
            const defaultLanguage = defaultLanguages.find((lang) => lang.key === companyInfoFormik.values.language);
            if (defaultLanguage) {
                void finishConfigurationFormik.setFieldValue('preferredLanguage', defaultLanguage);
            }
        } else if (!companyInfoFormik.values.language && !finishConfigurationFormik.values.preferredLanguage) {
            const defaultLanguage = defaultLanguages.find((lang) => lang.key === language);
            if (defaultLanguage) {
                void finishConfigurationFormik.setFieldValue('preferredLanguage', defaultLanguage);
            }
        }
    }, [companyInfoFormik.values.language, finishConfigurationFormik, language]);

    return (
        <PageBase imgSrc={pageImg} hideBackButton>
            <form className="finish-configuration-page" onSubmit={finishConfigurationFormik.handleSubmit}>
                <PageTitle title={isSignIn ? myStrings.titleSignIn : myStrings.title} subtitle={myStrings.subtitle} />
                <div className="finish-configuration-page__fields-wrap">
                    {!companyInfoFormik.values.language && (
                        <Dropdown
                            options={defaultLanguages}
                            className="finish-configuration-page__preferred-language"
                            {...finishConfigurationFormik.getFieldProps('preferredLanguage')}
                            selectedKey={finishConfigurationFormik.values.preferredLanguage?.key}
                            onChange={(e, o) => void finishConfigurationFormik.setFieldValue('preferredLanguage', o)}
                            responsiveMode={ResponsiveMode.large}
                            label={myStrings.preferredLanguage}
                        />
                    )}
                    {/* Only show toggle to trial users */}
                    {!isFreePlan && (
                        <Toggle
                            label={myStrings.automaticallySaveEmailsByContacts}
                            {...finishConfigurationFormik.getFieldProps('saveEmailsByContacts')}
                            onChange={(e, checked) => void finishConfigurationFormik.setFieldValue('saveEmailsByContacts', !!checked)}
                            checked={finishConfigurationFormik.values.saveEmailsByContacts}
                        />
                    )}
                    <Toggle
                        label={myStrings.sendAnonymousDiagnostics}
                        {...finishConfigurationFormik.getFieldProps('sendAnonymousDiagnostics')}
                        onChange={(e, checked) => void finishConfigurationFormik.setFieldValue('sendAnonymousDiagnostics', !!checked)}
                        checked={finishConfigurationFormik.values.sendAnonymousDiagnostics}
                    />
                </div>
                <PrimaryButton className="page-base__next-btn" type="submit" disabled={!finishConfigurationFormik.isValid}>
                    {Strings.next}
                </PrimaryButton>
            </form>
        </PageBase>
    );
};

export default FinishConfigurationPage;
