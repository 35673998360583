import { useContext, useEffect, useState } from 'react';
import { getEwayObject } from '../../../helpers/ewayObjectHelper';
import DateHelper from '../../../helpers/DateHelper';
import { FormContext, ImportDatabaseType } from '../../../providers/FormProvider';
import { TGetLocalDatabaseCreationProgressResponse } from '../../../types/ewayBrowserBoundObject';
import { OutlookImportType } from '../../ImportFromOutlookPage/ImportFromOutlookPage';
import { LanguageContext } from '../../../providers/LanguageProvider';

export enum ImportPhase {
    CheckLocalDb,
    Start,
    CheckProgress,
    Finish,
}

const useImportProgress = () => {
    const { companyInfoFormik, importFromOutlookFormik, importDatabaseType } = useContext(FormContext);
    const { language } = useContext(LanguageContext);
    const eway = getEwayObject();

    const [importPhase, setImportPhase] = useState<ImportPhase>(ImportPhase.CheckLocalDb);
    const [localDatabaseCreationProgress, setLocalDatabaseCreationProgress] = useState<TGetLocalDatabaseCreationProgressResponse | null>(null);
      
    useEffect(() => {
        const checkLocalDatabaseCreationProgress = (nextPhase: ImportPhase) => {
            setTimeout(() => {
                const localDatabaseCreationProgress = eway.getLocalDatabaseCreationProgress!();
                if (!localDatabaseCreationProgress) {
                    checkLocalDatabaseCreationProgress(nextPhase); // There is no progress yet, try again
                } else {
                    setLocalDatabaseCreationProgress(localDatabaseCreationProgress);
                    if (localDatabaseCreationProgress.isFinished) {
                        setTimeout(() => {
                            // Wait for a while to display last message
                            setImportPhase(nextPhase);
                        }, DateHelper.getRandomTime());
                    }
                }
            }, DateHelper.getRandomTime());
        };

        if (importPhase === ImportPhase.CheckLocalDb) {
            if (!localDatabaseCreationProgress) {
                checkLocalDatabaseCreationProgress(ImportPhase.Start);
            }
        } else if (importPhase === ImportPhase.Start) {
            switch(importDatabaseType) {
                case ImportDatabaseType.SampleData: {
                    eway.importSampleData!(companyInfoFormik.values.language ?? language);
                    break;
                }

                case ImportDatabaseType.DeletedWsData: {
                    eway.importFromPreviousAccount?.();
                    break;
                }

                case ImportDatabaseType.Outlook: {
                    const categories = importFromOutlookFormik.values.importedContactsCategories.reduce((acc, current) => `${acc ? acc + ', ' : ''}${current.name}`, '');
                    eway.importFromOutlook!(
                        importFromOutlookFormik.values.importType?.key === OutlookImportType.TwoWay,
                        importFromOutlookFormik.values.automaticallyCreateCompanies,
                        categories
                        );
                    break;
                }
            }
                        
            setImportPhase(ImportPhase.CheckProgress);
        } else if (importPhase === ImportPhase.CheckProgress) {
            checkLocalDatabaseCreationProgress(ImportPhase.Finish);
        }
    }, [importDatabaseType, eway, companyInfoFormik.values, importPhase, localDatabaseCreationProgress, importFromOutlookFormik.values, language]);

    return { importPhase, localDatabaseCreationProgress };
};

export default useImportProgress;
