import { createContext, useState } from 'react';
import { getEwayObject } from '../helpers/ewayObjectHelper';
import Strings from '../localization/strings';
import { MetaStrings } from '@eway-crm/gui';

export type TLanguageContext = {
    language: string;
    onLanguageSwitch: (newLang: string) => void;
};

export const LanguageContext = createContext<TLanguageContext>(null!);

const LanguageProvider: React.FC = ({ children }) => {
    const [language, setLanguage] = useState(() => Strings.getLanguage());
    const eway = getEwayObject();

    const onLanguageSwitch = (newLang: string) => {
        MetaStrings.setLanguage(newLang);
        const lang = Strings.getLanguage(); // Get actual language of localized-strings
        eway.setLanguage(lang);
        setLanguage(lang);
    };

    return (
        <LanguageContext.Provider
            value={{
                language,
                onLanguageSwitch,
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;
