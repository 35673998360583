import { TEwayBrowserBoundObject } from '../types/ewayBrowserBoundObject';
import CookieHelper from './CookieHelper';
import { v4 as uuidv4 } from 'uuid';
import Strings from '../localization/strings';

const languageCountryCodeMap = {
    en: 'US',
    cs: 'CZ',
    sk: 'SK',
    de: 'DE',
    ru: 'RU',
    no: 'NO',
} as const;

const defaultEwayObject: TEwayBrowserBoundObject = {
    getPreferredOutlookClientVersion: () => null,
    getTimeZone: () => null,
    getHostingUrl: () => null,
    /* Closes the windown */
    cancel: () => {
        return;
    },
    /* Starts eWay-CRM */
    start: () => {
        return;
    },
    minimize: () => {
        return;
    },
    login: (cb, wsUrl) => {
        if (wsUrl) {
            window.location.href = wsUrl;
        } else {
            window.location.href = 'https://login.eway-crm.com';
        }
    },
    sendError: () => {
        return;
    },
    saveApplicationSetting: (name, value) => {
        return;
    },
    getClientMachineIdentifier: (serviceUrl) => {
        const cookie = CookieHelper.getCookieObj<{ browserGuid: string }>(CookieHelper.names.browserId);
        if (typeof cookie.browserGuid === 'undefined') {
            const guid = uuidv4();
            CookieHelper.setCookieObj(CookieHelper.names.browserId, { browserGuid: guid }, 365 * 24 * 5);
            return guid;
        }
        return cookie.browserGuid;
    },
    getClientMachineName: () => window.navigator.userAgent,
    shouldUseOAuth: () => false,
    setWindowSize: (width, height) => {
        return;
    },
    getInitialCountry: () => languageCountryCodeMap[Strings.getLanguage() as keyof typeof languageCountryCodeMap],
    isOffline: () => false,
    isOutlookClient: () => false,
    setLanguage: (language) => {
        return;
    },
    getDeletedWebServiceAddress: () => null,
    createLocalDatabase: null,
    importFromPreviousAccount: null,
    getLocalDatabaseCreationProgress: null,
    isUrlSecure: null,
    checkLogin: null,
    checkWebService: null,
    tryToAuthorize: null,
    changePassword: null,
    importFromExcel: null,
    importFromOutlook: null,
    importSampleData: null,
};

export const getEwayObject = (): TEwayBrowserBoundObject => {
    if (!window.eway) {
        return defaultEwayObject;
    }

    return window.eway as unknown as TEwayBrowserBoundObject;
};
