import currencyList from '../../localization/currencyList';
import { countries } from 'countries-list';
import { IDropdownOption } from '@fluentui/react';
import defaultLanguages from './data/defaultLanguages';
import Strings from '../../localization/strings';

export default class CompanyInfoHelper {
    static getCompanyLanguage = (phoneDialCode: IDropdownOption | null) => {
        if (!phoneDialCode) {
            return "en";
        }

        const countryLanguages = countries[phoneDialCode.key as keyof typeof countries].languages;
        const languageOption = defaultLanguages.find((l) => countryLanguages.includes(l.key));

        return languageOption?.key ?? "en";
    };

    static getCompanyCurrency = (phoneDialCode: IDropdownOption | null, lang?: string) => {
        const currentLang = lang ?? Strings.getLanguage();

        const localizedCurrencies = currencyList[currentLang as keyof typeof currencyList];
        const currencyOptions: IDropdownOption[] = Object.entries(localizedCurrencies).map(([key, val]) => ({
            key,
            text: `${val.name} (${key})`,
            data: val.symbol_native,
        }));

        const phoneDialCodeKey = phoneDialCode?.key ?? "en";
        const currency = countries[phoneDialCodeKey as keyof typeof countries].currency.slice(0, 3);
        const currencyExistsInList = !!localizedCurrencies[currency as keyof typeof localizedCurrencies];

        const currencyOption = currencyOptions.find((o) => o.key === (currencyExistsInList ? currency : 'USD'));
        return currencyOption!.key;
    };
}