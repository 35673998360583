import { FormContext } from "../../providers/FormProvider";
import { useContext, useEffect, useMemo } from 'react';
import Strings from "../../localization/strings";
import useValidatePreviousFormikOnMount from "../../helpers/hooks/useValidatePreviousFormikOnMount";
import PageBase from "../../components/PageBase/PageBase";
import CompanyPageGraphics from "./CompanyPageGraphics";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Dropdown, IDropdownProps, PrimaryButton, ResponsiveMode, TextField } from "@fluentui/react";
import industryImgDefault from '../../img/graphic-design/povolani_select_all-02-01-01.svg';
import getCompanyInfoTwoDropdownOptions from "./data/getCompanyInfoTwoDropdownOptions";
import FluentV8FieldHelper from "../../helpers/FluentV8FieldHelper";
import { StringHelper } from "../../helpers/StringHelper";

const CompanyInfoTwoPage = () => {
    const { companyInfoFormik, companyInfoTwoFormik, introFormik } = useContext(FormContext);
    const myStrings = Strings.companyInfoPage;
    const { crmExperienceOptions, outlookTypeOptions } = useMemo(() => getCompanyInfoTwoDropdownOptions(Strings), []);

    useValidatePreviousFormikOnMount(companyInfoFormik);
    const roleTextFieldError = companyInfoTwoFormik.touched.role ? companyInfoTwoFormik.errors.role : undefined;
    const websiteTextFieldError = companyInfoTwoFormik.touched.website ? companyInfoTwoFormik.errors.website : undefined;

    useEffect(() => {
        const fillWebsiteIfEmpty = async () => {
            const website = StringHelper.getNonBlockedWebsiteFromEmail(introFormik.values.email);
            if (website) {
                await companyInfoTwoFormik.setFieldValue("website", website);
                await companyInfoTwoFormik.setFieldTouched("website", true);
            }
        };

        if (!companyInfoTwoFormik.values.website && introFormik.values.email && !companyInfoTwoFormik.touched.website) {
            void fillWebsiteIfEmpty();
        }
    }, [companyInfoTwoFormik, introFormik.values.email]);

    const handleOutlookTypeChange: IDropdownProps['onChange'] = (e, option) => {
        if (option) {

            const newVal = option.selected ? [...companyInfoTwoFormik.values.outlookType, option.key as string] : companyInfoTwoFormik.values.outlookType.filter(key => key !== option.key);
            void companyInfoTwoFormik.setFieldValue('outlookType', newVal);
        }
    };

    return (
        <PageBase imgSrc={industryImgDefault} imgComponent={<CompanyPageGraphics />}>
            <form className="company-info-page" onSubmit={companyInfoTwoFormik.handleSubmit}>
                <PageTitle title={Strings.companyInfoPage.titlePage2} subtitle={Strings.companyInfoPage.subtitlePage2} />
                <div className='company-info-page__dropdowns'>
                    <TextField
                        type="text"
                        label={myStrings.rolePlaceholder}
                        className="company-info-page__field"
                        {...companyInfoTwoFormik.getFieldProps('role')}
                        errorMessage={roleTextFieldError}
                        autoComplete="off"
                        maxLength={255}
                    />
                    <Dropdown
                        options={crmExperienceOptions}
                        label={myStrings.crmExperiencePlaceholder}
                        className="company-info-page__field"
                        {...companyInfoTwoFormik.getFieldProps('crmExperience')}
                        {...FluentV8FieldHelper.getDropdownFormikProps(companyInfoTwoFormik, 'crmExperience')}
                        selectedKey={companyInfoTwoFormik.values.crmExperience?.key}
                        onChange={(e, o) => void companyInfoTwoFormik.setFieldValue('crmExperience', o)}
                        responsiveMode={ResponsiveMode.large}
                    />
                    <Dropdown
                        multiSelect
                        options={outlookTypeOptions}
                        label={myStrings.outlookType.title}
                        className="company-info-page__field"
                        {...companyInfoTwoFormik.getFieldProps('outlookType')}
                        {...FluentV8FieldHelper.getDropdownFormikProps(companyInfoTwoFormik, 'outlookType')}
                        selectedKeys={companyInfoTwoFormik.values.outlookType}
                        onChange={handleOutlookTypeChange}
                        responsiveMode={ResponsiveMode.large}
                    />
                    <TextField
                        type="text"
                        label={myStrings.websitePlaceholder}
                        className="company-info-page__field company-info-page__field-with-prefix"
                        errorMessage={websiteTextFieldError}
                        prefix="https://"
                        {...companyInfoTwoFormik.getFieldProps('website')}
                        maxLength={255}
                    />
                </div>
                <PrimaryButton className="page-base__next-btn" type="submit" disabled={!companyInfoTwoFormik.isValid}>
                    {Strings.next}
                </PrimaryButton>
            </form>
        </PageBase>
    );
};

export default CompanyInfoTwoPage;