export default class WebAccessPreloaderHelper {
    private static getResourceFiles = (regex: RegExp, html: string) => {
        const files: string[] = [];
        const matches = html.match(regex);
        if (matches) {
            matches.forEach(m => {
                const r = regex.exec(m);
                if (r && r[1]) {
                    files.push(r[1]);
                }
            });
        }
        return files;
    };

    static readonly wakeUp = async (hostingWebServiceUrl: string) => {
        await new Promise<void>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${hostingWebServiceUrl}/Web/#/`);
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");

            xhr.onload = () => {
                resolve();
            };

            xhr.onerror = (e) => {
                console.error('Error when waking up WA.');
                reject(e);
            };

            xhr.send();
        });
    };

    static readonly wakeUpAndGetResourceFiles = (hostingWebServiceUrl: string, resourceFilesCb: (files: string[] | null) => void) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${hostingWebServiceUrl}/Web/#/`);
        xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
        xhr.onload = () => {
            if (xhr.status !== 200) {
                console.error('Error when preloading WA doc to find the large scripts.', `Error ${xhr.status}: ${xhr.statusText}`);
                return;
            }

            if (!xhr.responseText) {
                console.error('Error when preloading WA doc to find the large scripts.', 'Empty response.');
                return;
            }

            const scripts = WebAccessPreloaderHelper.getResourceFiles(/<script[^>]*src="?[^"]*static\/js\/([^">\s]*\.js)"?[^>]*\/?>/gi, xhr.responseText);
            const styles = WebAccessPreloaderHelper.getResourceFiles(/<link[^>]*href="?[^"]*static\/css\/([^">\s]*\.css)"?[^>]*\/?>/gi, xhr.responseText);

            resourceFilesCb([
                ...scripts.map(s => `static/js/${s}`),
                ...styles.map(s => `static/css/${s}`)
            ]);
        };

        xhr.onerror = (e) => {
            console.error('Error when preloading WA doc to find the large scripts.');
        };
        xhr.send();
    };
}