import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchParams from '../../constants/SearchParams';
import { RegisterAction } from '../../providers/FormProvider';
import { LanguageContext } from '../../providers/LanguageProvider';

const useMySearchParams = (setRegisterAction: React.Dispatch<React.SetStateAction<RegisterAction | null>>) => {
    const [urlParams, setUrlParams] = useState<URLSearchParams | null>(null);

    const { onLanguageSwitch } = useContext(LanguageContext);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (urlParams) {
            return;
        }

        // Save URL params on page load
        const hasAnySearchParams = !!searchParams.keys().next().value;
        if (hasAnySearchParams) {
            setUrlParams(searchParams);

            const langParam = searchParams.get(SearchParams.lang);
            if (langParam) {
                onLanguageSwitch(langParam);
            }

            const planParam = searchParams.get(SearchParams.plan)?.toLowerCase();
            if (planParam) {
                if (planParam === 'free') {
                    setRegisterAction(RegisterAction.SignUpFree);
                } else if (['basic', 'premium', 'enterprise'].includes(planParam)) {
                    setRegisterAction(RegisterAction.SignUpPremium);
                }
            }
        }
    }, [urlParams, setUrlParams, searchParams, setSearchParams, onLanguageSwitch, setRegisterAction]);

    return { urlParams };
};

export default useMySearchParams;
