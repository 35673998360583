import { IDropdownOption } from "@fluentui/react";
import Strings from "../../../localization/strings";
import getCompanyIndustryOptions from "./companyIndustry";

export const PURPOSE_GUIDS = {
    contactsAndCompanies: "9BE49673-E2E0-470C-9F7A-658B8B47D5D1",
    trackingActivities: "D5DC283B-EF88-4B5D-ADE2-9B31FE093162",
    managingSales: "36615996-5720-4448-881A-3BB6A7C5F9E6",
    managingJobs: "E797AEAF-8950-465C-8189-2677C3374AE7",
    emailMarketing: "297CC89B-7F09-4C22-8255-E55EC8140594",
};

const getCompanyInfoDropdownOptions = (strings: typeof Strings, lang?: string) => {
    const currentLang = lang ?? strings.getLanguage();

    const companyIndustryOptions = getCompanyIndustryOptions();

    const numberToLocaleString = (number: number) => number.toLocaleString(currentLang, {});

    const to = strings.getString('companyInfoPage.to', currentLang);
    const orMore = strings.getString('companyInfoPage.orMore', currentLang);

    const companySizeOptions: IDropdownOption[] = [
        { key: 1, text: '1' },
        { key: 5, text: `2 ${to} 5` },
        { key: 10, text: `6 ${to} 10` },
        { key: 25, text: `11 ${to} 25` },
        { key: 50, text: `26 ${to} 50` },
        { key: 200, text: `51 ${to} 200` },
        { key: 1000, text: `201 ${to} ${numberToLocaleString(1000)}` },
        { key: 10000, text: `${numberToLocaleString(1001)} ${to} ${numberToLocaleString(10000)}` },
        { key: 10001, text: `${numberToLocaleString(10001)} ${orMore}` },
    ];

    const purposeOptions: IDropdownOption[] = [
        { key: PURPOSE_GUIDS.contactsAndCompanies, text: strings.getString('companyInfoPage.purpose.contactsAndCompanies', currentLang) },
        { key: PURPOSE_GUIDS.trackingActivities, text: strings.getString('companyInfoPage.purpose.trackingActivities', currentLang) },
        { key: PURPOSE_GUIDS.managingSales, text: strings.getString('companyInfoPage.purpose.managingSales', currentLang) },
        { key: PURPOSE_GUIDS.managingJobs, text: strings.getString('companyInfoPage.purpose.managingJobs', currentLang) },
        { key: PURPOSE_GUIDS.emailMarketing, text: strings.getString('companyInfoPage.purpose.emailMarketing', currentLang) },
    ];

    return {
        companyIndustryOptions,
        companySizeOptions,
        purposeOptions
    };
};

export default getCompanyInfoDropdownOptions;