import industryImgConstruction from '../../../img/graphic-design/povolani_Constrction-02-01.svg';
import industryImgConsulting from '../../../img/graphic-design/povolani_Consulting-02-01.svg';
import industryImgCreative from '../../../img/graphic-design/povolani_Creative-02-01.svg';
import industryImgEducation from '../../../img/graphic-design/povolani_education-02-01.svg';
import industryImgFinance from '../../../img/graphic-design/povolani_finance-02-01.svg';
import industryImgFood from '../../../img/graphic-design/povolani_food-02-01.svg';
import industryImgGovernment from '../../../img/graphic-design/povolani_Government-02-01.svg';
import industryImgHealthCare from '../../../img/graphic-design/povolani_health_care-02-01.svg';
import industryImgIT from '../../../img/graphic-design/povolani_IT-02-01.svg';
import industryImgLegal from '../../../img/graphic-design/povolani_legal-02-01.svg';
import industryImgManufacturing from '../../../img/graphic-design/povolani_Manufactiring-02-01.svg';
import industryImgMedia from '../../../img/graphic-design/povolani_media-02-01.svg';
import industryImgNonProfit from '../../../img/graphic-design/povolani_non_profit-02-01.svg';
import industryImgRealEstate from '../../../img/graphic-design/povolani_real_estate-01.svg';
import industryImgRetail from '../../../img/graphic-design/povolani_retail-02-01.svg';
import industryImgTravel from '../../../img/graphic-design/povolani_travel-02-01.svg';
import industryImgLogistics from '../../../img/graphic-design/povolani_Logistics-01.svg';
import industryImgInsuranceBroker from '../../../img/graphic-design/povolani_Insurance-Broker-01.svg';
import industryImgDefault from '../../../img/graphic-design/povolani_select_all-02-01-01.svg';

import { IDropdownOption } from '@fluentui/react';
import LocalizationHelper from '../../../localization/LocalizationHelper';

/**
 * Guids taken from our production eWay, field Company Industry
 */

const getCompanyIndustryOptions = (): IDropdownOption[] => {
    return companyIndustry
        .map((c) => ({ key: c.itemGuid, text: LocalizationHelper.isLanguageCs() ? c.cs : c.en }))
        .sort((a, b) => a.text.localeCompare(b.text))
        .concat({ key: otherCompanyIndustry.itemGuid, text: LocalizationHelper.isLanguageCs() ? otherCompanyIndustry.cs : otherCompanyIndustry.en });
};

const companyIndustry = [
    {
        itemGuid: '8C8A5DF0-B926-4BBB-A8F9-C0CDF3929B80',
        en: 'Construction/Home Service',
        cs: 'Stavebnictví',
        img: industryImgConstruction,
    },
    {
        itemGuid: '9B1241F7-0277-4C13-873B-4E08288E748F',
        en: 'Consulting',
        cs: 'Poradenství',
        img: industryImgConsulting,
    },
    {
        itemGuid: 'CEA63BEF-9F8B-45D6-BEE1-4BB5B493C2E3',
        en: 'Creative Agency (Web, Advertising, Video)',
        cs: 'Kreativní agentura (web, reklama, video)',
        img: industryImgCreative,
    },
    {
        itemGuid: '427D9BE9-FF35-48E5-BF7E-A0932224214E',
        en: 'Education and Training',
        cs: 'Vzdělávání a školení',
        img: industryImgEducation,
    },
    {
        itemGuid: '037452D0-53DC-43B9-BC15-407AB6627E86',
        en: 'Finance',
        cs: 'Finance',
        img: industryImgFinance,
    },
    {
        itemGuid: 'A0E4EB25-12DC-48BD-A606-A4CAF991BAC2',
        en: 'Food, Catering and Restaurants',
        cs: 'Potravinářství, catering a restaurace',
        img: industryImgFood,
    },
    {
        itemGuid: '5B0C9B91-EEBC-4CEA-A943-9F56B54FCCAA',
        en: 'Government',
        cs: 'Vláda',
        img: industryImgGovernment,
    },
    {
        itemGuid: 'A28C2DC3-AAC6-4A36-9B41-0A5FFF9BEFC8',
        en: 'Health Care',
        cs: 'Zdravotnictví',
        img: industryImgHealthCare,
    },
    {
        itemGuid: '59440A46-1693-4CAA-A135-1FB6E2509DD5',
        en: 'IT/Technology',
        cs: 'IT/Technologie',
        img: industryImgIT,
    },
    {
        itemGuid: '317C93B6-E35E-426A-A62D-9DBD499243F9',
        en: 'Legal',
        cs: 'Právo',
        img: industryImgLegal,
    },
    {
        itemGuid: 'A743718D-D7E2-4A35-AAD5-C39899AEB80B',
        en: 'Manufacturing',
        cs: 'Výroba',
        img: industryImgManufacturing,
    },
    {
        itemGuid: 'B3A61B76-F566-4D18-9483-6735A5587767',
        en: 'News, Media and Publications',
        cs: 'Zpravodajství, média a vydavatelství',
        img: industryImgMedia,
    },
    {
        itemGuid: 'F8196007-EAFE-460B-8AA4-CF839C85B3E4',
        en: 'Non-Profit',
        cs: 'Neziskový sektor',
        img: industryImgNonProfit,
    },
    {
        itemGuid: 'F2E43027-3087-4F00-AB31-DE3994A1037D',
        en: 'Real Estate',
        cs: 'Nemovitosti',
        img: industryImgRealEstate,
    },
    {
        itemGuid: '92817256-312D-46A2-970B-77F8F6E3FD07',
        en: 'Retail',
        cs: 'Obchod',
        img: industryImgRetail,
    },
    {
        itemGuid: 'C67B1B03-49B4-477B-9C18-6BF1D599E06D',
        en: 'Travel, Touring and Hotels',
        cs: 'Cestování, turistika a hotely',
        img: industryImgTravel,
    },
    {
        itemGuid: '6F212230-B182-11EC-A44C-A3CC1B967872',
        en: 'Logistics and Transportation',
        cs: 'Logistika a doprava',
        img: industryImgLogistics
    },
    {
        itemGuid: '2C96D4C0-52D8-11EE-9835-578C046278BA',
        en: 'Insurance Broker',
        cs: 'Pojišťovací agent',
        img: industryImgInsuranceBroker
    },
];

const otherCompanyIndustry = {
    itemGuid: '19BBA59D-7FDD-4948-9A54-F46BDE950EDD',
    en: 'Other',
    cs: 'Ostatní',
    img: industryImgDefault,
};

export const allCompanyIndustries = companyIndustry.concat(otherCompanyIndustry);

export default getCompanyIndustryOptions;
