import { PrimaryButton, TextField, mergeStyles } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import PageBase from '../../components/PageBase/PageBase';
import PageTitle from '../../components/PageTitle/PageTitle';
import Strings from '../../localization/strings';
import { FormContext } from '../../providers/FormProvider';
import './ChoosePasswordPage.scss';
import pageImg from '../../img/graphic-design/4-02.svg';
import useValidatePreviousFormikOnMount from '../../helpers/hooks/useValidatePreviousFormikOnMount';
import { PasswordHelper } from '../../helpers/PasswordHelper';
import { useDebounce } from '@eway-crm/gui';

const PASSWORD_DEBOUNCE_DELAY = 500;

const isUsingAdmissableChars = (password: string) => {
    const regex = /^[\s\w\d\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]*$/; // Taken from https://stackoverflow.com/a/47620604/14334319
    return !!regex.exec(password);
};

export type TPasswordStrength = {
    IsAdmissible: boolean;
    Score: number;
};

const ChoosePasswordPage = () => {
    const { choosePasswordFormik, userInfoFormik, hostingApi, introFormik } = useContext(FormContext);
    const [passwordStrength, setPasswordStrength] = useState<TPasswordStrength | null>(null);
    const password = choosePasswordFormik.values.password;
    const debouncedPassword = useDebounce(password, PASSWORD_DEBOUNCE_DELAY);
    const [isCheckingPasswordStrength, setIsCheckingPasswordStrength] = useState(false);
    const myStrings = Strings.choosePasswordPage;

    useValidatePreviousFormikOnMount(userInfoFormik);

    const isPasswordUsingAdmissableChars = isUsingAdmissableChars(password);

    const getPasswordStrengthValues = (passStrength: TPasswordStrength | null) => {
        if (!passStrength || !password) {
            return null;
        }

        if (!isPasswordUsingAdmissableChars) {
            return { classNameEnd: 'too-weak', strengthDescription: myStrings.invalidChars };
        }

        if (!passStrength.IsAdmissible || passStrength.Score < 2) {
            return { classNameEnd: 'too-weak', strengthDescription: myStrings.tooWeak };
        } else if (passStrength.Score < 3 ) {
            return { classNameEnd: 'weak', strengthDescription: myStrings.weak };
        } else if (passStrength.Score < 4) {
            return { classNameEnd: 'good', strengthDescription: myStrings.good };
        } else {
            return { classNameEnd: 'strong', strengthDescription: myStrings.strong };
        }
    };

    useEffect(() => {
        if (!debouncedPassword) {
            setPasswordStrength(null);
            setIsCheckingPasswordStrength(false);
            return;
        }
        let isCurrent = true;
        const _checkedPassword = debouncedPassword;

        const checkPasswordStrength = async () => {
            setIsCheckingPasswordStrength(true);
            const checkPasswordStrengthRes = await hostingApi.checkPasswordStrength({ username: introFormik.values.email, password: debouncedPassword });
            if (checkPasswordStrengthRes && (isCurrent || _checkedPassword === debouncedPassword)) {
                setIsCheckingPasswordStrength(false);
                setPasswordStrength(checkPasswordStrengthRes);
            }
        };

        void checkPasswordStrength();

        return () => {
            isCurrent = false;
        };

    }, [debouncedPassword, hostingApi, introFormik.values.email]);

    const handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        const { setTouched, setValue } = choosePasswordFormik.getFieldHelpers('password');
        if (newValue && newValue !== debouncedPassword) {
            setIsCheckingPasswordStrength(true);
        }

        void setTouched(true);
        void setValue(newValue);
    };

    const passwordStrengthValues = getPasswordStrengthValues(passwordStrength);

    return (
        <PageBase imgSrc={pageImg}>
            <form className="choose-password-page" onSubmit={choosePasswordFormik.handleSubmit}>
                <PageTitle title={myStrings.title} />
                <TextField type="password" canRevealPassword maxLength={256} autoComplete='new-password' {...choosePasswordFormik.getFieldProps('password')} onChange={handleChange} />
                <div className={mergeStyles("choose-password-page__strength-wrap", !password && "hidden" )}>
                    <div className={mergeStyles(passwordStrengthValues && `choose-password-page__${passwordStrengthValues.classNameEnd}`)} />
                </div>
                <div className="choose-password-page__strength-description">
                    {password && passwordStrengthValues && passwordStrengthValues.strengthDescription}
                </div>
                <PrimaryButton
                    className="page-base__next-btn"
                    type="submit"
                    disabled={isCheckingPasswordStrength || !choosePasswordFormik.isValid || !PasswordHelper.isStrongEnough(passwordStrength) || !isPasswordUsingAdmissableChars || debouncedPassword !== password}
                >
                    {Strings.next}
                </PrimaryButton>
            </form>
        </PageBase>
    );
};

export default ChoosePasswordPage;
