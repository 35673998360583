type TListener<TStore> = (dataStore: TStore) => void;
type Selector<TStore, T> = (store: TStore) => T;

export default class Observable<TStore> {
    protected store: TStore;
    private readonly listeners = new Set<TListener<TStore>>();

    constructor(initialStore: TStore) {
        this.store = initialStore;
    }

    public readonly subscribe = (listener: TListener<TStore>) => {
        this.listeners.add(listener);
        return () => this.listeners.delete(listener);
    };

    public readonly unsubscribe = (listener: TListener<TStore>) => {
        this.listeners.delete(listener);
    };

    private readonly emitChange = () => {
        this.listeners.forEach((listener) => listener(this.store));
    };

    public readonly setStore = (callbackOrPartialStore: Partial<TStore> | ((prevStore: TStore) => Partial<TStore>)) => {
        if (typeof callbackOrPartialStore === 'function') {
            const partialStore = callbackOrPartialStore(this.store);
            this.store = { ...this.store, ...partialStore };
        }
        else {
            this.store = { ...this.store, ...callbackOrPartialStore };
        }
        this.emitChange();
    };

    public readonly selectDataExternally = <T>(selector: Selector<TStore, T>) => {
        return selector(this.store);
    };
}