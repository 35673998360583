export enum FinalizationSteps {
    Start,
    CreateHosting,
    WaitingForAllTrialHostingInfoSchedule,
    AllTrialHostingInfoScheduled,
    AddingTrialHostingInfo,
    HostingCreatedWithAllInfo,
    ApiDataSaved,
    AfterSaveActionsDone,
    CreateLocalDatabase,
    Finish,
    Error,
    DuplicateEmailRequestError
}