import { ComboBox, IComboBoxOption, PrimaryButton, TextField } from '@fluentui/react';
import PageBase from '../../components/PageBase/PageBase';
import Strings from '../../localization/strings';
import './UserInfoPage.scss';
import { useContext, useEffect, useState } from 'react';
import getLocalizedCountries from 'localized-countries';
import { allCountries } from 'country-telephone-data';
import { FormContext } from '../../providers/FormProvider';
import PageTitle from '../../components/PageTitle/PageTitle';
import pageImgEn from '../../img/graphic-design/3-02_en.svg';
import pageImgCs from '../../img/graphic-design/3-02_cs.svg';
import pageImgSk from '../../img/graphic-design/3-02_sk.svg';
import useValidatePreviousFormikOnMount from '../../helpers/hooks/useValidatePreviousFormikOnMount';
import LocalizationHelper from '../../localization/LocalizationHelper';

const getPageImg = () => {
    if (LocalizationHelper.isLanguageCs()) {
        return pageImgCs;
    } else if (LocalizationHelper.isLanguageSk()) {
        return pageImgSk;
    }

    return pageImgEn;
};

export const loadCountryCallingCodeOptions = async () => {
    const language = Strings.getLanguage();
    // eslint-disable-next-line
    const localizedCountriesData = await require(`localized-countries/data/${language}.json`);
    const localizedCountries = getLocalizedCountries(localizedCountriesData as object).array();
    const localizedCountriesMap: { [code: string]: string } = {};
    localizedCountries.forEach((v) => {
        localizedCountriesMap[v.code] = v.label;
    });

    const options: IComboBoxOption[] = allCountries.map((country) => {
        const isoCountryCode = country.iso2.toUpperCase();

        return {
            key: isoCountryCode,
            text: `${localizedCountriesMap[isoCountryCode] ?? country.name} (+${country.dialCode})`,
            data: country.dialCode,
        };
    });

    return options;
};

const UserInfoPage = () => {
    const { userInfoFormik, introFormik, isLoggedViaMs } = useContext(FormContext);
    const [phoneOptions, setPhoneOptions] = useState<IComboBoxOption[]>([]);
    const myStrings = Strings.userInfoPage;

    useValidatePreviousFormikOnMount(introFormik, isLoggedViaMs && !!introFormik.values.email);

    useEffect(() => {
        const changePhoneDialCode = async (option?: IComboBoxOption) => {
            if (!option) {
                return;
            }

            await userInfoFormik.setFieldValue('phoneDialCode', option);

            if (!!userInfoFormik.values.phoneNumber) {
                // Is prefilled from MS
                const replaceValue = `+${option.data as string}`;
                const regex = new RegExp(`\\${replaceValue}+\\s?`);
                const newPhoneNumber = userInfoFormik.values.phoneNumber.replace(regex, "");
                await userInfoFormik.setFieldValue('phoneNumber', newPhoneNumber);
            }
        };

        const loadCountries = async () => {
            const options = await loadCountryCallingCodeOptions();
            const sortedOptions = options.sort((a, b) => a.text.localeCompare(b.text));

            setPhoneOptions(sortedOptions);
            if (!userInfoFormik.values.phoneDialCode) {
                if (LocalizationHelper.isLanguageCs()) {
                    const csOption = sortedOptions.find((o) => o.key === 'CZ');
                    await changePhoneDialCode(csOption);
                } else if (LocalizationHelper.isLanguageSk()) {
                    const skOption = sortedOptions.find((o) => o.key === 'SK');
                    await changePhoneDialCode(skOption);
                } else {
                    const usOption = sortedOptions.find((o) => o.key === 'US');
                    await changePhoneDialCode(usOption);
                }
            }
        };

        if (phoneOptions.length === 0) {
            void loadCountries();
        }
    }, [userInfoFormik, phoneOptions]);

    const companyNameErrorMessage = userInfoFormik.touched.companyName ? userInfoFormik.errors.companyName : undefined;
    const phoneNumberErrorMessage = userInfoFormik.touched.phoneNumber ? userInfoFormik.errors.phoneNumber : undefined;
    const { nameDeclension } = introFormik.values;

    return (
        <PageBase imgSrc={getPageImg()}>
            <form onSubmit={userInfoFormik.handleSubmit}>
                <PageTitle title={nameDeclension ? Strings.formatString(myStrings.niceToMeetYouName, nameDeclension) as string : myStrings.niceToMeetYou} />
                <div>
                    <TextField
                        placeholder={myStrings.companyNamePlaceholder}
                        autoComplete="organization"
                        {...userInfoFormik.getFieldProps('companyName')}
                        errorMessage={companyNameErrorMessage}
                        className="user-info-page__company-input"
                        maxLength={256}
                    />
                    <div className="user-info-page__phone-inputs">
                        <ComboBox options={phoneOptions} selectedKey={userInfoFormik.values.phoneDialCode?.key} onChange={(e, o) => void userInfoFormik.setFieldValue('phoneDialCode', o)} />
                        <TextField
                            placeholder={myStrings.phoneNumberPlaceholder}
                            autoComplete="tel-national"
                            className="user-info-page__phone-input"
                            {...userInfoFormik.getFieldProps('phoneNumber')}
                            errorMessage={phoneNumberErrorMessage}
                            maxLength={30} // Max is 15, but leave room for spaces etc.
                        />
                    </div>
                </div>
                <PrimaryButton className="page-base__next-btn" type="submit" disabled={!userInfoFormik.isValid}>
                    {Strings.next}
                </PrimaryButton>
            </form>
        </PageBase>
    );
};

export default UserInfoPage;
