import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Strings from '../../localization/strings';
import blankDbImg from '../../img/icons-import/Document_2-01.svg';
import excelImportImg from '../../img/icons-import/excel-01.svg';
import outlookImportImg from '../../img/icons-import/outlook-01.svg';
// import microsoft365ImportImg from '../../img/icons-vendors/microsoft_365_2022.svg';
import sampleDatabaseImg from '../../img/icons-import/kontakt_2-01.svg';
import { getEwayObject } from '../../helpers/ewayObjectHelper';
import RouteConfig from '../../config/RouteConfig';
import { FormContext, ImportDatabaseType } from '../../providers/FormProvider';
import { FontIcon, TooltipHost } from '@fluentui/react';

type TImportDataTile = {
    title: React.ReactNode;
    onClick: () => void;
    icon: string;
    bgColor: string;
};

const useImportDataTiles = () => {
    // const {  } = useContext(FormContext);
    const myStrings = Strings.importDataPage;
    const eway = getEwayObject();
    const navigate = useNavigate();
    const { setImportDatabaseType } = useContext(FormContext);
    const deletedWsAddress = eway.getDeletedWebServiceAddress?.();

    let importDataTiles: TImportDataTile[] = [
        // TODO: #42928 : Allow when ready...
        // {
        //     title: myStrings.importFromO365,
        //     onClick: () => {
        //         if (!hostingInfo || !accountsInfo) {
        //             console.log('Hosting info or account info is missing, could not navigate to import contacts form O365 page.');
        //             return;
        //         }

        //         RedirectToImportContactsFromO365Page(hostingInfo, accountsInfo, isLoggedViaMs, () => navigate(RouteConfig.otherPages.finalizationFailed));
        //     },
        //     icon: microsoft365ImportImg,
        //     bgColor: '#dfd0f5',
        // },        
        {
            title: myStrings.importFromOutlook,
            onClick: () => {
                setImportDatabaseType(ImportDatabaseType.Outlook);
                navigate(RouteConfig.outlookPages.importFromOutlook);
            },
            icon: outlookImportImg,
            bgColor: '#daedfd',
        },
        {
            title: myStrings.importFromExcel,
            onClick: () => {
                eway.importFromExcel && eway.importFromExcel();
            },
            icon: excelImportImg,
            bgColor: '#CAEAD8',
        },
        {
            title: myStrings.sampleDatabase,
            onClick: () => {
                setImportDatabaseType(ImportDatabaseType.SampleData);
                navigate(RouteConfig.outlookPages.importProgress);
            },
            icon: sampleDatabaseImg,
            bgColor: '#F9DAD4',
        },
        {
            title: myStrings.blankDatabase,
            onClick: () => {
                setImportDatabaseType(ImportDatabaseType.Blank);
                navigate(RouteConfig.outlookPages.importSuccess);
            },
            icon: blankDbImg,
            bgColor: '#cfcfce',
        },
    ];

    if (deletedWsAddress) {
        // Replace sample db tile

        const importFromDeletedWsTile = {
            title: <span>{myStrings.importFromDeletedWs} <TooltipHost content={Strings.formatString(myStrings.importFromDeletedWsTooltip, deletedWsAddress) as string}><FontIcon iconName={"Info"}/></TooltipHost></span>,
            onClick: () => {
                setImportDatabaseType(ImportDatabaseType.DeletedWsData);
                navigate(RouteConfig.outlookPages.importProgress);
            },
            icon: sampleDatabaseImg,
            bgColor: '#F9DAD4',
        };

        importDataTiles = importDataTiles.map(tile => tile.title === myStrings.sampleDatabase ? importFromDeletedWsTile : tile);
    }

    return importDataTiles;
};

export default useImportDataTiles;
