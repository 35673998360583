import React, { createContext } from 'react';
import RouteConfig from '../../config/RouteConfig';
import usePageMatch from '../../helpers/hooks/usePageMatch';
import Finalization from '../../pages/FinalizationPage/Finalization/Finalization';
import { FormContext, FormFinalizationAction } from '../FormProvider';
import { LanguageContext } from '../LanguageProvider';
import useFinalizationErrorDetection from './useFinalizationErrorDetections';

type TFinalizationContext = {
    finalization: Finalization | null;
};
export const FinalizationContext = createContext<TFinalizationContext>(null!);


const useFinalization = () => {
    const [finalization, setFinalization] = React.useState<Finalization | null>(null);
    const formContext = React.useContext(FormContext);
    const languageContext = React.useContext(LanguageContext);
    const isOnFinalizationPage = usePageMatch(RouteConfig.pages.finalization);
    useFinalizationErrorDetection(finalization);

    React.useEffect(() => {
        // Initiate finalization
        if (formContext.formFinalizationAction === FormFinalizationAction.StartSignUp && !finalization) {
            const finalizationActions = new Finalization(formContext, languageContext);
            setFinalization(finalizationActions);
            finalizationActions.nextStep();
        }
    }, [formContext, languageContext, finalization]);

    React.useEffect(() => {
        if (isOnFinalizationPage && finalization?.selectDataExternally(x => !x.isUpdateTrialHostingInfoScheduled)) {
            finalization.scheduleUpdateTrialInfo(formContext, languageContext);
        }
    }, [isOnFinalizationPage, finalization, formContext, languageContext]);

    React.useEffect(() => {
        // Initiate finalization for sign in
        if (formContext.formFinalizationAction === FormFinalizationAction.StartAfterSignIn && !finalization) {
            const finalizationActions = new Finalization(formContext, languageContext);
            setFinalization(finalizationActions);
            finalizationActions.nextStep();
        }
    }, [formContext, languageContext, finalization]);

    return finalization;
};

const FinalizationProvider: React.FC = ({ children }) => {
    const finalization = useFinalization();

    return <FinalizationContext.Provider value={{ finalization }}>{children}</FinalizationContext.Provider>;
};

export default FinalizationProvider;
