import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RouteConfig from "../../config/RouteConfig";
import { getEwayObject } from "../../helpers/ewayObjectHelper";

const useCheckWebServiceDeleted = () => {
    const [hasDeletedWsUrlNavigated, setHasDeletedWsUrlNavigated] = useState(false);
    const navigate = useNavigate();
    const eway = getEwayObject();

    useEffect(() => {
        const deletedWsUrl = eway.getDeletedWebServiceAddress?.();
        if (deletedWsUrl && !hasDeletedWsUrlNavigated) {
            setHasDeletedWsUrlNavigated(true);
            navigate(RouteConfig.otherPages.wsUnavailable);
        }
    }, [navigate, eway, hasDeletedWsUrlNavigated]);
};

export default useCheckWebServiceDeleted;