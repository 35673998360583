import { IDropdownOption } from "@fluentui/react";
import Strings from "../../../localization/strings";

export const OUTLOOK_TYPE_GUIDS = {
    desktopClassic: "645F6F0C-7F72-4087-BC12-6F484BB0799F",
    desktopNew: "EBBC51BC-2A25-4E7D-A733-D69EA650C71E",
    mac: "FCD235ED-DE83-4779-B1CE-59664E3225F5",
    online: "686EC708-D97D-4F0B-9274-ECC9D4295548",
    noneYet: "7E4676CC-B309-440C-AA09-1BE007625025",
};

export const OUTLOOK_GUID_NAME_MAP = {
    [OUTLOOK_TYPE_GUIDS.desktopClassic]: "DesktopClassic",
    [OUTLOOK_TYPE_GUIDS.desktopNew]: "DesktopNew",
    [OUTLOOK_TYPE_GUIDS.mac]: "Mac",
    [OUTLOOK_TYPE_GUIDS.online]: "Online",
    [OUTLOOK_TYPE_GUIDS.noneYet]: "NoneYet"
};

const getCompanyInfoTwoDropdownOptions = (strings: typeof Strings, lang?: string) => {
    const currentLang = lang ?? strings.getLanguage();

    const noCrmExperience = strings.getString('companyInfoPage.noCrmExperience', currentLang);
    const excelCrmExperience = strings.getString('companyInfoPage.excelCrmExperience', currentLang);
    const pastProfessionalCrmExperience = strings.getString('companyInfoPage.pastProfessionalCrmExperience', currentLang);
    const professionalCrmExperience = strings.getString('companyInfoPage.professionalCrmExperience', currentLang);

    const crmExperienceOptions: IDropdownOption[] = [
        { key: 1, text: noCrmExperience },
        { key: 2, text: excelCrmExperience },
        { key: 3, text: pastProfessionalCrmExperience },
        { key: 4, text: professionalCrmExperience },
    ];

    const desktopClassic = strings.getString('companyInfoPage.outlookType.desktopClassic', currentLang);
    const desktopNew = strings.getString('companyInfoPage.outlookType.desktopNew', currentLang);
    const mac = strings.getString('companyInfoPage.outlookType.mac', currentLang);
    const online = strings.getString('companyInfoPage.outlookType.online', currentLang);
    const noneYet = strings.getString('companyInfoPage.outlookType.noneYet', currentLang);

    const outlookTypeOptions: IDropdownOption[] = [
        { key: OUTLOOK_TYPE_GUIDS.desktopClassic, text: desktopClassic },
        { key: OUTLOOK_TYPE_GUIDS.desktopNew, text: desktopNew },
        { key: OUTLOOK_TYPE_GUIDS.mac, text: mac },
        { key: OUTLOOK_TYPE_GUIDS.online, text: online },
        { key: OUTLOOK_TYPE_GUIDS.noneYet, text: noneYet },
    ];

    return { crmExperienceOptions, outlookTypeOptions };
};

export default getCompanyInfoTwoDropdownOptions;