import { TextField } from '@fluentui/react';
import { useEffect, useRef, useState } from 'react';

/**
 * Input that grows based on the size of its input
 * Inspired by https://stackoverflow.com/a/65024003/14334319
 */
type TGrowingInputProps = {
    value: string;
    onValueChange: (newValue: string) => void;
    onSubmit: () => void;
    invalid?: boolean;
    errorMessage?: string;
};

const GrowingInput: React.FC<TGrowingInputProps> = ({ value, onValueChange, onSubmit, invalid, errorMessage }) => {
    const [width, setWidth] = useState(0);
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanRef.current?.offsetWidth) {
            setWidth(spanRef.current.offsetWidth + 2);
        }
    }, [value]);

    return (
        <div className="email-verification-page__email-edit">
            <span className="email-verification-page__email-edit__hidden" ref={spanRef}>
                {value}
            </span>
            <TextField
                invalid={invalid}
                errorMessage={errorMessage}
                underlined={true}
                style={{ width }}
                onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
                autoFocus
                onChange={(e, newVal) => onValueChange(newVal ?? '')}
                value={value}
                maxLength={256}
            />
        </div>
    );
};

export default GrowingInput;
