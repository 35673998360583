import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import RouteConfig from '../../config/RouteConfig';

const useValidatePreviousFormikOnMount = <T>(prevFormik: FormikProps<T>, ignoreValidation?: boolean) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!ignoreValidation && !prevFormik.isValid) {
            console.warn('Missing information from previous steps, redirecting to first step.', prevFormik.errors);
            navigate(RouteConfig.pages.intro);
        }
    }, [prevFormik, ignoreValidation, navigate]);
};

export default useValidatePreviousFormikOnMount;
