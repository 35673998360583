import Strings from "../../../localization/strings";
import { RegisterAction, TFormContext } from "../../../providers/FormProvider";
import getCompanyInfoDropdownOptions from "../../CompanyInfoPage/data/getCompanyInfoDropdownOptions";
import getCompanyInfoTwoDropdownOptions from "../../CompanyInfoPage/data/getCompanyInfoTwoDropdownOptions";
import platform from 'platform';
import SearchParams from "../../../constants/SearchParams";
import { getEwayObject } from "../../../helpers/ewayObjectHelper";
import HostingApi, { TGetHostingActionStatusResponse, TGetInfoAboutHostingResponse, THostingActionRequestResponse, TRequestNewTrialHostingCreationErrorResponse, TRequestNewTrialHostingCreationResponse } from "@eway-crm/hostingdaemon-js-lib";
import { TLanguageContext } from "../../../providers/LanguageProvider";
import HostingConfig from "../../../config/HostingConfig";
import RegisterActionHelper from "../../../helpers/RegisterActionHelper";
import Connection, { RETRY_TIMEOUT } from "../../../api/Connection";
import { StringHelper } from "../../../helpers/StringHelper";
import md5 from "md5";
import { EnumTypes, GlobalSettingsNames, ReturnCodes } from "@eway-crm/connector";
import type { IApiDataResponse, IApiEnumValue } from "@eway-crm/connector";
import { v4 as uuidv4 } from 'uuid';
import DateHelper from "../../../helpers/DateHelper";
import { FinalizationSteps } from "../../../constants/FinalizationSteps";
import Observable from "../../../helpers/Observable";
import finalizationReducer, { TFinalizationReducerActions } from "./finalizationReducer";
import { TGetLocalDatabaseCreationProgressResponse } from "../../../types/ewayBrowserBoundObject";
import RoleKeys from "../../../constants/RoleKeys";
import WebAccessPreloaderHelper from "../../../helpers/WebAccessPreloaderHelper";

const ACTIVATE_AZURE_AD_AUTH_ACTION = 'ActivateAzureAdAuth()';
const WATCH_ACTION = 'Watch()';

// Use official types once highEntropyValues become standard
type THighEntropyValues = {
    mobile: boolean;
    platform: string;
    platformVersion: string;
};

export type TUserToCreate = { itemGuid: string; username: string; email: string; group: string; firstName?: string; lastName?: string; password?: string };

export type TFinalizationState = {
    hostingInfo: TGetInfoAboutHostingResponse | null;
    hostingCreationInfo: TRequestNewTrialHostingCreationResponse | null;
    accountsInfo: TUserToCreate[] | null;
    localDatabaseCreationInfo: TGetLocalDatabaseCreationProgressResponse | null;
    error: string | null;
    finalizationStep: FinalizationSteps;
    progressMessage: React.ReactNode;
    activateAzureAdAuthActionId: string | null;
    addTrialHostingInfo: THostingActionRequestResponse | null;
    addTrialHostingProgressInfo: TGetHostingActionStatusResponse | null;
    isUpdateTrialHostingInfoScheduled: boolean;
    wasUpdateTrialHostingStartedImmediatelly: boolean | null;
    hostingCreationErrorInfo: TRequestNewTrialHostingCreationErrorResponse | null;
};

export const finalizationInitialState: TFinalizationState = {
    hostingCreationInfo: null,
    hostingInfo: null,
    localDatabaseCreationInfo: null,
    accountsInfo: null,
    error: null,
    finalizationStep: FinalizationSteps.Start,
    progressMessage: '',
    activateAzureAdAuthActionId: null,
    addTrialHostingInfo: null,
    addTrialHostingProgressInfo: null,
    isUpdateTrialHostingInfoScheduled: false,
    wasUpdateTrialHostingStartedImmediatelly: null,
    hostingCreationErrorInfo: null
};

export default class Finalization extends Observable<TFinalizationState> {
    private formContext: TFormContext;
    private languageContext: TLanguageContext;
    public connection: Connection | null = null;

    constructor(formContext: TFormContext, languageContext: TLanguageContext) {
        super(finalizationInitialState);
        this.formContext = formContext;
        this.languageContext = languageContext;
    }

    private readonly dispatchAction = (action: TFinalizationReducerActions) => {
        console.log(action);
        this.setStore(s => finalizationReducer(s, action));
        this.nextStep();
    };

    /**
    * Transforms URLSearchParams to a format recognizable by new trial request
    */
    private static readonly transformAnalyticsParams = async (urlSearchParams: URLSearchParams | null) => {
        let os = platform.os?.toString() ?? '';
        // Windows 11 can be only detected through experimental features https://docs.microsoft.com/en-us/microsoft-edge/web-platform/how-to-detect-win11
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line
        if (navigator.userAgentData) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line
            const highEntropyValues = (await navigator.userAgentData.getHighEntropyValues(['platformVersion', 'platform'])) as THighEntropyValues;

            if (highEntropyValues.platform === 'Windows') {
                const majorPlatformVersion = parseInt(highEntropyValues.platformVersion.split('.')[0]);
                if (majorPlatformVersion >= 13) {
                    os = os.replace('Windows 10', 'Windows 11');
                }
            }
        }

        const utmSource = urlSearchParams?.get(SearchParams.utmSource) ?? ''; // af_388
        const utmMedium = urlSearchParams?.get(SearchParams.utmMedium) ?? ''; // af_389
        const utmCampaign = urlSearchParams?.get(SearchParams.utmCampaign) ?? ''; // af_392
        const fullReferrer = [document.referrer, urlSearchParams?.get(SearchParams.referrer)].filter((r) => !!r).join('; '); // af_390
        const operatingSystem = os; // af_391 (OS + version)

        const transformedParams = `source=${utmSource}&medium=${utmMedium}&campaign=${utmCampaign}&referrer=${encodeURIComponent(fullReferrer)}&os=${operatingSystem}`;
        return transformedParams;
    };

    private readonly getMultiselectStringParamsFromArray = (key: string, values: string[]) => {
        let finalParams = "";
        values.forEach(val => {
            finalParams += `&${key}=${val}`;
        });

        return finalParams;
    };

    private readonly getCallerIdentifier = async () => {
        const { registerAction, companyInfoFormik, companyInfoTwoFormik, urlParams } = this.formContext;
        const isPremiumSignUp = registerAction === RegisterAction.SignUpPremium;
        const form = `WEB_${Strings.getLanguage().toUpperCase()}_${isPremiumSignUp ? 'TRIALFORM' : 'FREEFORM'}`; // For backwards compatibility

        const { companySizeOptions } = getCompanyInfoDropdownOptions(Strings, 'en');
        const { crmExperienceOptions } = getCompanyInfoTwoDropdownOptions(Strings, 'en');

        const companySize = companySizeOptions.find((o) => o.key === companyInfoFormik.values.teamSize?.key)?.text ?? '';
        const crmExperience = crmExperienceOptions.find((o) => o.key === companyInfoTwoFormik.values.crmExperience?.key)?.text ?? '';
        const website = companyInfoTwoFormik.values.website;
        const industry = companyInfoFormik.values.industry?.key ?? '';
        const purposeParams = this.getMultiselectStringParamsFromArray("purpose", companyInfoFormik.values.purpose);
        const role = companyInfoTwoFormik.values.role;
        const outlookType = this.getMultiselectStringParamsFromArray("outlookType", companyInfoTwoFormik.values.outlookType);
        const companyInfo = `companysize=${companySize}&crmexperience=${crmExperience}${purposeParams}&website=${website}&industry=${industry}&role=${role}${outlookType}`;

        const analyticsParams = await Finalization.transformAnalyticsParams(urlParams);

        const callerIdentifier = `form=${form}&${companyInfo}&${analyticsParams}`;
        return callerIdentifier;
    };

    private readonly requestNewTrialHostingCreation = async () => {
        const eway = getEwayObject();
        const { companyInfoFormik, featuresFormik, hostingApi, introFormik, userInfoFormik } = this.formContext;

        const trialRequest: Partial<Parameters<HostingApi["requestNewTrialHostingCreation"]>["0"]["trialRequest"]> = {
            PreferredOutlookClientVersion: eway.getPreferredOutlookClientVersion(),
            CommunicationLanguage: this.languageContext.language,
            ClearDatabaseLanguage: companyInfoFormik.values.language!,
            HostingFeatures: {
                ContactsAndCompanies: featuresFormik.values.contactsAndCompanies,
                Sales: featuresFormik.values.sales,
                Projects: featuresFormik.values.projects,
                MarketingCampaigns: featuresFormik.values.marketingCampaigns,
                QuotesAndBilling: featuresFormik.values.sales,
                TimeSheetsAndPlaning: featuresFormik.values.projects,
            },
            CreateAccounts: false,
            Company: userInfoFormik.values.companyName,
            Country: userInfoFormik.values.phoneDialCode!.key as string,
            Email: introFormik.values.email,
            Name: `${introFormik.values.firstName.trim()} ${introFormik.values.lastName.trim()}`,
            Phone: `${(userInfoFormik.values.phoneDialCode?.data as string) ?? ''}${userInfoFormik.values.phoneNumber}`,
        };

        const requestNewTrialHostingCreationRes = await hostingApi.requestNewTrialHostingCreation({
            trialRequest: trialRequest as Parameters<typeof hostingApi.requestNewTrialHostingCreation>["0"]["trialRequest"]
        });

        if (requestNewTrialHostingCreationRes) {
            if (requestNewTrialHostingCreationRes.IsErrorResponse) {
                const error = `An error has occured while requesting hosting creation. ${requestNewTrialHostingCreationRes.message}`;
                if (requestNewTrialHostingCreationRes.DuplicateEmail) {
                    this.dispatchAction({ type: 'DUPLICATE_EMAIL_REQUEST_ERROR', payload: requestNewTrialHostingCreationRes });
                    await this.sendErrors([error], false);
                    return;
                }

                await this.sendErrors([error], true);
            } else {
                this.dispatchAction({ type: 'CREATE_HOSTING', payload: requestNewTrialHostingCreationRes });
            }
        }
    };

    private readonly waitForAddTrialHostingInfoAction = async (shouldNotRetry?: boolean) => {
        const { hostingApi } = this.formContext;
        const { hostingCreationInfo, addTrialHostingInfo } = this.store;

        if (!addTrialHostingInfo) {
            throw new Error(`Trial hosting info should exist.`);
        }

        await new Promise((res) => setTimeout(res, DateHelper.getRandomTime())); // Wait before request

        const hostingActionStatus = await hostingApi.getHostingActionStatus({ hostingActionGuid: addTrialHostingInfo.newItemGuid }, hostingCreationInfo!.authorizationCode);
        if (!hostingActionStatus) {
            if (shouldNotRetry) {
                const error = `No data returned for AddTrialHostingInfo action ${addTrialHostingInfo.newItemGuid}`;
                await this.sendErrors([error], true);
                return;
            }

            // Try again
            setTimeout(() => {
                void this.waitForAddTrialHostingInfoAction(true);
            }, 1000);
            return;
        }

        this.dispatchAction({ type: "SET_ADD_TRIAL_HOSTING_INFO", payload: hostingActionStatus });
    };

    private readonly addTrialHostingInfo = async () => {
        const eway = getEwayObject();

        const callerIdentifier = await this.getCallerIdentifier();
        const { companyInfoFormik, userInfoFormik, introFormik, featuresFormik, registerAction, hostingApi } = this.formContext;
        const { hostingCreationInfo } = this.store;

        const isPremiumSignUp = registerAction === RegisterAction.SignUpPremium;

        const trialRequest: Parameters<HostingApi["addTrialHostingInfo"]>["0"]["trialRequest"] = {
            HostingGuid: hostingCreationInfo!.newItemGuid,
            PreferredOutlookClientVersion: eway.getPreferredOutlookClientVersion(),
            CommunicationLanguage: this.languageContext.language,
            ClearDatabaseLanguage: companyInfoFormik.values.language!,
            SendEmailAfterCreation: false,
            SendTipsAndTricksCampaign: true,
            Company: userInfoFormik.values.companyName,
            Country: userInfoFormik.values.phoneDialCode!.key as string,
            Email: introFormik.values.email,
            Name: `${introFormik.values.firstName.trim()} ${introFormik.values.lastName.trim()}`,
            Phone: `${(userInfoFormik.values.phoneDialCode?.data as string) ?? ''}${userInfoFormik.values.phoneNumber}`,
            eWayLeadTypeFileAs: isPremiumSignUp ? HostingConfig.ewayLeadType.opportunity : HostingConfig.ewayLeadType.freeVersion,
            CallerIdentifier: callerIdentifier,
            HostingFeatures: {
                ContactsAndCompanies: featuresFormik.values.contactsAndCompanies,
                Sales: featuresFormik.values.sales,
                Projects: featuresFormik.values.projects,
                MarketingCampaigns: featuresFormik.values.marketingCampaigns,
                QuotesAndBilling: featuresFormik.values.sales,
                TimeSheetsAndPlaning: featuresFormik.values.projects,
            },
            NumberOfUsers: isPremiumSignUp ? HostingConfig.premiumLicenseCount : HostingConfig.freeLicenseCount,
            CreateAccounts: false,
            TimeZone: eway.getTimeZone(),
        };

        const addTrialHostingInfo = await hostingApi.addTrialHostingInfo({ trialRequest }, hostingCreationInfo!.authorizationCode);
        if (addTrialHostingInfo) {
            if (!addTrialHostingInfo.newItemGuid) {
                const error = `An error has occured while requesting hosting creation`;
                await this.sendErrors([error], true);
            } else {
                this.dispatchAction({ type: 'ADD_TRIAL_HOSTING_INFO', payload: addTrialHostingInfo });
            }
        }
    };

    private readonly getInfoAboutHosting = async () => {
        const { hostingApi } = this.formContext;
        const { hostingCreationInfo } = this.store;
        const { language } = this.languageContext;

        const res = await hostingApi.getInfoAboutHosting({
            hostingItemGuid: hostingCreationInfo!.newItemGuid,
            communicationLanguage: language,
        });

        if (res) {
            this.dispatchAction({ type: 'SET_HOSTING_INFO', payload: res });
        }
    };

    private readonly sendErrors = async (errors: string[], isBlockingError: boolean) => {
        const { hostingApi } = this.formContext;
        const { hostingCreationInfo } = this.selectDataExternally(x => x);
        await hostingApi.notifyAboutHostingCreationError({ hostingGuid: hostingCreationInfo!.newItemGuid, error: errors.join(`\n\n`), isBlockingError }, hostingCreationInfo!.authorizationCode);

        if (isBlockingError) {
            this.dispatchAction({ type: 'ERROR', error: `Hard errors ocurred during hosting creation process. ${JSON.stringify(errors)}` });
        }
    };

    private readonly createConnectionWithRetry = async (doNotRetry?: boolean): Promise<Connection | null> => {
        const { hostingCreationInfo, hostingInfo } = this.store;

        const sendError = (e: Error) => {
            const errorMessage = StringHelper.prepareErrorLog(
                e,
                `There was an error while creating API connection. \nHostingInfo: ${JSON.stringify(hostingInfo)}. \nHostingCreationInfo: ${JSON.stringify({
                    newItemGuid: hostingCreationInfo?.newItemGuid,
                    message: hostingCreationInfo?.message,
                })}`
            );
            void this.sendErrors([errorMessage], true);
        };

        try {
            return new Connection(
                StringHelper.prepareForEway(hostingInfo!.eWayWebServiceUrl),
                'admin',
                md5(hostingCreationInfo!.adminPassword),
                (e) => {
                    if (doNotRetry) {
                        sendError(e);
                    }

                    throw e; // Go to catch block
                },
                hostingInfo!.eWayClientVersion
            );
        } catch (err) {
            if (doNotRetry) {
                sendError(err as Error);
                return null;
            }

            await new Promise((res) => setTimeout(res, RETRY_TIMEOUT));
            return this.createConnectionWithRetry(true);
        }
    };

    private readonly saveDataViaApiConnection = async () => {
        const { companyInfoFormik, companyInfoTwoFormik, userInfoFormik, introFormik, choosePasswordFormik, isLoggedViaMs, featuresFormik } = this.formContext;
        const { hostingInfo } = this.store;
        const eway = getEwayObject();

        this.connection = await this.createConnectionWithRetry();
        if (!this.connection) {
            return;
        }

        const country = (userInfoFormik.values.phoneDialCode?.key as string) ?? eway.getInitialCountry();

        const transmitObjects = [
            {
                Name: GlobalSettingsNames.defaultLanguage,
                Value: companyInfoFormik.values.language!,
            },
            {
                Name: GlobalSettingsNames.defaultCurrency,
                Value: companyInfoFormik.values.currency,
            },
            {
                Name: GlobalSettingsNames.myCompanyCountry,
                Value: country,
            },
            {
                Name: GlobalSettingsNames.myCompanyName,
                Value: userInfoFormik.values.companyName,
            },
        ];

        try {
            const saveToGlobalSettingsRes = await this.connection.askMethodWithRetry('SaveGlobalSettings', { transmitObjects });

            if (saveToGlobalSettingsRes.ReturnCode !== ReturnCodes.rcSuccess) {
                const error = `There was an error while saving global settings data. ${saveToGlobalSettingsRes.Description}`;
                await this.sendErrors([error], false);
            }
        } catch (e) {
            const error = StringHelper.prepareErrorLog(e as Error, `There was an error while saving global settings data to API.`);
            await this.sendErrors([error], false);
        }

        try {
            // Set default EnumValues for country and currency
            const countryCodeEv = await this.connection.askMethodWithRetry<IApiDataResponse<IApiEnumValue>>("SearchEnumValues", { transmitObject: { EnumTypeName: EnumTypes.countryCode, FileAs: country} });
            const currencyEv = await this.connection.askMethodWithRetry<IApiDataResponse<IApiEnumValue>>("SearchEnumValues", { transmitObject: { EnumTypeName: EnumTypes.currency, En: companyInfoFormik.values.currency } });

            const evToSave = [];
            if (countryCodeEv.Data.length === 1) {
                evToSave.push({ ItemGUID: countryCodeEv.Data[0].ItemGUID, IsDefault: true });
            }

            if (currencyEv.Data.length === 1) {
                evToSave.push({ ItemGUID: currencyEv.Data[0].ItemGUID, IsDefault: true });
            }

            if (evToSave.length) {
                await this.connection.askMethodWithRetry("SaveEnumValues", { transmitObjects: evToSave });
            }
        } catch (e) {
            const error = StringHelper.prepareErrorLog(e as Error, `There was an error while saving default enum values data to API.`);
            await this.sendErrors([error], false);
        }

        try {
            const mainUser = {
                username: introFormik.values.username.toLowerCase(),
                email: introFormik.values.email,
                firstName: introFormik.values.firstName,
                lastName: introFormik.values.lastName,
                group: RoleKeys.Administrator,
                password: choosePasswordFormik.values.password,
                itemGuid: uuidv4(),
            };

            const accountsToSave = [mainUser];
            const { softErrors, blockingErrors } = await this.connection.createUsersAndAssignRelations(
                accountsToSave,
                hostingInfo!.eWayWebServiceUrl,
                companyInfoFormik.values,
                isLoggedViaMs,
                featuresFormik.values,
                companyInfoTwoFormik.values.outlookType
            );

            if (blockingErrors.length !== 0) {
                await this.sendErrors([...blockingErrors, ...softErrors], true);
                return;
            } else if (softErrors.length !== 0) {
                await this.sendErrors(softErrors, false);
            }

            let activateAzureAdAuthActionId: string | null = null;
            if (isLoggedViaMs) {
                activateAzureAdAuthActionId = await this.activateAzureAdAuth();
            }

            this.dispatchAction({ type: 'DATA_SAVED', payload: accountsToSave, activateAzureAdAuthActionId });

        } catch (e) {
            const error = StringHelper.prepareErrorLog(e as Error, `There was an error while saving data to API.`);
            await this.sendErrors([error], true);
        }
    };

    private readonly createLocalDatabase = async () => {
        const eway = getEwayObject();
        const { companyInfoFormik } = this.formContext;
        const { hostingCreationInfo, hostingInfo, accountsInfo } = this.store;

        if (eway.createLocalDatabase && hostingInfo && hostingCreationInfo && accountsInfo) {
            eway.createLocalDatabase(
                0, // Blank database
                hostingInfo.eWayWebServiceUrl,
                accountsInfo[0].username,
                accountsInfo[0].password!,
                true,
                companyInfoFormik.values.language
            );

            this.dispatchAction({ type: 'CREATE_LOCAL_DATABASE' });
        } else {
            const error = `Some data are missing for createLocalDatabase. eWayWebServiceUrl: ${hostingInfo?.eWayWebServiceUrl}, accountsInfo: ${JSON.stringify(accountsInfo?.[0])}`;
            await this.sendErrors([error], true);
        }
    };

    private readonly checkLocalDatabaseCreationProgress = () => {
        const eway = getEwayObject();

        setTimeout(() => {
            const localDatabaseCreationProgress = eway.getLocalDatabaseCreationProgress!();
            if (!localDatabaseCreationProgress) {
                this.checkLocalDatabaseCreationProgress(); // There is no progress yet, try again
            } else {
                this.dispatchAction({ type: 'SET_LOCAL_DATABASE_INFO', payload: localDatabaseCreationProgress });
            }
        }, DateHelper.getRandomTime());
    };

    /**
     * Synchronize users list for hosting so they cannot create new hostings
     */
    private readonly watchHosting = async () => {
        const { hostingApi } = this.formContext;
        const { hostingCreationInfo } = this.store;

        const res = await hostingApi.requestHostingAction(
            {
                hostingActionRequest: {
                    ActionDescriptor: WATCH_ACTION,
                    TargetHostingGuid: hostingCreationInfo!.newItemGuid,
                },
            },
            hostingCreationInfo!.authorizationCode
        );

        if (res && res.IsErrorResponse) {
            const errMessage = `There was an error while requesting Watch() hosting action. ${res.message}`;
            await this.sendErrors([errMessage], false);
            console.error(errMessage);
        }
    };

    private readonly activateAzureAdAuth = async (doNotRetry?: boolean): Promise<string | null> => {
        const { hostingApi } = this.formContext;
        const { hostingCreationInfo } = this.store;

        try {
            const res = await hostingApi.requestHostingAction(
                {
                    hostingActionRequest: {
                        ActionDescriptor: ACTIVATE_AZURE_AD_AUTH_ACTION,
                        TargetHostingGuid: hostingCreationInfo!.newItemGuid,
                    },
                },
                hostingCreationInfo!.authorizationCode
            );

            if (res && !res.IsErrorResponse) {
                return res.newItemGuid;
            }

            if (!doNotRetry) {
                const error = `There was an error while activating Azure AD Auth. ${res?.message}. Trying again...`;
                await this.sendErrors([error], false);
                return this.activateAzureAdAuth(true);
            }

            return null;
        } catch (e) {
            const error = StringHelper.prepareErrorLog(e as Error, `There was an error while activating Azure AD Auth. ${doNotRetry ? '' : 'Trying again...'}`);
            await this.sendErrors([error], !!doNotRetry);

            if (!doNotRetry) {
                return this.activateAzureAdAuth(true);
            }

            return null;
        }
    };

    private readonly checkAzureAdAuthActivation = (body: { hostingActionGuid: string }) => {
        const { hostingApi } = this.formContext;
        const { hostingCreationInfo, hostingInfo } = this.store;

        setTimeout(() => {
            void hostingApi
                .getHostingActionStatus(body, hostingCreationInfo!.authorizationCode)
                .then(async (res) => {
                    if (res && res.IsComplete) {
                        try {
                            await WebAccessPreloaderHelper.wakeUp(hostingInfo!.eWayWebServiceUrl);
                        } catch (e) {
                            console.error('WA waking up failed.', e);
                        } finally {
                            this.dispatchAction({ type: 'AFTER_SAVE_ACTIONS_DONE' });
                        }
                    } else if (res && res.HasErrorOccured) {
                        const error = `An error occured while processing ${ACTIVATE_AZURE_AD_AUTH_ACTION} action. Result value: ${res.ResultValue}`;
                        await this.sendErrors([error], true);
                    } else {
                        this.checkAzureAdAuthActivation(body);
                    }
                })
                .catch(async (e) => {
                    const error = `There was an error while requesting ${ACTIVATE_AZURE_AD_AUTH_ACTION} progress. ${e as string}`;
                    await this.sendErrors([error], true);
                });
        }, DateHelper.getRandomTime());
    };

    public readonly scheduleUpdateTrialInfo = (formContext: TFormContext, languageContext: TLanguageContext) => {
        this.formContext = formContext;
        this.languageContext = languageContext;

        if (this.store.isUpdateTrialHostingInfoScheduled) {
            console.error(`scheduleUpdateTrialInfo shouldn't be called twice`);
            return;
        }

        this.store.isUpdateTrialHostingInfoScheduled = true;
        if (this.store.finalizationStep === FinalizationSteps.WaitingForAllTrialHostingInfoSchedule) {
            this.dispatchAction({ type: "START_ADD_TRIAL_HOSTING_INFO", wasStartedImmediatelly: true });
        }
    };

    public readonly nextStep = () => {
        const { isLoggedViaMs } = this.formContext;
        const isSignIn = RegisterActionHelper.isSignIn(this.formContext.registerAction);
        const { finalizationStep, activateAzureAdAuthActionId, accountsInfo, hostingInfo } = this.store;
        const eway = getEwayObject();

        if (isSignIn) {
            // SignIn steps
            if (finalizationStep === FinalizationSteps.Start) {
                // Creating local database is done right after login, so here we just set correct finalization state
                this.dispatchAction({ type: 'CREATE_LOCAL_DATABASE' });
            } else if (finalizationStep === FinalizationSteps.CreateLocalDatabase) {
                this.checkLocalDatabaseCreationProgress();
            }
        } else {
            // SignUp Steps
            if (finalizationStep === FinalizationSteps.Start) {
                void this.requestNewTrialHostingCreation();
            } else if (finalizationStep === FinalizationSteps.CreateHosting) {
                setTimeout(() => void this.getInfoAboutHosting(), DateHelper.getRandomTime());
            } else if (finalizationStep === FinalizationSteps.WaitingForAllTrialHostingInfoSchedule) {
                if (this.store.isUpdateTrialHostingInfoScheduled) {
                    this.dispatchAction({ type: 'START_ADD_TRIAL_HOSTING_INFO', wasStartedImmediatelly: false });
                }
            } else if (finalizationStep === FinalizationSteps.AllTrialHostingInfoScheduled) {
                void this.addTrialHostingInfo();
            } else if (finalizationStep === FinalizationSteps.AddingTrialHostingInfo) {
                void this.waitForAddTrialHostingInfoAction();
            } else if (finalizationStep === FinalizationSteps.HostingCreatedWithAllInfo) {
                void this.saveDataViaApiConnection();
            } else if (finalizationStep === FinalizationSteps.ApiDataSaved) {
                if (isLoggedViaMs && activateAzureAdAuthActionId) {
                    void this.checkAzureAdAuthActivation({ hostingActionGuid: activateAzureAdAuthActionId });
                } else {
                    // Skip after-save actions
                    this.dispatchAction({ type: 'AFTER_SAVE_ACTIONS_DONE' });
                }
            } else if (finalizationStep === FinalizationSteps.AfterSaveActionsDone) {
                void this.watchHosting();

                if (!eway.isOutlookClient()) {
                    this.dispatchAction({ type: 'FINISH' });
                    return;
                }

                if (isLoggedViaMs && accountsInfo) {
                    eway.tryToAuthorize &&
                        eway.tryToAuthorize(hostingInfo!.eWayWebServiceUrl, accountsInfo[0].email, (isAuthorized) => {
                            if (isAuthorized) {
                                void this.createLocalDatabase();
                            } else {
                                const error = `Unable to authorize. WebServiceUrl: ${hostingInfo!.eWayWebServiceUrl}`;
                                void this.sendErrors([error], true);
                            }
                        });
                } else {
                    void this.createLocalDatabase();
                }
            } else if (finalizationStep === FinalizationSteps.CreateLocalDatabase) {
                this.checkLocalDatabaseCreationProgress();
            }
        }
    };
}