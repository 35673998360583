import { CommandBarButton, IContextualMenuProps } from '@fluentui/react';
import { useContext } from 'react';
import defaultLanguages from '../../../../pages/CompanyInfoPage/data/defaultLanguages';
import { LanguageContext } from '../../../../providers/LanguageProvider';

const WORLD_ICON = {
    iconName: 'World',
};

const LanguageSwitch = () => {
    const { onLanguageSwitch, language } = useContext(LanguageContext);

    const languageMenuProps: IContextualMenuProps = { items: defaultLanguages.filter((l) => l.isSignupLanguage), onItemClick: (e, item) => item && onLanguageSwitch(item.key) };

    return (
        <CommandBarButton className="header__language-switch" iconProps={WORLD_ICON} menuProps={languageMenuProps}>
            {defaultLanguages.find((l) => l.key === language)!.text}
        </CommandBarButton>
    );
};

export default LanguageSwitch;
