export default class UrlHelper {
    static trimHash = (url: string) => url.replace("/#/", "/");

    /**
     * @returns url with hash
     */
    static getFixedUrl = () => {
        const server = window.location.protocol + '//' + window.location.host;
        let path = window.location.pathname;

        if (!path) {
            path = "";
        } else {
            if (path.startsWith('/')) {
                path = path.substring(1);
            }
            if (path.endsWith('/')) {
                path = path.substring(0, path.length - 1);
            }
        }

        return server + (path ? "/" + path : path) + "/#/" + window.location.search;
    };
}