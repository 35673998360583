import React, { useContext } from 'react';
import './FinalizationFailedPage.scss';
import errorImg from '../../img/graphic-design/SomethingWentWrong.svg';
import PageTitle from '../../components/PageTitle/PageTitle';
import Strings from '../../localization/strings';
import HeaderButtons from '../../components/PageBase/Header/HeaderButtons';
import { FinalizationContext } from '../../providers/FinalizationProvider/FinalizationProvider';

const FinalizationFailedPage = () => {
    const myStrings = Strings.finalizationFailed;
    const { finalization } = useContext(FinalizationContext);

    let title = myStrings.title;
    let subtitle = myStrings.subtitle;

    const hostingCreationErrorInfo = finalization?.selectDataExternally(x => x.hostingCreationErrorInfo);
    if (hostingCreationErrorInfo) {
        title = myStrings.titleDuplicateEmailRequest;
        subtitle = myStrings.subtitleDuplicateEmailRequest;
    }

    return (
        <>
            <HeaderButtons />
            <div className="finalization-failed-page">
                <div className="finalization-failed-page__title">
                    <PageTitle title={title} subtitle={subtitle} variant={'large'} />
                </div>
                <img src={errorImg} alt="error" className="finalization-failed-page__img" />
            </div>
        </>
    );
};

export default FinalizationFailedPage;
