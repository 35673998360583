import { Dropdown, IDropdownProps, PrimaryButton, ResponsiveMode } from '@fluentui/react';
import { useContext, useMemo } from 'react';
import PageBase from '../../components/PageBase/PageBase';
import Strings from '../../localization/strings';
import { FormContext } from '../../providers/FormProvider';
import './CompanyInfoPage.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import industryImgDefault from '../../img/graphic-design/povolani_select_all-02-01-01.svg';
import useValidatePreviousFormikOnMount from '../../helpers/hooks/useValidatePreviousFormikOnMount';
import CompanyPageGraphics from './CompanyPageGraphics';
import getCompanyInfoDropdownOptions from './data/getCompanyInfoDropdownOptions';
import FluentV8FieldHelper from '../../helpers/FluentV8FieldHelper';

const CompanyInfoPage = () => {
    const { companyInfoFormik, userInfoFormik } = useContext(FormContext);
    const myStrings = Strings.companyInfoPage;
    const { companyIndustryOptions, companySizeOptions, purposeOptions } = useMemo(() => getCompanyInfoDropdownOptions(Strings), []);

    useValidatePreviousFormikOnMount(userInfoFormik);


    const handlePurposeChange: IDropdownProps['onChange'] = (e, option) => {
        if (option) {

            const newVal = option.selected ? [...companyInfoFormik.values.purpose, option.key as string] : companyInfoFormik.values.purpose.filter(key => key !== option.key);
            void companyInfoFormik.setFieldValue('purpose', newVal);
        }
    };

    return (
        <PageBase imgSrc={industryImgDefault} imgComponent={<CompanyPageGraphics />}>
            <form className="company-info-page" onSubmit={companyInfoFormik.handleSubmit}>
                <PageTitle title={myStrings.title} subtitle={myStrings.subtitle} />
                <div className='company-info-page__dropdowns'>
                    <Dropdown
                        options={companyIndustryOptions}
                        label={myStrings.industryPlaceholder}
                        className="company-info-page__field"
                        {...companyInfoFormik.getFieldProps('industry')}
                        {...FluentV8FieldHelper.getDropdownFormikProps(companyInfoFormik, 'industry')}
                        selectedKey={companyInfoFormik.values.industry?.key}
                        onChange={(e, o) => void companyInfoFormik.setFieldValue('industry', o)}
                        responsiveMode={ResponsiveMode.large}
                    />
                    
                    <Dropdown
                        multiSelect
                        options={purposeOptions}
                        label={myStrings.purpose.title}
                        className="company-info-page__field"
                        {...companyInfoFormik.getFieldProps('purpose')}
                        {...FluentV8FieldHelper.getDropdownFormikProps(companyInfoFormik, 'purpose')}
                        selectedKeys={companyInfoFormik.values.purpose}
                        onChange={handlePurposeChange}
                        responsiveMode={ResponsiveMode.large}
                    />
                    <Dropdown
                        options={companySizeOptions}
                        label={myStrings.teamSizePlaceholder}
                        className="company-info-page__field"
                        {...companyInfoFormik.getFieldProps('teamSize')}
                        {...FluentV8FieldHelper.getDropdownFormikProps(companyInfoFormik, 'teamSize')}
                        selectedKey={companyInfoFormik.values.teamSize?.key}
                        onChange={(e, o) => void companyInfoFormik.setFieldValue('teamSize', o)}
                        responsiveMode={ResponsiveMode.large}
                    />
                </div>
                <PrimaryButton className="page-base__next-btn" type="submit" disabled={!companyInfoFormik.isValid}>
                    {Strings.next}
                </PrimaryButton>
            </form>
        </PageBase>
    );
};

export default CompanyInfoPage;
