import { Text, mergeStyles } from '@fluentui/react';
import React from 'react';
import './PageTitle.scss';

type TPageTitleProps = {
    title: string;
    subtitle?: React.ReactNode;
    variant?: 'large';
};

const PageTitle: React.FC<TPageTitleProps> = ({ title, subtitle, variant }) => {

    const variantClassNameSuff = variant ? `--${variant}` : null;

    return (
        <div className={`page-title`}>
            <Text className={mergeStyles('page-title__title', variantClassNameSuff && `page-title__title${variantClassNameSuff}`)} block>
                {title}
            </Text>
            {subtitle && (
                <Text variant="large" className={mergeStyles(`page-title__subtitle`, variantClassNameSuff && `page-title__subtitle${variantClassNameSuff}`)} block>
                    {subtitle}
                </Text>
            )}
        </div>
    );
};

export default PageTitle;
