import { MetaStrings } from '@eway-crm/gui';
import { Link, Text } from '@fluentui/react';

const NewTabLink: typeof Link = ({ children, ...restProps }) => {
    return (
        <Link target="_blank" rel="noreferrer noopener" {...restProps}>
            {children}
        </Link>
    );
};

const Strings = MetaStrings.create({
    en: {
        next: 'Next',
        back: 'Back',
        hidePassword: 'Hide password',
        showPassword: 'Show password',
        fieldCannotBeEmpty: 'Field cannot be empty.',
        emailAddressIsNotValid: 'Email address is not valid.',
        emailAddressHasInvalidCharacters: 'Email address contains invalid characters.',
        emailIsSameAsYours: 'Enter email of your colleagues. Your account will be created separately.',
        emailAddressAlreadyUsed: 'Email address is already used above.',
        startBtn: 'Start eWay-CRM',
        introPage: {
            alreadyHaveAccount: 'Have an account?',
            logIn: 'Log in',
            title: "Welcome to eWay-CRM.\nLet's create an account for you.",
            subtitle: 'No credit card required.',
            orDivider: 'or',
            email: 'Email',
            firstName: 'First name',
            lastName: 'Last name',
            agreeToTermsAndConditions: () => (
                <Text>
                    I agree to the eWay-CRM <NewTabLink href="https://www.eway-crm.com/eula/">Business Terms</NewTabLink> and{' '}
                    <NewTabLink href="https://www.eway-crm.com/privacy-policy/">Privacy Policy</NewTabLink>
                </Text>
            ),
            signUp: 'Sign Up',
            signInWithMicrosoft: 'Sign up with Microsoft',
            unableToSignInWithMs: 'Unable to sign in to Microsoft account.',
            accountAlreadyExists: 'This account is already registered.',
        },
        emailVerificationPage: {
            title: 'Email verification.',
            accept: 'Accept and resend code',
            cancel: 'Cancel',
            subtitle: 'Check your mailbox {0}and retype the code we sent you.',
            enterPasscodeMessage: 'Please enter verification code.',
            passcodeInvalid: 'Verification code is invalid.',
            unableToGenerateVerificationCode: 'Unable to generate verification code. Please try again later or with different email.',
            resendCode: 'Resend code',
            hostingExistsTitle: 'Account is already created.',
            hostingExistsSubtitle: 'You already have an eWay-CRM account with this email address.',
            login: 'Sign in to eWay-CRM',
            timeBeforeNextSend: 'Resend code ({0})'
        },
        userInfoPage: {
            niceToMeetYou: 'Nice to meet you!',
            niceToMeetYouName: 'Nice to meet you {0}!',
            companyNamePlaceholder: "What is your company's name?",
            phoneNumberPlaceholder: 'What is your phone number?',
        },
        choosePasswordPage: {
            title: 'Choose your password.',
            invalidChars: 'Password contains invalid characters',
            tooWeak: 'Too Weak',
            weak: 'Weak',
            good: 'Good',
            strong: 'Strong',
        },
        companyInfoPage: {
            title: 'Let us personalize your experience',
            titlePage2: 'Thank you. Last few questions, please.',
            subtitle: '',
            subtitlePage2: '',
            industryPlaceholder: 'What industry are you from?',
            teamSizePlaceholder: 'How many seats do you need?',
            to: 'to',
            orMore: 'or more',
            websitePlaceholder: 'What is your website?',
            purpose: {
                title: 'What do you want to track?',
                contactsAndCompanies: 'Contacts and Companies',
                trackingActivities: 'Activities and Follow-Ups',
                managingSales: 'Sales',
                managingJobs: 'Jobs and Projects',
                emailMarketing: 'Email Marketing'
            },
            crmExperiencePlaceholder: 'What is your experience with CRM?',
            noCrmExperience: "I haven't used a CRM before",
            excelCrmExperience: 'We use spreadsheets or another non-professional CRM',
            professionalCrmExperience: 'We use a professional CRM, but are considering switching',
            pastProfessionalCrmExperience: 'I have used a professional CRM in the past',
            rolePlaceholder: 'What is your role in your organization?',
            outlookType: {
                title: 'What Outlook do you use?',
                desktopClassic: "Outlook Classic for Windows",
                desktopNew: "New Outlook for Windows",
                mac: "Outlook for Mac",
                online: "Outlook Online",
                noneYet: "None Yet"
            }
        },
        downloadOutlookAddinPage: {
            title: 'Download and install eWay-CRM Desktop.',
            subtitle: "Best choice for those who want all features.",
            step: 'Step',
            downloadForWindows: 'Download for Windows',
            downloadDoesntStartAutomaticallyText: "Click here if the download doesn't start automatically.",
            installEway: 'Install',
            loginAndEnjoy: 'Log in & Enjoy!'
        },
        finalization: {
            title: 'Creating your account…',
            subtitle: 'Give us a moment to set everything up.',
            welcomeScreenCustomizingSettings: 'Customizing settings',
            finalizationFinish: "And we are done. Let's begin.",
            sendingRequestForNewHosting: 'Sending request for new hosting',
            welcomeScreenCreatingAccount: 'Creating account',
            licenceTermsAgreementLink: 'https://www.eway-crm.com/eula/',
            loading: 'Loading…',
            pleaseWait: 'Please Wait',
            finalizationHostingDaemonStage0: 'This will take a minute. In the meantime, did you know…',
            finalizationHostingDaemonStage1: 'that eWay-CRM sits right within your Outlook?',
            finalizationHostingDaemonStage2: 'It allows you to easily save emails and synchronize contacts…',
            finalizationHostingDaemonStage4: 'and even organize meetings by deals or projects!',
            finalizationHostingDaemonStage6: 'Also, you can connect eWay-CRM with QuickBooks or PowerBI.',
            finalizationHostingDaemonStage14: 'This way, you can synchronize your invoices and make beautiful reports.',
            finalizationHostingDaemonStage24: () => (
                <>
                    Have a look at the <NewTabLink href="https://www.eway-crm.com/integrations/">complete list of products</NewTabLink> we are integrated with.
                </>
            ),
            finalizationHostingDaemonOnlyUpdate: 'Just a couple of seconds.',
            finalizationHostingDaemonFinishWeb: 'Finalizing…',
            finalizationHostingDaemonFinish: 'eWay-CRM has plenty of settings so you can customize it as you wish. For instance…',
            finalizationCreatingLocalDatabase: 'You can create custom fields, change the look of your forms, or make your own workflows.',
            finalizationWaitingUntilInitialSynchronizationFinishes: 'Preparing your sample database.',
            finalizationWaitingUntilInitialSynchronizationFinishesSignIn: 'Preparing your database.',
            finalizationWaitingUntilInitialSynchronizationFinishesOutlookImport: 'Before we start importing your contact, we need to prepare your local database.\n',
        },
        finishConfigurationPage: {
            title: 'Account Created.',
            titleSignIn: 'Login successful.',
            subtitle: "Let's finish your configuration.",
            preferredLanguage: 'What is your preferred language?',
            automaticallySaveEmailsByContacts: 'Automatically save emails by contacts and companies.',
            sendAnonymousDiagnostics: 'Send anonymous diagnostics to eWay-Team',
        },
        finalizationFailed: {
            title: 'Something Went Wrong.',
            subtitle: 'We collected all logs and will get back to you within 24 hours. Thank you for your patience.',
            titleDuplicateEmailRequest: 'Duplicate Account Creation',
            subtitleDuplicateEmailRequest: 'You might already be creating eWay-CRM account in a separate browser tab. If you have closed it, please wait for a while and try again.'
        },
        importDataPage: {
            title: 'How do you want to start?',
            subtitle: 'Build your database in a few steps.',
            blankDatabase: 'Create an empty database',
            sampleDatabase: 'Try a sample dataset',
            importFromDeletedWs: 'Use data from your old account',
            importFromDeletedWsTooltip: 'Data will be imported from your old account ({0})',
            importFromO365: 'Import data from Microsoft 365',
            importFromOutlook: 'Import data from Outlook',
            importFromExcel: 'Import data from Excel',
            importDataFromOtherCRM: 'Import data from your old CRM',
            importDataEmailTitle: 'Help me move my data to eWay-CRM',
            importDataEmailBody: `Hello,\n\nI want to switch to eWay-CRM and need help with migrating my data.\n\nRegards\n\n`,
        },
        importFromOutlookPage: {
            title: 'Import contacts from Outlook.',
            subtitle: "Let's set it up.",
            importType: 'What are you looking for?',
            subtitleFree: 'We will move data from your default Outlook contact folder.',
            oneWayImport: 'One-time import (from your Outlook default contact folder)',
            twoWayImport: 'Two-way synchronization (both eWay-CRM and Outlook contact databases will be updated automatically)',
            automaticallyCreateCompanies: 'Automatically create companies in eWay-CRM',
            importedContactsCategory: 'Category name for imported contacts',
            startImportBtn: 'Import',
            skipImportBtn: 'Skip for now'
        },
        importProgressPage: {
            title: 'Importing data.',
            subtitle: 'Please give us a minute to import your data to eWay-CRM.',
            subtitleSampleData: 'Please give us a minute to import data to eWay-CRM.',
        },
        importSuccessPage: {
            title: 'Import successfully completed.',
            subtitle: 'You are ready to go.',
        },
        loginSuccessPage: {
            title: 'Success',
            subtitle: 'You have successfully logged in to eWay-CRM.'
        },
        loginPage: {
            title: 'Welcome to eWay-CRM.',
            subtitle: "Let's start by logging in.",
            login: 'Login',
            dontHaveAccountYet: 'Need an account?',
            signup: 'Sign Up.'
        },
        wsDeletedPage: {
            titleCloud: 'Your cloud account is no longer available.',
            subtitleCloud: 'Address {0} doesn\'t exist anymore.',
            titleOnPremise: 'Your web service is unavailable.',
            subtitleOnPremise: 'Make sure your server is running or contact your administrator.',
            changeAccountBtn: 'Change Account',
            createNewAccountBtn: 'Create New Account',
            workOfflineBtn: 'Work Offline',
            workOfflineDialog: {
                title: 'WARNING! Your data might be in danger.',
                text: 'If you work offline, your data is not automatically synchronized with the server. If your computer breaks down, you might lose it.',
                ok: 'I Understand the Risk',
                cancel: 'Cancel'
            }
        }
    },
    cs: {
        next: 'Pokračovat',
        back: 'Zpět',
        hidePassword: 'Skrýt heslo',
        showPassword: 'Zobrazit heslo',
        fieldCannotBeEmpty: 'Pole musí být vyplněno.',
        emailAddressIsNotValid: 'E-mailová adresa není platná.',
        emailAddressHasInvalidCharacters: 'E-mailová adresa obsahuje neplatné znaky.',
        emailIsSameAsYours: 'Zadejte emaily vašich kolegů. Váš účet bude vytvořen odděleně.',
        emailAddressAlreadyUsed: 'E-mailová adresa je už použita výše.',
        startBtn: 'Začít s eWay-CRM',
        introPage: {
            alreadyHaveAccount: 'Máte už účet?',
            logIn: 'Přihlašte se',
            title: 'Vítejte v eWay-CRM.\nNový účet.',
            subtitle: '',
            orDivider: 'nebo',
            email: 'Email',
            firstName: 'Jméno',
            lastName: 'Příjmení',
            agreeToTermsAndConditions: () => (
                <Text>
                    Souhlasím s <NewTabLink href="https://www.eway-crm.com/cs/eula/">licenčními podmínkami</NewTabLink> a{' '}
                    <NewTabLink href="https://www.eway-crm.com/cs/privacy-policy/">zásadami ochrany osobních údajů</NewTabLink>
                </Text>
            ),
            signUp: 'Vytvořit nový účet',
            signInWithMicrosoft: 'Zaregistrovat se účtem Microsoft',
            unableToSignInWithMs: 'K účtu Microsoft se nepodařilo přihlásit.',
            accountAlreadyExists: 'Tento účet je již zaregistrovaný.',
        },
        emailVerificationPage: {
            title: 'Ověření emailu.',
            accept: 'Potvrdit a znovu odeslat kód',
            cancel: 'Zrušit',
            subtitle: 'Zkontrolujte svou emailovou schránku {0}a zadejte kód, který jsme vám zaslali.',
            enterPasscodeMessage: 'Prosím zadejte ověřovací kód.',
            passcodeInvalid: 'Ověřovací kód je neplatný.',
            unableToGenerateVerificationCode: 'Nepodařilo se vygenerovat ověřovací kód. Prosím zkuste to znovu později nebo s jiným emailem.',
            resendCode: 'Znovu odeslat kód',
            hostingExistsTitle: 'Účet je již vytvořený.',
            hostingExistsSubtitle: 'eWay-CRM účet s touto emailovou adresou je již vytvořený.',
            login: 'Přihlásit se do eWay-CRM',
            timeBeforeNextSend: 'Znovu odeslat kód ({0})'
        },
        userInfoPage: {
            niceToMeetYou: 'Rádi Vás poznáváme.',
            niceToMeetYouName: 'Rádi Vás poznáváme, {0}.',
            companyNamePlaceholder: 'Jméno vaší společnosti.',
            phoneNumberPlaceholder: 'Vaše telefonní číslo.',
        },
        choosePasswordPage: {
            title: 'Zvolte si své heslo.',
            invalidChars: 'Heslo obsahuje neplatné znaky',
            tooWeak: 'Příliš slabé',
            weak: 'Slabé',
            good: 'Dobré',
            strong: 'Silné',
        },
        companyInfoPage: {
            title: 'Pojďme vám eWay-CRM nastavit na míru',
            titlePage2: 'Posledních pár otázek.',
            subtitle: '',
            subtitlePage2: '',
            industryPlaceholder: 'Z jakého jste oboru?',
            teamSizePlaceholder: 'Pro kolik uživatelů budete potřebovat licence?',
            to: 'až',
            orMore: 'nebo více',
            websitePlaceholder: 'Jaké jsou vaše webové stránky?',
            purpose: {
                title: 'Proč hledáte CRM?',
                contactsAndCompanies: 'Evidence zákazníků',
                trackingActivities: 'Sledování komunikace a dalších kroků',
                managingSales: 'Řízení obchodu',
                managingJobs: 'Řízení zakázek',
                emailMarketing: 'E-mailové kampaně'
            },
            crmExperiencePlaceholder: 'Jaké máte zkušenosti s CRM?',
            noCrmExperience: 'Dosud jsem žádné CRM nepoužíval',
            excelCrmExperience: 'Používáme Excel nebo šanony',
            professionalCrmExperience: 'CRM už máme, ale uvažujeme o změně',
            pastProfessionalCrmExperience: 'V minulosti jsem používal CRM',
            rolePlaceholder: 'Jaká je vaše pracovní pozice?',
            outlookType: {
                title: 'Jaký Outlook používáte?',
                desktopClassic: "Klasický Outlook pro Windows",
                desktopNew: "Nový Outlook pro Windows",
                mac: "Outlook pro Mac",
                online: "Outlook Online",
                noneYet: "Zatím žádný"
            }
        },
        downloadOutlookAddinPage: {
            title: 'Nainstalujte si eWay-CRM pro Outlook na Windows.',
            subtitle: 'Nejlepší volba pro ty, kteří chtějí všechny funkce.',
            step: 'Krok',
            downloadForWindows: 'Stáhněte',
            downloadDoesntStartAutomaticallyText: 'Pokud stahování nezačne automaticky, klikněte sem.',
            installEway: 'Nainstalujte',
            loginAndEnjoy: 'Přihlašte se'
        },
        finalization: {
            title: 'Vytváříme účet...',
            subtitle: 'Za chvilku to bude hotovo.',
            welcomeScreenCustomizingSettings: 'Úprava nastavení',
            finalizationFinish: 'Hotovo. Můžeme začít.',
            sendingRequestForNewHosting: 'Odesílání dotazu pro vytvoření nového hostingu',
            welcomeScreenCreatingAccount: 'Vytváření účtu',
            licenceTermsAgreementLink: 'https://www.eway-crm.com/cs/eula/',
            loading: 'Načítání...',
            pleaseWait: 'Počkejte prosím',
            finalizationHostingDaemonStage0: 'Zabere to minutku. Věděli jste, že…',
            finalizationHostingDaemonStage1: 'eWay-CRM je přímo ve vašem Outlooku?',
            finalizationHostingDaemonStage2: 'Umožňuje vám snadno ukládat e-maily a synchronizovat kontakty…',
            finalizationHostingDaemonStage4: 'a organizovat schůzky podle příležitostí nebo projektů.',
            finalizationHostingDaemonStage6: 'Taky se umíme napojit na Pohodu, PowerBI a mnoho dalších nástrojů.',
            finalizationHostingDaemonStage14: 'Díky tomu můžete synchronizovat do eWay-CRM faktury, objednávky nebo si vytvářet krásné reporty.',
            finalizationHostingDaemonStage24: () => (
                <>
                    Podívejte se na <NewTabLink href="https://www.eway-crm.com/cs/snadne-propojeni-integrace-systemu-s-eway-crm/">seznam všech produktů, se kterými je eWay-CRM integrovaná</NewTabLink>
                    .
                </>
            ),
            finalizationHostingDaemonOnlyUpdate: 'Už jen sekundu.',
            finalizationHostingDaemonFinishWeb: 'Dokončování…',
            finalizationHostingDaemonFinish: 'eWay-CRM si můžete přizpůsobit podle Vašich potřeb.',
            finalizationCreatingLocalDatabase: 'Můžete si vytvořit uživatelská políčka, změnit vzhled formulářů nebo vytvořit vlastní workflow.',
            finalizationWaitingUntilInitialSynchronizationFinishes: 'Připravujeme vaši vzorovou databázi.',
            finalizationWaitingUntilInitialSynchronizationFinishesSignIn: 'Připravujeme vaši databázi.',
            finalizationWaitingUntilInitialSynchronizationFinishesOutlookImport: 'Než zahájíme import kontaktů, musíme připravit lokální databázi.\n',
        },
        finishConfigurationPage: {
            title: 'Účet vytvořen.',
            titleSignIn: 'Přihlášení bylo úspěšné.',
            subtitle: 'Pojďme dokončit konfiguraci.',
            preferredLanguage: 'Jaký je váš preferovaný jazyk?',
            automaticallySaveEmailsByContacts: 'Automaticky ukládat e-maily do eWay-CRM',
            sendAnonymousDiagnostics: 'Posílat anonymní diagnostiku',
        },
        finalizationFailed: {
            title: 'Došlo k chybě.',
            subtitle: 'Shromáždili jsme všechny chybové informace a do 24 hodin se vám ozveme zpět. Děkujeme za trpělivost.',
            titleDuplicateEmailRequest: 'Duplicitní zakládání účtu',
            subtitleDuplicateEmailRequest: 'Pravděpodobně již vytváříte účet eWay-CRM v jiné záložce prohlížeče. Pokud jste ji zavřeli, chvíli počkejte a zkuste to znovu.'
        },
        importDataPage: {
            title: 'Jak chcete začít?',
            subtitle: 'Vytvořte si svoji databázi.',
            blankDatabase: 'Založit prázdnou databázi',
            sampleDatabase: 'Vyzkoušet ukázková data',
            importFromDeletedWs: 'Použít data ze starého účtu',
            importFromDeletedWsTooltip: 'Po zvolení této možnosti budou naimportována data z vašeho starého účtu ({0})',
            importFromO365: 'Naimportovat data z Microsoft 365',
            importFromOutlook: 'Naimportovat data z Outlooku',
            importFromExcel: 'Naimportovat data z Excelu',
            importDataFromOtherCRM: 'Importujte data ze svého současného CRM',
            importDataEmailTitle: 'Přesun dat do eWay-CRM',
            importDataEmailBody: `Dobrý den,\n\nChtěl bych přejít na eWay-CRM a potřebuji pomoc s migrací mých dat.\n\nS pozdravem\n\n`,
        },
        importFromOutlookPage: {
            title: 'Importujte kontakty z Outlooku.',
            subtitle: 'Pojďme to nastavit.',
            subtitleFree: 'Převedeme data z vaší výchozí složky kontaktů.',
            importType: 'Jaký typ importu chcete provést?',
            oneWayImport: 'Jednorázový import (z výchozí složky kontaktů Outlooku)',
            twoWayImport: 'Obousměrná synchronizace (databáze Outlooku i eWay-CRM budou pravidelně aktualizovány)',
            automaticallyCreateCompanies: 'Automaticky v eWay-CRM vytvářet společnosti',
            importedContactsCategory: 'Název kategorie pro importované kontakty',
            startImportBtn: 'Importovat',
            skipImportBtn: 'Přeskočit'
        },
        importProgressPage: {
            title: 'Importujeme data.',
            subtitle: 'Za chvíli budete mít vaše data v eWay-CRM.',
            subtitleSampleData: 'Za chvíli budete mít data v eWay-CRM.',
        },
        importSuccessPage: {
            title: 'Import byl úspěšně dokončen.',
            subtitle: 'Můžeme začít.',
        },
        loginSuccessPage: {
            title: 'Hotovo',
            subtitle: 'Úspěšně jste se přihlásili do eWay-CRM.'
        },
        loginPage: {
            title: 'Vítejte v eWay-CRM.',
            subtitle: 'Začněme přihlášením.',
            login: 'Přihlásit se',
            dontHaveAccountYet: 'Ještě nemáte účet?',
            signup: 'Registrovat se.'
        },
        wsDeletedPage: {
            titleCloud: 'Váš cloudový účet již není k dispozici.',
            subtitleCloud: 'Adresa {0} již neexistuje.',
            titleOnPremise: 'Vaše webová služba je nedostupná.',
            subtitleOnPremise: 'Ujistěte se, že váš server běží, nebo kontaktujte svého správce.',
            changeAccountBtn: 'Změnit účet',
            createNewAccountBtn: 'Vytvořit nový účet',
            workOfflineBtn: 'Pracovat offline',
            workOfflineDialog: {
                title: 'POZOR! Vaše data mohou být v nebezpečí.',
                text: 'Pokud pracujete offline, vaše data zůstávají pouze na vašem počítači a nejsou automaticky synchronizovaná se serverem. Pokud se s vašim počítačem něco stane, o data můžete nenávratně přijít.',
                ok: 'Rozumím riziku',
                cancel: 'Zrušit'
            }
        }
    },
    sk: {
        next: 'Pokračovať',
        back: 'Späť',
        hidePassword: 'Skryť heslo',
        showPassword: 'Zobraziť heslo',
        fieldCannotBeEmpty: 'Pole musí byť vyplnené.',
        emailAddressIsNotValid: 'E-mailová adresa nie je platná.',
        emailAddressHasInvalidCharacters: 'E-mailová adresa obsahuje neplatné znaky.',
        emailIsSameAsYours: 'Zadajte emaily vašich kolegov. Váš účet bude vytvorený oddelene.',
        emailAddressAlreadyUsed: 'Emailová adresa je už použitá vyššie.',
        startBtn: 'Začať s eWay-CRM',
        introPage: {
            alreadyHaveAccount: 'Máte už účet?',
            logIn: 'Prihláste sa',
            title: 'Vitajte v eWay-CRM.\nNový účet.',
            subtitle: '',
            orDivider: 'alebo',
            email: 'Email',
            firstName: 'Meno',
            lastName: 'Priezvisko',
            agreeToTermsAndConditions: () => (
                <Text>
                    Súhlasím s <NewTabLink href="https://www.eway-crm.com/cs/eula/">licenčními podmínkami</NewTabLink> a{' '}
                    <NewTabLink href="https://www.eway-crm.com/cs/privacy-policy/">zásadami ochrany osobných údajov</NewTabLink>
                </Text>
            ),
            signUp: 'Vytvoriť nový účet',
            signInWithMicrosoft: 'Zaregistrovať sa účtom Microsoft',
            unableToSignInWithMs: 'K účtu Microsoft sa nepodarilo prihlásiť.',
            accountAlreadyExists: 'Tento účet je už zaregistrovaný.',
        },
        emailVerificationPage: {
            title: ' Overenie emailu.',
            accept: 'Potvrdiť a znovu odoslať kód',
            cancel: 'Zrušiť',
            subtitle: 'Skontrolujte svoju emailovú schránku {0}a zadajte kód, ktorý sme vám zaslali.',
            enterPasscodeMessage: 'Prosím zadajte overovací kód.',
            passcodeInvalid: 'Overovací kód je neplatný.',
            resendCode: 'Znovu odoslať kód',
            unableToGenerateVerificationCode: 'Nepodarilo sa vygenerovať overovací kód. Prosím skúste to znovu neskôr alebo použite iný email.',
            hostingExistsTitle: 'Účet je už vytvorený.',
            hostingExistsSubtitle: 'eWay-CRM účet s touto emailovou adresou je už vytvorený.',
            login: 'Prihlásiť sa do eWay-CRM',
            timeBeforeNextSend: 'Znovu odoslať kód ({0})'
        },
        userInfoPage: {
            niceToMeetYou: 'Radi vás spoznávame.',
            niceToMeetYouName: 'Radi vás spoznávame, {0}.',
            companyNamePlaceholder: 'Názov vašej spoločnosti.',
            phoneNumberPlaceholder: 'Vaše telefónne číslo.',
        },
        choosePasswordPage: {
            title: 'Zvoľte si svoje heslo.',
            invalidChars: 'Heslo obsahuje neplatné znaky',
            tooWeak: 'Príliš slabé',
            weak: 'Slabé',
            good: 'Dobré',
            strong: 'Silné',
        },
        companyInfoPage: {
            title: 'Poďme vám eWay-CRM nastaviť na mieru',
            titlePage2: 'Posledných pár otázok',
            subtitle: '',
            subtitlePage2: '',
            industryPlaceholder: 'Z akého odvetvia ste?',
            teamSizePlaceholder: 'Pre koľko používateľov budete potrebovať licencie?',
            to: 'až',
            orMore: 'alebo viac',
            websitePlaceholder: 'Aké sú vaše webové stránky?',
            purpose: {
                title: 'Prečo hľadáte CRM?',
                contactsAndCompanies: 'Evidencia zákazníkov',
                trackingActivities: 'Sledovanie komunikácie a ďalších krokov',
                managingSales: 'Riadenie obchodu',
                managingJobs: 'Riadenie zákaziek',
                emailMarketing: 'E-mailové kampane'
            },
            crmExperiencePlaceholder: 'Aké skúsenosti máte s CRM?',
            noCrmExperience: 'Doteraz som žiadne CRM nepoužíval',
            excelCrmExperience: 'Používame Excel alebo šanóny',
            professionalCrmExperience: 'CRM už máme, ale uvažujeme o zmene',
            pastProfessionalCrmExperience: 'V minulosti som používal CRM',
            rolePlaceholder: 'Aká je vaša pracovná pozícia?',
            outlookType: {
                title: 'Aký Outlook používate?',
                desktopClassic: "Klasický Outlook pre Windows",
                desktopNew: "Nový Outlook pre Windows",
                mac: "Outlook pre Mac",
                online: "Outlook Online",
                noneYet: "Zatiaľ žiadny"
            }
        },
        downloadOutlookAddinPage: {
            title: 'Nainštalujte si eWay-CRM pre Outlook na Windows.',
            subtitle: 'Najlepšia voľba pre tých, ktorí chcú všetky funkcie.',
            step: 'Krok',
            downloadForWindows: 'Stiahnite',
            downloadDoesntStartAutomaticallyText: 'Pokiaľ sťahovanie nezačne automaticky, kliknite sem.',
            installEway: 'Nainštalujte',
            loginAndEnjoy: 'Prihláste sa'
        },
        finalization: {
            title: 'Vytvárame účet...',
            subtitle: 'Za chvíľku to bude hotovo.',
            welcomeScreenCustomizingSettings: 'Úprava nastavení',
            finalizationFinish: 'Hotovo. Môžeme začať.',
            sendingRequestForNewHosting: 'Odosielanie dotazu pre vytvorenie nového hostingu',
            welcomeScreenCreatingAccount: 'Vytváranie účtu',
            licenceTermsAgreementLink: 'https://www.eway-crm.com/cs/eula/',
            loading: 'Načítavanie...',
            pleaseWait: 'Počkajte prosím',
            finalizationHostingDaemonStage0: 'Zaberie to jednu až dve minúty. Vedeli ste, že...',
            finalizationHostingDaemonStage1: 'eWay-CRM je priamo vo vašom Outlooku?',
            finalizationHostingDaemonStage2: 'Umožňuje vám ľahko ukladať e-maily a synchronizovať kontakty…',
            finalizationHostingDaemonStage4: 'a organizovať stretnutia podľa príležitostí alebo projektov.',
            finalizationHostingDaemonStage6: 'Taktiež sa vieme napojiť na Pohodu, PowerBI a mnoho ďaľších nástrojov.',
            finalizationHostingDaemonStage14: 'Vďaka tomu môžete synchronizovať do eWay-CRM faktúry, objednávky alebo si vytvárať krásne reporty.',
            finalizationHostingDaemonStage24: () => (
                <>
                    Pozrite sa na <NewTabLink href="https://www.eway-crm.com/cs/snadne-propojeni-integrace-systemu-s-eway-crm/">zoznam produktov, s ktorými je eWay-CRM integrovaná</NewTabLink>
                    .
                </>
            ),
            finalizationHostingDaemonOnlyUpdate: 'Už len sekundu.',
            finalizationHostingDaemonFinishWeb: 'Dokončovanie…',
            finalizationHostingDaemonFinish: 'eWay-CRM si môžete prispôsobiť podľa Vašich potrieb.',
            finalizationCreatingLocalDatabase: 'Môžete si vytvoriť používateľské políčka, zmeniť vzhľad formulárov alebo vytvoriť vlastné workflow.',
            finalizationWaitingUntilInitialSynchronizationFinishes: 'Pripravujeme vašu vzorovú databázu.',
            finalizationWaitingUntilInitialSynchronizationFinishesSignIn: 'Pripravujeme vašu databázu.',
            finalizationWaitingUntilInitialSynchronizationFinishesOutlookImport: 'Než začneme import kontaktov, musíme pripraviť lokálnu databázu.\n',
        },
        finishConfigurationPage: {
            title: 'Účet vytvorený.',
            titleSignIn: 'Prihlásenie bolo úspešné.',
            subtitle: 'Poďme dokončiť konfiguráciu.',
            preferredLanguage: 'Aký je váš preferovaný jazyk?',
            automaticallySaveEmailsByContacts: 'Automaticky ukladať e-maily do eWay-CRM',
            sendAnonymousDiagnostics: 'Posielať anonymnú diagnostiku',
        },
        finalizationFailed: {
            title: 'Došlo k chybe.',
            subtitle: 'Zhromaždili sme všetky chybové informácie a do 24 hodín sa vám ozveme späť. Ďakujeme za trpezlivosť.',
            titleDuplicateEmailRequest: 'Duplicitné zakladanie účtu',
            subtitleDuplicateEmailRequest: 'Pravdepodobne už vytvárate účet eWay-CRM v inej záložke prehliadača. Ak ste ju zavreli, chvíľu počkajte a skúste to znova.'
        },
        importDataPage: {
            title: 'Ako chcete začať?',
            subtitle: 'Vytvorte si svoju databázu.',
            blankDatabase: 'Založiť prázdnu databázu',
            importFromDeletedWs: 'Použiť dáta zo starého účtu',
            importFromDeletedWsTooltip: 'Po zvolení tejto možnosti budú naimportované údaje z vášho starého účtu ({0})',
            sampleDatabase: 'Vyskúšať skušobné údaje',
            importFromO365: 'Naimportovať údaje z Microsoft 365',
            importFromOutlook: 'Naimportovať údaje z Outlooku',
            importFromExcel: 'Naimportovať údaje z Excelu',
            importDataFromOtherCRM: 'Importujte údaje zo svojho súčasného CRM',
            importDataEmailTitle: 'Presun údajov do eWay-CRM',
            importDataEmailBody: `Dobrý deň,\n\nChcel by som prejsť na eWay-CRM a potrebujem pomôcť s migráciou mojich údajov.\n\nS pozdravom\n\n`,
        },
        importFromOutlookPage: {
            title: 'Importujte kontakty z Outlooku.',
            subtitle: 'Poďme to nastaviť.',
            subtitleFree: 'Prevedieme dáta z vašej východiskovej zložky kontaktov.',
            importType: 'Aký typ importu chcete uskutočniť?',
            oneWayImport: 'Jednorázový import (z východzej zložky kontaktov Outlooku)',
            twoWayImport: 'Obojsmerná synchronizácia (databáza Outlooku a eWay-CRM budú pravidelne aktualizované)',
            automaticallyCreateCompanies: 'Automaticky v eWay-CRM vytvárať spoločnosti',
            importedContactsCategory: 'Názov kategórie pre importované kontakty',
            startImportBtn: 'Importovat',
            skipImportBtn: 'Preskočiť'
        },
        importProgressPage: {
            title: 'Importujeme dáta.',
            subtitle: 'Za chvíľu budete mať vaše dáta v eWay-CRM.',
            subtitleSampleData: 'Za chvíľu budete mať dáta v eWay-CRM.',
        },
        importSuccessPage: {
            title: 'Import bol úspešne dokončený.',
            subtitle: 'Môžeme začať.',
        },
        loginSuccessPage: {
            title: 'Hotovo',
            subtitle: 'Úspešne ste sa prihlásili do eWay-CRM.'
        },
        loginPage: {
            title: 'Vítejte v eWay-CRM.',
            subtitle: 'Začnime prihlásením.',
            login: 'Prihlásiť sa',
            dontHaveAccountYet: 'Ešte nemáte účet?',
            signup: 'Registrovať sa.'
        },
        wsDeletedPage: {
            titleCloud: 'Váš cloudový účet už nie je k dispozíci.',
            subtitleCloud: 'Adresa {0} už neexistuje.',
            titleOnPremise: 'Vaša webová služba je nedostupná.',
            subtitleOnPremise: 'Uistite sa, že váš server beží, alebo kontaktujte svojho správcu.',
            changeAccountBtn: 'Zmeniť účet',
            createNewAccountBtn: 'Vytvoriť nový účet',
            workOfflineBtn: 'Pracovať offline',
            workOfflineDialog: {
                title: 'POZOR! Vaše dáta môžu byť v nebezpečenstve.',
                text: 'Ak pracujete offline, vaše dáta zostávajú iba na vašom počítači a nie sú automaticky synchronizované so serverom. Pokiaľ sa s vašim počítačom niečo stane, o dáta môžete nenávratne prísť.',
                ok: 'Rozumiem riziku',
                cancel: 'Zrušiť'
            }
        }
    },
});

export default Strings;
