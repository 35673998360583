import { Link, Text, useTheme } from '@fluentui/react';
import HeaderButtons from '../../components/PageBase/Header/HeaderButtons';
import PageTitle from '../../components/PageTitle/PageTitle';
import Strings from '../../localization/strings';
import './ImportDataPage.scss';
import useImportDataTiles from './useImportDataTiles';

const ImportDataPage = () => {
    const myStrings = Strings.importDataPage;
    const theme = useTheme();
    const importDataTiles = useImportDataTiles();

    const getEmailLink = () => {
        return `mailto:sales@eway-crm.com?subject=${encodeURIComponent(myStrings.importDataEmailTitle)}&body=${encodeURIComponent(myStrings.importDataEmailBody)}`;
    };

    return (
        <>
            <HeaderButtons />
            <div className="import-data-page">
                <PageTitle title={myStrings.title} subtitle={myStrings.subtitle} variant={'large'} />
                <div className="import-data-page__tiles">
                    {importDataTiles.map((tile, idx) => (
                        <div className="import-data-page__tile ignore-ew-window-move" onClick={tile.onClick} style={{ boxShadow: theme.effects.elevation4 }} key={idx}>
                            <div className="import-data-page__tile__image-wrap ignore-ew-window-move" style={{ backgroundColor: tile.bgColor }}>
                                <img src={tile.icon} className="import-data-page__tile__image" alt="Import icon" />
                            </div>
                            <Text className="import-data-page__tile__text">{tile.title}</Text>
                        </div>
                    ))}
                </div>
                <Link className="import-data-page__import-from-other-crm" href={getEmailLink()}>{myStrings.importDataFromOtherCRM}</Link>
            </div>
        </>
    );
};

export default ImportDataPage;
