import { ProgressIndicator } from '@fluentui/react';
import { useContext, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import PageBase from '../../components/PageBase/PageBase';
import PageTitle from '../../components/PageTitle/PageTitle';
import RouteConfig from '../../config/RouteConfig';
import Strings from '../../localization/strings';
import { FormContext, ImportDatabaseType } from '../../providers/FormProvider';
import { TGetLocalDatabaseCreationProgressResponse } from '../../types/ewayBrowserBoundObject';
import { OutlookImportType } from '../ImportFromOutlookPage/ImportFromOutlookPage';
import useImportProgress, { ImportPhase } from './hooks/useImportProgress';
import './ImportProgressPage.scss';
import exportOutlookToEwayImg from '../../img/graphic-design/Export_outlook.svg';
import syncOutlookToEwayImg from '../../img/graphic-design/Sync_outlook_eway.svg';
import sampleContactsToEwayImg from '../../img/graphic-design/Export_kontakty-01.svg';
import oldAccountToEwayImg from '../../img/graphic-design/Import_old-account.svg';
import { progressIndicatorStyles } from '../FinalizationPage/FinalizationPage';
import { getEwayObject } from '../../helpers/ewayObjectHelper';

type TLocalDatabaseCreationFinalizationMessageProps = {
    localDatabaseCreationInfo: TGetLocalDatabaseCreationProgressResponse | null;
};

const LocalDatabaseCreationFinalizationMessage: React.FC<TLocalDatabaseCreationFinalizationMessageProps> = ({ localDatabaseCreationInfo }) => {
    const { importDatabaseType } = useContext(FormContext);
    const eway = getEwayObject();
    const deletedWsUrl = eway.getDeletedWebServiceAddress?.();

    const myStrings = Strings.finalization;

    const isWithOutlookImport = importDatabaseType === ImportDatabaseType.Outlook;

    let finalMessage: JSX.Element | string = myStrings.loading;

    const isWaitingForInitialSyncToFinish = !localDatabaseCreationInfo || ['CreatingLocalDatabase', 'WaitingUntilInitialSynchronizationFinishes'].includes(localDatabaseCreationInfo.progressStage);

    if (isWaitingForInitialSyncToFinish) {
        if (isWithOutlookImport) {
            finalMessage = <div style={{whiteSpace: 'pre-wrap'}}>{myStrings.finalizationWaitingUntilInitialSynchronizationFinishesOutlookImport + (localDatabaseCreationInfo?.progressMessage ?? '')}</div>;
        } else if (!deletedWsUrl) {
            // Creating sample database
            finalMessage = myStrings.finalizationWaitingUntilInitialSynchronizationFinishes;
        } else {
            finalMessage = myStrings.finalizationWaitingUntilInitialSynchronizationFinishesSignIn;
        }
    } else if (localDatabaseCreationInfo) {
        finalMessage = localDatabaseCreationInfo.progressMessage;
    }

    return <>{finalMessage}</>;
};

const getPageImg = (importDatabaseType: ImportDatabaseType | null, importFromOutlookType?: keyof typeof OutlookImportType) => {
    if (importDatabaseType === ImportDatabaseType.Outlook) {
        if (importFromOutlookType && importFromOutlookType === OutlookImportType.TwoWay) {
            return syncOutlookToEwayImg;
        } else {
            return exportOutlookToEwayImg;
        }
    } else if (importDatabaseType === ImportDatabaseType.DeletedWsData) {
        return oldAccountToEwayImg;
    } else {
        return sampleContactsToEwayImg;
    }
};

const ImportProgressPage: React.FC = () => {
    const { importPhase, localDatabaseCreationProgress } = useImportProgress();
    const hasErrorOccurred = !!localDatabaseCreationProgress?.hasErrorOccured;
    const { importFromOutlookFormik, importDatabaseType } = useContext(FormContext);

    const importFromOutlookType = importFromOutlookFormik.values.importType?.key as keyof typeof OutlookImportType;
    const pageImg = useMemo(() => getPageImg(importDatabaseType, importFromOutlookType), [importDatabaseType, importFromOutlookType]);

    return (
        <>
            {hasErrorOccurred && <Navigate to={RouteConfig.otherPages.finalizationFailed} />}
            {importPhase === ImportPhase.Finish && <Navigate to={RouteConfig.outlookPages.importSuccess} />}
            <PageBase imgSrc={pageImg} imgClassName="import-progress-page__img">
                <PageTitle
                    title={Strings.importProgressPage.title}
                    subtitle={importDatabaseType === ImportDatabaseType.SampleData ? Strings.importProgressPage.subtitleSampleData : Strings.importProgressPage.subtitle}
                />
                <div className="import-progress-page">
                    <ProgressIndicator
                        label={Strings.finalization.pleaseWait}
                        description={<LocalDatabaseCreationFinalizationMessage localDatabaseCreationInfo={localDatabaseCreationProgress} />}
                        className="import-progress__progress"
                        styles={progressIndicatorStyles}
                    />
                </div>
            </PageBase>
        </>
    );
};

export default ImportProgressPage;
