import { TPasswordStrength } from "../pages/ChoosePasswordPage/ChoosePasswordPage";

export class PasswordHelper {
    static MIN_ACCEPTABLE_PASSWORD_SCORE = 2;
    
    static generate(length: number): string {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%^&*';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    static isStrongEnough = (passwordScore: TPasswordStrength | null) => {
        if (!passwordScore) {
            return false;
        }

        return passwordScore.IsAdmissible && passwordScore.Score >= PasswordHelper.MIN_ACCEPTABLE_PASSWORD_SCORE;
    };
}
