export const getMsalConfig = (mainPageUri: string) => ({
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: mainPageUri,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
});

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
    prompt: 'select_account'
};
