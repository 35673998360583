import { useNavigate } from "react-router-dom";
import RouteConfig from "../../config/RouteConfig";
import Finalization from "../../pages/FinalizationPage/Finalization/Finalization";
import React from 'react';

const useFinalizationErrorDetection = (finalization: Finalization | null) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!finalization) {
            return;
        }

        const unsubscribe = finalization.subscribe(x => {
            if (x.error || x.hostingCreationErrorInfo) {
                navigate(RouteConfig.otherPages.finalizationFailed);
            }
        });

        return () => {
            unsubscribe();
        };


    }, [finalization, navigate]);
};

export default useFinalizationErrorDetection;