import React from "react";
import CookieHelper from "../helpers/CookieHelper";

const useSyncValueToCookies = (cookieName: string, cookieValue: string) => {
    
    React.useEffect(() => {
        if (!cookieValue) {
            return;
        }
    
        CookieHelper.setCookieText(cookieName, cookieValue, 24 * 365 * 5);
    }, [cookieName, cookieValue]);
};

export default useSyncValueToCookies;