import { TGetInfoAboutHostingResponse } from '@eway-crm/hostingdaemon-js-lib';
import Connection from '../api/Connection';
import SearchParams from '../constants/SearchParams';
import { OUTLOOK_GUID_NAME_MAP } from '../pages/CompanyInfoPage/data/getCompanyInfoTwoDropdownOptions';
import { TUserToCreate } from '../pages/FinalizationPage/Finalization/Finalization';
import { compare } from 'compare-versions';
import EnvironmentHelper from './EnvironmentHelper';

const RedirectToImportContactsFromO365Page = (hostingInfo: TGetInfoAboutHostingResponse, accountsInfo: TUserToCreate[], outlookType: string[], isLoggedViaMs: boolean, wcfVersion: string | undefined, language: string, failedCallback: () => void) => {
    const welcomePageUrl = `${hostingInfo.eWayWebServiceUrl}/Web/#/welcome-page`;
    const { username } = accountsInfo[0];

    const getOutlookTypeParamValue = () => {
        return encodeURIComponent(outlookType.map(ot => OUTLOOK_GUID_NAME_MAP[ot]).join(","));
    };

    if (isLoggedViaMs) {
        if (wcfVersion && compare(wcfVersion, "8.0.0.103", "<=")) {
            // Versions with Login.aspx
            // Invoke Azure login, redirect user to WA Welcome Page after login
            const url = `${welcomePageUrl}?LoginHint=${username}&${SearchParams.outlookTypes}=${getOutlookTypeParamValue()}`;
            window.location.replace(`${hostingInfo.eWayWebServiceUrl}/Web/Login.aspx?LoginHint=${username}&LoginForced=true&ReturnUrl=${encodeURIComponent(url)}`);
        } else {
            const isWaCloudHosted = wcfVersion && compare(wcfVersion, "8.0.0.115", ">=");
            const baseUrl = isWaCloudHosted ? "https://app.eway-crm." + (EnvironmentHelper.isProd() ? "com" : "dev") + "/auth/" : `${hostingInfo.eWayWebServiceUrl}/Auth.aspx`;
            let url = `${baseUrl}?phase=login&client_id=web%20access&username=${username}&redirect=%2Fwelcome-page`;

            if (isWaCloudHosted) {
                url += "&wsurl=" + encodeURI(hostingInfo.eWayWebServiceUrl);
            }

            window.location.replace(url);
        }
    }
    else {
        // Create session and pass session id
        const { password } = accountsInfo[0];
        Connection.GetNewSessionId(hostingInfo.eWayWebServiceUrl, username, password!, hostingInfo.eWayClientVersion, (sessionId) => {
            if (sessionId) {
                window.location.replace(`${welcomePageUrl}?sessionId=${sessionId}&${SearchParams.outlookTypes}=${getOutlookTypeParamValue()}&wsUrl=${encodeURI(hostingInfo.eWayWebServiceUrl)}&lang=${language}`);
            }
            else {
                failedCallback();
            }
        });
    }
};

export default RedirectToImportContactsFromO365Page;