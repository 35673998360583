import { MetaStrings } from "@eway-crm/gui";

const roleTranslationStrings = MetaStrings.create({
    en: {
        Administrator: 'Administrator',
        Manager: 'Manager',
        User: 'User'
    },
    cs: {
        Administrator: 'Administrator',
        Manager: 'Manažer',
        User: 'Uživatel'
    },
    sk: {
        Administrator: 'Administrator',
        Manager: 'Manažer',
        User: 'Používateľ'
    },
    de: {
        Administrator: 'Administrator',
        Manager: 'Manager',
        User: 'User'
    },
    ru: {
        Administrator: 'Администратор',
        Manager: 'Менеджер',
        User: 'Пользователь'
    },
    no: {
        Administrator: 'Administrator',
        Manager: 'Manager',
        User: 'User'
    },
});

export default roleTranslationStrings;