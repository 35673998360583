import React from "react";
import FinalizationFailedPage from "../pages/FinalizationFailedPage/FinalizationFailedPage";

type TErrorBoundaryProps = unknown;
type TErrorBoundaryState = {
    hasError: boolean;
};

/**
 * Shows FinalizationFailedPage if there was an uncaught error in wrapped component
 */
export default class PageErrorBoundary extends React.Component<TErrorBoundaryProps, TErrorBoundaryState> {
    constructor(props: TErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
      }

    componentDidCatch(e: unknown) {
        console.error(e);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <FinalizationFailedPage />;
        }

        return this.props.children;
    }
}