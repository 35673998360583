import { ErrorHelper } from '@eway-crm/connector';
import HostingApi from '@eway-crm/hostingdaemon-js-lib';
import LocalizationHelper from '../localization/LocalizationHelper';
import { popularEmailDomains } from '../pages/CompanyInfoPage/data/popularEmailDomains';

export class StringHelper {
    /**
     * Prepares the string for sending to eway by e.g. trimming it
     */
    static prepareForEway = (string: string) => {
        return string.trim();
    };

    static loadNameDeclension = async (hostingApi: HostingApi, firstName: string) => {
        if (LocalizationHelper.isLanguageCs()) {
            const res = await hostingApi.getNameDeclension({ firstName });
            if (res && !res.IsErrorResponse && res.Value) {
                return res.Value;
            }
            return null;
        }

        return firstName;
    };

    /**
     * Naive approach to get first and last name from email. In many cases it will be incorrect.
     */
    static getFirstAndLastNameFromEmail = (email: string) => {
        const firstPart = email.substring(0, email.lastIndexOf("@"));

        if (firstPart.includes('.')) {
            const [firstName, ...lastNameParts] = firstPart.split('.');
            const lastName = lastNameParts.join(".");
            return {
                firstName: StringHelper.capitalize(firstName),
                lastName: StringHelper.capitalize(lastName),
            };
        }

        return {
            firstName: firstPart,
            lastName: '',
        };
    };

    static readonly extractDomainFromEmail = (email: string) =>{
        // Regular expression to match the domain part of an email address
        const domainRegex = /@([^@\s]+\.[^@\s]+)/;
        const match = email.match(domainRegex);
        return match ? match[1] : "";
      };

      /**
       * Extracts website from email if it is not blocked (popular) domain
       * @param email 
       * @returns 
       */
      static readonly getNonBlockedWebsiteFromEmail = (email: string) => {
        const isPopularEmailDomain = popularEmailDomains.some(domain => email.endsWith(domain));
        if (isPopularEmailDomain) {
            return "";
        }
    
        return `www.${StringHelper.extractDomainFromEmail(email)}`;
    };

    static capitalize = (string: string) => {
        if (!string) {
            return '';
        }

        return string[0].toUpperCase() + string.slice(1);
    };

    static prepareErrorLog = (e: Error, info: string) => {
        return `${info}. \n${ErrorHelper.stringifyError(e)}`;
    };

    /**
     * Removes characters from start of the string, including any whitespace
     */
    static removeCharactersFromStart(inputString: string, charactersToRemove: string): string {
        let resultString = inputString;
    
        for (const char of charactersToRemove) {
            const regex = new RegExp(`^${char}\\s?`);
            resultString = resultString.replace(regex, '');
        }
    
        return resultString;
    }
}
