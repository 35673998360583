export default class HostingConfig {
    static readonly freeLicenseCount = 1;
    static readonly premiumLicenseCount = 3;

    static ewayLeadType = {
        opportunity: 'Opportunity-Type',
        freeVersion: 'Free version'
    } as const;

    static getEwDownloadLink = (outlookClientVersion: string | null) => (outlookClientVersion ? `https://download.eway-crm.com/setup/eWayCRM-${outlookClientVersion}.exe` : null);
}
