import { TGetHostingActionStatusResponse, TGetInfoAboutHostingResponse, THostingActionRequestResponse, TRequestNewTrialHostingCreationResponse, TRequestNewTrialHostingCreationErrorResponse } from "@eway-crm/hostingdaemon-js-lib";
import { FinalizationSteps } from "../../../constants/FinalizationSteps";
import Strings from "../../../localization/strings";
import { TGetLocalDatabaseCreationProgressResponse } from "../../../types/ewayBrowserBoundObject";
import { TFinalizationState, TUserToCreate } from "./Finalization";

export type TFinalizationReducerActions =
    | { type: 'CREATE_HOSTING'; payload: TRequestNewTrialHostingCreationResponse }
    | { type: 'SET_HOSTING_INFO'; payload: TGetInfoAboutHostingResponse }
    | { type: 'DATA_SAVED'; payload: TUserToCreate[]; activateAzureAdAuthActionId: string | null }
    | { type: 'AFTER_SAVE_ACTIONS_DONE' }
    | { type: 'CREATE_LOCAL_DATABASE' }
    | { type: 'SET_LOCAL_DATABASE_INFO'; payload: TGetLocalDatabaseCreationProgressResponse }
    | { type: 'START_ADD_TRIAL_HOSTING_INFO'; wasStartedImmediatelly: boolean }
    | { type: 'ADD_TRIAL_HOSTING_INFO'; payload: THostingActionRequestResponse }
    | { type: 'SET_ADD_TRIAL_HOSTING_INFO'; payload: TGetHostingActionStatusResponse }
    | { type: 'FINISH' }
    | { type: 'ERROR'; error: string | null }
    | { type: 'DUPLICATE_EMAIL_REQUEST_ERROR'; payload: TRequestNewTrialHostingCreationErrorResponse };

const finalizationReducer = (state: TFinalizationState, action: TFinalizationReducerActions): TFinalizationState => {
    const myStrings = Strings.finalization;

    switch (action.type) {
        case 'CREATE_HOSTING':
            return { ...state, hostingCreationInfo: action.payload, progressMessage: myStrings.sendingRequestForNewHosting, finalizationStep: FinalizationSteps.CreateHosting };
        case 'SET_HOSTING_INFO':
            if (action.payload.HasErrorOccurred) {
                return {
                    ...state,
                    error: `The error has occured while creating hosting in stage ${action.payload.CurrentCreationStadiumNumber} - ${action.payload.CurrentCreationStadiumDescription}`,
                    progressMessage: `${myStrings.welcomeScreenCreatingAccount}: ${action.payload.CurrentCreationStadiumDescription}`,
                    finalizationStep: FinalizationSteps.Error,
                };
            } else if (action.payload.CurrentCreationStadiumNumber === action.payload.CreationCompleteStadiumNumber) {
                return {
                    ...state,
                    hostingInfo: action.payload,
                    progressMessage: myStrings.finalizationHostingDaemonFinish || `${myStrings.welcomeScreenCreatingAccount}: ${action.payload.CurrentCreationStadiumDescription}`,
                    finalizationStep: FinalizationSteps.WaitingForAllTrialHostingInfoSchedule,
                };
            } else {
                return { ...state, hostingInfo: action.payload, progressMessage: `${myStrings.welcomeScreenCreatingAccount}: ${action.payload.CurrentCreationStadiumDescription}` };
            }
        case 'START_ADD_TRIAL_HOSTING_INFO':
            return {
                ...state,
                wasUpdateTrialHostingStartedImmediatelly: action.wasStartedImmediatelly,
                finalizationStep: FinalizationSteps.AllTrialHostingInfoScheduled,
            };
        case 'ADD_TRIAL_HOSTING_INFO':
            if (action.payload.IsErrorResponse) {
                return {
                    ...state,
                    addTrialHostingInfo: action.payload,
                    error: `Error has occured while creating local database. Message: ${action.payload.message}`,
                    finalizationStep: FinalizationSteps.Error,
                };
            }
            return {
                ...state,
                addTrialHostingInfo: action.payload,
                finalizationStep: FinalizationSteps.AddingTrialHostingInfo
            };
        case 'SET_ADD_TRIAL_HOSTING_INFO':
            if (action.payload.HasErrorOccured) {
                return {
                    ...state,
                    addTrialHostingProgressInfo: action.payload,
                    error: `Error has occured while creating local database. Message: ${action.payload.ResultValue}`,
                    finalizationStep: FinalizationSteps.Error,
                };
            } else if (action.payload.IsComplete) {
                return {
                    ...state,
                    addTrialHostingProgressInfo: action.payload,
                    finalizationStep: FinalizationSteps.HostingCreatedWithAllInfo,
                };
            }
            return { ...state, addTrialHostingProgressInfo: action.payload };
        case 'DATA_SAVED':
            return {
                ...state,
                progressMessage: myStrings.welcomeScreenCustomizingSettings,
                finalizationStep: FinalizationSteps.ApiDataSaved,
                accountsInfo: action.payload,
                activateAzureAdAuthActionId: action.activateAzureAdAuthActionId,
            };
        case 'AFTER_SAVE_ACTIONS_DONE':
            return { ...state, finalizationStep: FinalizationSteps.AfterSaveActionsDone };
        case 'CREATE_LOCAL_DATABASE':
            return { ...state, finalizationStep: FinalizationSteps.CreateLocalDatabase };
        case 'SET_LOCAL_DATABASE_INFO':
            if (action.payload.hasErrorOccured) {
                return {
                    ...state,
                    localDatabaseCreationInfo: action.payload,
                    error: `Error has occured while creating local database. Message: ${action.payload.progressMessage}`,
                    finalizationStep: FinalizationSteps.Error,
                };
            } else if (action.payload.isFinished) {
                return {
                    ...state,
                    localDatabaseCreationInfo: action.payload,
                    progressMessage: myStrings.finalizationFinish || action.payload.progressMessage,
                    finalizationStep: FinalizationSteps.Finish,
                };
            } else {
                return { ...state, localDatabaseCreationInfo: action.payload, progressMessage: action.payload.progressMessage };
            }
        case 'FINISH':
            return { ...state, progressMessage: myStrings.welcomeScreenCustomizingSettings, finalizationStep: FinalizationSteps.Finish };
        case 'ERROR':
            console.error(action.error);
            return { ...state, error: action.error, finalizationStep: FinalizationSteps.Error };
        case 'DUPLICATE_EMAIL_REQUEST_ERROR':
            return { ...state, hostingCreationErrorInfo: action.payload, finalizationStep: FinalizationSteps.DuplicateEmailRequestError };
    }
};

export default finalizationReducer;