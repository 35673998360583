import * as React from 'react';
import { Helmet } from "react-helmet";
import WebAccessPreloaderHelper from '../../../helpers/WebAccessPreloaderHelper';

const WebAccessPreloader: React.FC<{ hostingWebServiceUrl: string }> = ({ hostingWebServiceUrl }) => {
    const [files, setFiles] = React.useState<string[] | null>(null);

    React.useEffect(() => {
        WebAccessPreloaderHelper.wakeUpAndGetResourceFiles(hostingWebServiceUrl, setFiles);
    }, [hostingWebServiceUrl]);

    return (
        <Helmet>
            <link rel="prefetch" href={`${hostingWebServiceUrl}/Web/#/`} as="document" />
            <link rel="prefetch" href={`${hostingWebServiceUrl}/Web/WaApiPage.aspx`} as="script" />
            {(!!files && files.length !== 0) &&
                files.map(file => <link key={`preloaded-${file}`} rel="prefetch" href={`${hostingWebServiceUrl}/Web/${file}`} as={file.endsWith('.css') ? 'style' : 'script'} />)
            }
        </Helmet>
    );
};

export default WebAccessPreloader;