import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { IProgressIndicatorStyles, mergeStyles, ProgressIndicator } from '@fluentui/react';
import { useNavigate } from 'react-router';
import PageBase from '../../components/PageBase/PageBase';
import PageTitle from '../../components/PageTitle/PageTitle';
import RouteConfig from '../../config/RouteConfig';
import Strings from '../../localization/strings';
import { FormContext } from '../../providers/FormProvider';
import RegisterActionHelper from '../../helpers/RegisterActionHelper';
import RedirectToImportContactsFromO365Page from '../../helpers/RedirectToImportContactsFromO365Page';
import { getEwayObject } from '../../helpers/ewayObjectHelper';
import pageImg from '../../img/graphic-design/eway_integrations-01.svg';
import gif1 from '../../img/finalization/1_install.gif';
import gif2 from '../../img/finalization/2_install.gif';
import gif3 from '../../img/finalization/3_install.gif';
import gif4 from '../../img/finalization/4_install.gif';
import gif5 from '../../img/finalization/5_install.gif';
import gif6 from '../../img/finalization/6_install.gif';
import gif7 from '../../img/finalization/7_install.gif';
import './FinalizationPage.scss';
import WebAccessPreloader from './WebAccessPreloader/WebAccessPreloader';
import { FinalizationSteps } from '../../constants/FinalizationSteps';
import useFinalizationState from './hooks/useFinalizationState';
import { FinalizationContext } from '../../providers/FinalizationProvider/FinalizationProvider';
import { LanguageContext } from '../../providers/LanguageProvider';

const PROGRESS_MESSAGE_WAIT_TIME = 4000;
const FADE_IN_CLASSNAME = 'finalization-page__page-img--fadein';
const FADE_OUT_CLASSNAME = 'finalization-page__page-img--fadeout';

export const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
    itemName: {
        textAlign: 'center',
        fontSize: '1rem'
    },
    itemDescription: {
        textAlign: 'center',
        height: '4rem',
        fontSize: '14px'
    },
};

const useFinalizationProps = () => {
    const finalizationState = useFinalizationState();
    const { finalizationStep, hostingInfo, localDatabaseCreationInfo, progressMessage, } = finalizationState;

    const { registerAction } = useContext(FormContext);
    const isSignIn = RegisterActionHelper.isSignIn(registerAction);
    const eway = getEwayObject();
    const myStrings = Strings.finalization;
    const [lastHostingDaemonProgress, setLastHostingDaemonProgress] = useState({ stage: 0, updateTime: new Date() });
    const [finalMessage, setFinalMessage] = useState<React.ReactNode>(
        isSignIn ? myStrings.finalizationWaitingUntilInitialSynchronizationFinishesSignIn : myStrings.finalizationHostingDaemonStage0
    );
    const [finalPageImg, setFinalPageImg] = useState(isSignIn ? pageImg : gif1);
    const [finalPageImgClassName, setFinalPageImgClassName] = useState(FADE_IN_CLASSNAME);

    useEffect(() => {

        const changeImg = (newImg: string) => {
            setFinalPageImgClassName(FADE_OUT_CLASSNAME);
            setTimeout(() => {
                // Batch updates like this, because they are not batched until React 18+ (https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#automatic-batching)
                ReactDOM.unstable_batchedUpdates(() => {
                    setFinalPageImg(newImg);
                    setFinalPageImgClassName(FADE_IN_CLASSNAME);
                });
            }, 625);
        };

        if (finalizationState.wasUpdateTrialHostingStartedImmediatelly) {
            setFinalMessage(myStrings.finalizationHostingDaemonOnlyUpdate);
            return;
        } else if (['cs', 'en', 'sk'].includes(Strings.getLanguage()) && !!hostingInfo) {
            if (finalizationStep === FinalizationSteps.CreateHosting || lastHostingDaemonProgress.stage !== hostingInfo.CreationCompleteStadiumNumber) {
                const FinalizationHostingDaemonStage24 = myStrings.finalizationHostingDaemonStage24;
                const hostingDaemonProgressProps = {
                    0: {
                        text: myStrings.finalizationHostingDaemonStage0,
                        img: gif1,
                    },
                    1: {
                        text: myStrings.finalizationHostingDaemonStage1,
                        img: gif2,
                    },
                    2: {
                        text: myStrings.finalizationHostingDaemonStage2,
                        img: gif3,
                    },
                    4: {
                        text: myStrings.finalizationHostingDaemonStage4,
                        img: gif4,
                    },
                    6: {
                        text: myStrings.finalizationHostingDaemonStage6,
                        img: gif5,
                    },
                    14: {
                        text: myStrings.finalizationHostingDaemonStage14,
                        img: gif6,
                    },
                    24: {
                        text: <FinalizationHostingDaemonStage24 />,
                        img: gif7,
                    },
                };

                let nextStageId = lastHostingDaemonProgress.stage;
                const currentStage = hostingInfo.CurrentCreationStadiumNumber;
                if (currentStage > lastHostingDaemonProgress.stage) {
                    nextStageId = Number(Object.keys(hostingDaemonProgressProps).find((stage) => Number(stage) > lastHostingDaemonProgress.stage) ?? 25);

                    const nextChangeTime = new Date(lastHostingDaemonProgress.updateTime.getTime() + PROGRESS_MESSAGE_WAIT_TIME);
                    const currentTime = new Date();
                    const canMakeNextChange = nextChangeTime < currentTime;

                    if (nextStageId <= currentStage && currentStage !== hostingInfo.CreationCompleteStadiumNumber && canMakeNextChange) {
                        setLastHostingDaemonProgress({ stage: nextStageId, updateTime: new Date() });
                        setFinalMessage(
                            isSignIn
                                ? myStrings.finalizationWaitingUntilInitialSynchronizationFinishesSignIn
                                : hostingDaemonProgressProps[nextStageId as keyof typeof hostingDaemonProgressProps].text
                        );

                        if (!isSignIn) {
                            changeImg(hostingDaemonProgressProps[nextStageId as keyof typeof hostingDaemonProgressProps].img);
                        }
                    } else if (currentStage === hostingInfo.CreationCompleteStadiumNumber) {
                        setLastHostingDaemonProgress({ stage: nextStageId, updateTime: new Date() });
                    }
                }
            } else if (finalizationStep === FinalizationSteps.HostingCreatedWithAllInfo || finalizationStep === FinalizationSteps.ApiDataSaved) {
                if (eway.isOutlookClient()) {
                    setFinalMessage(myStrings.finalizationHostingDaemonFinish);
                } else {
                    setFinalMessage(myStrings.finalizationHostingDaemonFinishWeb);
                }
            } else if (finalizationStep === FinalizationSteps.CreateLocalDatabase) {
                const isWaitingForInitialSyncToFinish =
                    !localDatabaseCreationInfo || ['CreatingLocalDatabase', 'WaitingUntilInitialSynchronizationFinishes'].includes(localDatabaseCreationInfo.progressStage);

                if (isWaitingForInitialSyncToFinish) {
                    if (isSignIn) {
                        setFinalMessage(myStrings.finalizationWaitingUntilInitialSynchronizationFinishesSignIn);
                    } else {
                        setFinalMessage(myStrings.finalizationCreatingLocalDatabase);
                    }
                } else {
                    setFinalMessage(progressMessage);
                }

                setFinalPageImg(pageImg);
            }
        } else if (hostingInfo && hostingInfo.CurrentCreationStadiumNumber !== 0) {
            setFinalMessage(progressMessage);
        }
    }, [eway, finalizationStep, hostingInfo, lastHostingDaemonProgress, progressMessage, myStrings, localDatabaseCreationInfo, registerAction, isSignIn, finalizationState.wasUpdateTrialHostingStartedImmediatelly]);

    return { finalMessage, finalPageImg, finalPageImgClassName };
};

const FinalizationPage: React.FC = () => {
    const { registerAction, isLoggedViaMs, companyInfoTwoFormik } = useContext(FormContext);
    const { language }= useContext(LanguageContext);
    const { finalization } = useContext(FinalizationContext);
    const finalizationState = useFinalizationState();
    const { error, finalizationStep, hostingInfo, accountsInfo } = finalizationState;

    const navigate = useNavigate();

    // Do not useValidatePreviousFormikOnMount on this page since we can come here after login without any info

    const eway = getEwayObject();

    useEffect(() => {
        if (error) {
            console.error(error);
            navigate(RouteConfig.otherPages.finalizationFailed);
            return;
        }

        if (finalizationStep !== FinalizationSteps.Finish) {
            return;
        }

        if (RegisterActionHelper.isSignIn(registerAction)) {
            navigate(RouteConfig.otherPages.loginSuccess);
        }
        else if (eway.isOutlookClient()) {
            navigate(RouteConfig.outlookPages.finishConfiguration);
        }
        else if (!hostingInfo || !accountsInfo) {
            console.error('Hosting info or account info is missing, could not navigate to WA Welcome page.');
            navigate(RouteConfig.otherPages.finalizationFailed);
        } else {
            RedirectToImportContactsFromO365Page(
                hostingInfo,
                accountsInfo,
                companyInfoTwoFormik.values.outlookType,
                isLoggedViaMs,
                finalization?.connection?.apiConnection.sessionHandler.lastSuccessfulLoginResponse?.WcfVersion,
                language,
                () => navigate(RouteConfig.otherPages.finalizationFailed)
            );
        }
    }, [navigate, eway, finalizationStep, accountsInfo, hostingInfo, registerAction, isLoggedViaMs, error, companyInfoTwoFormik.values.outlookType, finalization, language]);

    const { finalMessage, finalPageImg, finalPageImgClassName } = useFinalizationProps();

    return (
        <PageBase imgSrc={finalPageImg} hideBackButton imgClassName={mergeStyles('finalization-page__page-img', finalPageImgClassName)}>
            <PageTitle title={Strings.finalization.title} subtitle={Strings.finalization.subtitle} />
            <div className="finalization-page">
                <ProgressIndicator label={Strings.finalization.pleaseWait} description={finalMessage} className="finalization-page__progress" styles={progressIndicatorStyles} />
            </div>
            {(!!finalizationState.hostingInfo && !!finalizationState.hostingInfo.eWayWebServiceUrl && !!finalizationState.addTrialHostingProgressInfo?.IsComplete) &&
                <WebAccessPreloader hostingWebServiceUrl={finalizationState.hostingInfo.eWayWebServiceUrl} />
            }
        </PageBase>
    );
};

export default FinalizationPage;
