import Strings from "../localization/strings";
import * as Yup from 'yup';
import { StringHelper as StringHelperGui } from "@eway-crm/gui";

export default class YupHelper {
    static readonly validateEmail = Yup.string()
        .required(() => Strings.fieldCannotBeEmpty)
        .email(() => Strings.emailAddressIsNotValid)
        .test({
            test: StringHelperGui.hasEmailValidCharacters,
            message: Strings.emailAddressHasInvalidCharacters
        });
}