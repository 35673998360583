import './SignInWithMicrosoftButton.scss';
import React, { useContext, useState } from 'react';
import { mergeStyles } from '@fluentui/merge-styles';
import msLogoImg from '../../../../img/ms-symbol.svg';
import Strings from '../../../../localization/strings';
import { useMsal } from '@azure/msal-react';
import { SilentRequest } from '@azure/msal-browser';
import { loginRequest } from '../../../../config/authConfig';
import { AccountInfo } from '@azure/msal-common';
import { FormContext } from '../../../../providers/FormProvider';
import { useNavigate } from 'react-router';
import RouteConfig from '../../../../config/RouteConfig';
import { IComboBoxOption, Link, Spinner, SpinnerSize, Text, useTheme } from '@fluentui/react';
import MsGraph from '../../../../api/MsGraph';
import { StringHelper } from '../../../../helpers/StringHelper';
import useLogin from '../../../../helpers/hooks/useLogin';
import { loadCountryCallingCodeOptions } from '../../../UserInfoPage/UserInfoPage';
import * as Yup from 'yup';

type TSignInWithMicrosoftButtonProps = Omit<React.ComponentProps<'button'>, 'onClick'>;

interface ICustomComboBoxOption extends IComboBoxOption {
    data: string;
}

const extractCountryCallingCodeOptionFromPhoneNumber = async (phoneNumber: string) => {
    const _phoneNumber = phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters
    const options = await loadCountryCallingCodeOptions() as ICustomComboBoxOption[];

    const sortedOptions = options.sort((a, b) => b.data.length - a.data.length);
    const option = sortedOptions.find(o => _phoneNumber.startsWith(o.data));

    if (!option) {
        return null;
    } else if (option?.data === "1") {
        // Prioritize US before its territories and CA
        const usOption = sortedOptions.find((o) => o.key === 'US')!;
        return usOption;
    }

    return option;
};

const SignInWithMicrosoftButton: React.FC<TSignInWithMicrosoftButtonProps> = ({ className, ...rest }) => {
    const [error, setError] = useState<JSX.Element | string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { instance } = useMsal();
    const theme = useTheme();
    const { introFormik, userInfoFormik, setIsLoggedViaMs, hostingApi } = useContext(FormContext);
    const navigate = useNavigate();
    const myStrings = Strings.introPage;
    const { onLogin } = useLogin();

    const requestProfileData = async (account: AccountInfo) => {
        const request: SilentRequest = {
            ...loginRequest,
            account,
        };

        const tokenRes = await instance.acquireTokenSilent(request);
        const msGraphRes = await MsGraph.getUserData(tokenRes.accessToken);

        return { profileData: msGraphRes, accessToken: tokenRes.accessToken };
    };

    const setErrorMessage = (message: JSX.Element | string | null) => {
        setIsLoading(false);
        setError(message);
    };

    const handleMsLogin = async () => {
        try {
            const loginRes = await instance.loginPopup(loginRequest);
            const account = loginRes.account;

            if (!account) {
                throw new Error(`Login did not return any account information, ${JSON.stringify(loginRes)}`);
            }

            setIsLoading(true);
            const { profileData, accessToken } = await requestProfileData(account);

            if (!profileData || !profileData.userPrincipalName) {
                throw new Error(`Account did not contain necessary information, ${JSON.stringify(loginRes)}`);
            }

            const isUserPrincipalNameEmail = Yup.string().email().isValidSync(profileData.userPrincipalName);
            const email = isUserPrincipalNameEmail ? profileData.userPrincipalName : profileData.mail ?? '';
            if (email) {
                // Check if the account is already registered
                const hostingsRes = await hostingApi.getHostingsByEmail({ email: email.toLowerCase() }, accessToken);

                if (hostingsRes && hostingsRes.IsErrorResponse) {
                    setErrorMessage(myStrings.unableToSignInWithMs);
                    console.error(`An error occurred while getting hostings list.`, hostingsRes.message);
                    return;
                } else if (hostingsRes && !!hostingsRes.HostingsUrls) {
                    setIsLoading(false);

                    const { Url, UserName } = hostingsRes.HostingsUrls[0];
                    setErrorMessage(
                        <>
                            {myStrings.accountAlreadyExists} <Link onClick={() => onLogin(Url, UserName)}>{myStrings.logIn}</Link>
                        </>
                    );

                    console.error(`Hosting already exists`, hostingsRes.HostingsUrls);
                    return;
                }
            }

            let phoneNumber = profileData.mobilePhone ?? profileData.businessPhones[0] ?? '';
            let phoneDialCode: ICustomComboBoxOption | null = null;
            if (phoneNumber) {
                phoneDialCode = await extractCountryCallingCodeOptionFromPhoneNumber(phoneNumber);
                if (phoneDialCode) {
                    phoneNumber = StringHelper.removeCharactersFromStart(phoneNumber.replace(new RegExp(`^\\+\\s?`), ""), phoneDialCode.data);
                }
            }

            const firstName = profileData.givenName ?? introFormik.values.firstName;
            const nameDeclension = await StringHelper.loadNameDeclension(hostingApi, firstName);

            await introFormik.setValues({
                email,
                username: profileData.userPrincipalName,
                agreeToTermsAndConditions: true,
                firstName: profileData.givenName ?? introFormik.values.firstName,
                lastName: profileData.surname ?? introFormik.values.lastName,
                nameDeclension,
            });

            await userInfoFormik.setValues({
                companyName: profileData.companyName ?? '',
                phoneDialCode,
                phoneNumber,
            });

            setErrorMessage(null);
            setIsLoggedViaMs(true);
            navigate(RouteConfig.pages.userInfo);
        } catch (e) {
            console.error('Unable to login via MS', e);
            setIsLoggedViaMs(false);
            setErrorMessage(myStrings.unableToSignInWithMs);
        }
    };

    return (
        <div>
            <button className={mergeStyles('sign-in-with-microsoft', className)} type="button" onClick={() => void handleMsLogin()} {...rest}>
                {isLoading ? <Spinner size={SpinnerSize.medium} className={'sign-in-with-microsoft__spinner'} /> : <img src={msLogoImg} alt="MS logo small" className="sign-in-with-microsoft__logo" />}
                <div className="sign-in-with-microsoft__text ignore-ew-window-move">{Strings.introPage.signInWithMicrosoft}</div>
            </button>
            {error && (
                <Text className="sign-in-with-microsoft__error" block style={{ color: theme.palette.redDark }}>
                    {error}
                </Text>
            )}
        </div>
    );
};

export default SignInWithMicrosoftButton;
