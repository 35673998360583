import { IDropdownOption, ITag } from '@fluentui/react';
import { FormikProps, useFormik } from 'formik';
import React, { createContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import RouteConfig from '../config/RouteConfig';
import Strings from '../localization/strings';
import HostingApi from '@eway-crm/hostingdaemon-js-lib';
import { getEwayObject } from '../helpers/ewayObjectHelper';
import { StringHelper } from '../helpers/StringHelper';
import RegisterActionHelper from '../helpers/RegisterActionHelper';
import usePreloadImages from '../helpers/hooks/usePreloadImages';
import useMySearchParams from '../helpers/hooks/useMySearchParams';
import CompanyInfoHelper from '../pages/CompanyInfoPage/CompanyInfoHelper';
import YupHelper from '../helpers/YupHelper';
import CookieHelper from '../helpers/CookieHelper';
import useSyncValueToCookies from '../hooks/useSyncValueToCookies';

export enum RegisterAction {
    SignUpFree = 'SignUpFree',
    SignUpPremium = 'SignUpPremium',
    SignIn = 'SignIn',
    SignInFree = 'SignInFree',
    SignInNoImport = 'SignInNoImport',
    SignInNoImportFree = 'SignInNoImportFree'
}

export enum CheckLoginResponseCode {
    Success,
    Invalid,
    ForcePasswordChange
}

export enum CheckWebServiceResponseCode {
    Success,
    Invalid,
    UseLegacyLogin
}

export enum LoginResponseCode {
    Success,
    SuccessFree,
    Invalid,
    SuccessContinueWithImport,
    SuccessContinueWithImportFree
}

export enum ImportDatabaseType {
    Blank,
    SampleData,
    Outlook,
    DeletedWsData
}

export enum FormFinalizationAction {
    StartSignUp,
    StartAfterSignIn
}

type TIntroPageFormikValues = {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    agreeToTermsAndConditions: boolean;
    nameDeclension: string | null;
};

type TUserInfoPageFormikValues = {
    companyName: string;
    phoneDialCode: IDropdownOption | null;
    phoneNumber: string;
};

type TChoosePasswordPageFormikValues = {
    password: string;
};

export type TFeaturesFormikValues = {
    contactsAndCompanies: boolean;
    sales: boolean;
    projects: boolean;
    marketingCampaigns: boolean;
    quotesAndBilling: boolean;
    timesheetsAndPlanning: boolean;
};

export type TCompanyInfoPageFormikValues = {
    industry: IDropdownOption | null;
    purpose: string[];
    teamSize: IDropdownOption | null;
    language: string | null; // Calculated automatically from userInfoFormik.phoneDialCode
    currency: string | null; // Calculated automatically from userInfoFormik.phoneDialCode
};

type TCompanyInfoTwoPageFormikValues = {
    role: string;
    crmExperience: IDropdownOption | null;
    outlookType: string[];
    website: string;
};

type TFinishConfigurationPageFormikValues = {
    preferredLanguage: IDropdownOption | null;
    saveEmailsByContacts: boolean;
    sendAnonymousDiagnostics: boolean;
};

type TImportFromOutlookPageFormikValues = {
    importType: IDropdownOption | null;
    automaticallyCreateCompanies: boolean;
    importedContactsCategories: ITag[];
};

export type TFormContext = {
    introFormik: FormikProps<TIntroPageFormikValues>;
    userInfoFormik: FormikProps<TUserInfoPageFormikValues>;
    choosePasswordFormik: FormikProps<TChoosePasswordPageFormikValues>;
    featuresFormik: FormikProps<TFeaturesFormikValues>;
    companyInfoFormik: FormikProps<TCompanyInfoPageFormikValues>;
    companyInfoTwoFormik: FormikProps<TCompanyInfoTwoPageFormikValues>;
    finishConfigurationFormik: FormikProps<TFinishConfigurationPageFormikValues>;
    importFromOutlookFormik: FormikProps<TImportFromOutlookPageFormikValues>;
    registerAction: RegisterAction | null;
    setRegisterAction: React.Dispatch<React.SetStateAction<RegisterAction | null>>;
    verifiedEmail: string | null;
    setVerifiedEmail: React.Dispatch<React.SetStateAction<string | null>>;
    hostingApi: HostingApi;
    importDatabaseType: ImportDatabaseType | null;
    setImportDatabaseType: React.Dispatch<React.SetStateAction<ImportDatabaseType | null>>;
    urlParams: URLSearchParams | null;
    isLoggedViaMs: boolean;
    setIsLoggedViaMs: React.Dispatch<React.SetStateAction<boolean>>;
    formFinalizationAction: FormFinalizationAction | null;
};

export const FormContext = createContext<TFormContext>(null!);

const FormProvider: React.FC = ({ children }) => {
    const [registerAction, setRegisterAction] = useState<RegisterAction | null>(null);
    const [verifiedEmail, setVerifiedEmail] = useState<string | null>(null);
    const [importDatabaseType, setImportDatabaseType] = useState<ImportDatabaseType | null>(null);
    const [isLoggedViaMs, setIsLoggedViaMs] = useState(false);
    const [formFinalizationAction, setFormFinalizationAction] = useState<FormFinalizationAction | null>(null);
    const { urlParams } = useMySearchParams(setRegisterAction);

    const eway = getEwayObject();
    const hostingApi = useRef(
        new HostingApi(eway.getHostingUrl() ?? process.env.REACT_APP_HOSTING_DAEMON_URL, (e) => {
            throw new Error(JSON.stringify(e));
        })
    );

    const navigate = useNavigate();
    usePreloadImages();

    const introFormik = useFormik<TIntroPageFormikValues>({
        initialValues: {
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            agreeToTermsAndConditions: false,
            nameDeclension: null
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            firstName: Yup.string().required(() => Strings.fieldCannotBeEmpty),
            lastName: Yup.string().required(() => Strings.fieldCannotBeEmpty),
            email: YupHelper.validateEmail,
            agreeToTermsAndConditions: Yup.boolean().equals([true], () => Strings.fieldCannotBeEmpty),
        }),
        onSubmit: async (values) => {
            setIsLoggedViaMs(false);
            await introFormik.setFieldValue('username', values.email);

            const loadNameDeclension = async () => {
                const nameDeclension = await StringHelper.loadNameDeclension(hostingApi.current, values.firstName);
                await introFormik.setFieldValue('nameDeclension', nameDeclension);
            };

            if (values.email === verifiedEmail) {
                await loadNameDeclension();
                navigate(RouteConfig.pages.userInfo);
            } else {
                navigate(RouteConfig.pages.emailVerification);
                // Load declension after navigation to not hold users flow
                await loadNameDeclension();
            }
        },
    });

    const userInfoFormik = useFormik<TUserInfoPageFormikValues>({
        initialValues: {
            companyName: '',
            phoneDialCode: null,
            phoneNumber: '',
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            companyName: Yup.string().required(() => Strings.fieldCannotBeEmpty),
            phoneDialCode: Yup.object().required(() => Strings.fieldCannotBeEmpty),
            phoneNumber: Yup.string().required(() => Strings.fieldCannotBeEmpty),
        }),
        onSubmit: async (values) => {
            await companyInfoFormik.setFieldValue("language", CompanyInfoHelper.getCompanyLanguage(values.phoneDialCode));
            await companyInfoFormik.setFieldValue("currency", CompanyInfoHelper.getCompanyCurrency(values.phoneDialCode));

            if (!registerAction) {
                setRegisterAction(RegisterAction.SignUpPremium);
            }

            if (!formFinalizationAction) {
                setFormFinalizationAction(FormFinalizationAction.StartSignUp);
            }

            if (isLoggedViaMs) {
                navigate(RouteConfig.pages.companyInfo);
            } else {
                navigate(RouteConfig.pages.choosePassword);
            }
        },
    });

    const choosePasswordFormik = useFormik<TChoosePasswordPageFormikValues>({
        initialValues: {
            password: '',
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            password: Yup.string().required(() => Strings.fieldCannotBeEmpty),
        }),
        onSubmit: () => {
            navigate(RouteConfig.pages.companyInfo);
        },
    });

    const featuresFormik = useFormik<TFeaturesFormikValues>({
        initialValues: {
            contactsAndCompanies: true,
            sales: true,
            projects: true,
            marketingCampaigns: true,
            quotesAndBilling: false,
            timesheetsAndPlanning: false,
        },
        onSubmit: () => {
            navigate(RouteConfig.pages.companyInfo);
        },
    });

    const companyInfoFormik = useFormik<TCompanyInfoPageFormikValues>({
        initialValues: {
            industry: null,
            purpose: [],
            teamSize: null,
            language: null,
            currency: null,
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            industry: Yup.object().nullable().required(() => Strings.fieldCannotBeEmpty),
            purpose: Yup.array().min(1, () => Strings.fieldCannotBeEmpty),
            teamSize: Yup.object().nullable().required(() => Strings.fieldCannotBeEmpty),
        }),
        onSubmit: () => {
            navigate(RouteConfig.pages.companyInfoTwo);
        },
    });

    const companyInfoTwoFormik = useFormik<TCompanyInfoTwoPageFormikValues>({
        initialValues: {
            role: "",
            crmExperience: null,
            outlookType: [],
            website: ""
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            role: Yup.string().required(() => Strings.fieldCannotBeEmpty),
            crmExperience: Yup.object().nullable().required(() => Strings.fieldCannotBeEmpty),
            outlookType: Yup.array().min(1, () => Strings.fieldCannotBeEmpty),
            website: Yup.string().required(() => Strings.fieldCannotBeEmpty),

        }),
        onSubmit: () => {
            navigate(RouteConfig.pages.finalization);
        },
    });

    const finishConfigurationFormik = useFormik<TFinishConfigurationPageFormikValues>({
        initialValues: {
            preferredLanguage: null,
            saveEmailsByContacts: false,
            sendAnonymousDiagnostics: false,
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            preferredLanguage: Yup.object().required(() => Strings.fieldCannotBeEmpty),
        }),
        onSubmit: (values) => {
            if (eway.saveApplicationSetting) {
                eway.saveApplicationSetting('SendErrorInformation', values.sendAnonymousDiagnostics ? 1 : 0);
                eway.saveApplicationSetting('SavingIncomingMailsEnableAutoFromContacts', values.saveEmailsByContacts ? 1 : 0);
                eway.saveApplicationSetting('SavingOutgoingMailsEnableToContacts', values.saveEmailsByContacts ? 1 : 0);
            }

            if (RegisterActionHelper.canShowImportDataOptions(registerAction, eway.isOutlookClient())) {
                navigate(RouteConfig.outlookPages.importData);
            } else {
                if (RegisterActionHelper.isSignIn(registerAction)) {
                    if (!formFinalizationAction) {
                        setFormFinalizationAction(FormFinalizationAction.StartSignUp);
                    }
                    navigate(RouteConfig.pages.finalization);
                } else {
                    navigate(RouteConfig.otherPages.loginSuccess);
                }
            }
        },
    });

    const importFromOutlookFormik = useFormik<TImportFromOutlookPageFormikValues>({
        initialValues: {
            importType: null,
            automaticallyCreateCompanies: false,
            importedContactsCategories: [],
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            importType: Yup.object().required(() => Strings.fieldCannotBeEmpty)
        }),
        onSubmit: () => {
            setImportDatabaseType(ImportDatabaseType.Outlook);
            navigate(RouteConfig.outlookPages.importProgress);
        },
    });

    useSyncValueToCookies(CookieHelper.names.signUpEmailAddress, introFormik.values.email);
    useSyncValueToCookies(CookieHelper.names.isSignUp, RegisterActionHelper.isSignIn(registerAction) ? '0' : '1');

    return (
        <FormContext.Provider
            value={{
                introFormik,
                userInfoFormik,
                choosePasswordFormik,
                featuresFormik,
                companyInfoFormik,
                companyInfoTwoFormik,
                finishConfigurationFormik,
                importFromOutlookFormik,
                registerAction,
                setRegisterAction,
                verifiedEmail,
                setVerifiedEmail,
                hostingApi: hostingApi.current,
                importDatabaseType,
                setImportDatabaseType,
                urlParams,
                isLoggedViaMs,
                setIsLoggedViaMs,
                formFinalizationAction,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export default FormProvider;
