import { Checkbox, PrimaryButton, Text, TextField, useTheme } from '@fluentui/react';
import React, { useContext } from 'react';
import PageBase from '../../components/PageBase/PageBase';
import TextDivider from '../../components/TextDivider/TextDivider';
import Strings from '../../localization/strings';
import SignInWithMicrosoftButton from './components/SignInWithMicrosoftButton/SignInWithMicrosoftButton';
import { FormContext } from '../../providers/FormProvider';
import PageTitle from '../../components/PageTitle/PageTitle';
import pageImg from '../../img/graphic-design/1-03-leftSide.svg';
import pageImgAlternative from '../../img/graphic-design/1-03-rightSide.svg';
import './IntroPage.scss';

const IntroPage: React.FC = () => {
    const myStrings = Strings.introPage;
    const { introFormik } = useContext(FormContext);
    const theme = useTheme();

    const firstNameError = introFormik.touched.firstName ? introFormik.errors.firstName : undefined;
    const lastNameError = introFormik.touched.lastName ? introFormik.errors.lastName : undefined;
    const emailError = introFormik.touched.email ? introFormik.errors.email : undefined;

    return (
        <PageBase imgSrc={pageImg} imgSrcAlt={pageImgAlternative}>
            <form onSubmit={introFormik.handleSubmit}>
                <div className="intro-page__top-wrap">
                    <PageTitle title={myStrings.title} subtitle={myStrings.subtitle} />
                    <SignInWithMicrosoftButton className="intro-page__ms-signin" />
                </div>
                <TextDivider>{myStrings.orDivider}</TextDivider>
                <div className="intro-page__bottom-wrap">
                    <div className="intro-page__name-inputs">
                        <TextField className="intro-page__firstname" type="text" autoComplete='given-name' placeholder={myStrings.firstName} errorMessage={firstNameError} {...introFormik.getFieldProps('firstName')} maxLength={128} />
                        <TextField className="intro-page__lastname" type="text" autoComplete='family-name' placeholder={myStrings.lastName} errorMessage={lastNameError} {...introFormik.getFieldProps('lastName')} maxLength={128} />
                    </div>
                    <TextField type="email" className="intro-page__email" autoComplete='email' placeholder={myStrings.email} errorMessage={emailError} {...introFormik.getFieldProps('email')} maxLength={256}/>
                    <div>
                        <Checkbox
                            className="intro-page__terms-conditions"
                            required
                            onRenderLabel={() => myStrings.agreeToTermsAndConditions()}
                            checked={introFormik.values.agreeToTermsAndConditions}
                            {...introFormik.getFieldProps('agreeToTermsAndConditions')}
                        />
                        {introFormik.touched.agreeToTermsAndConditions && introFormik.errors.agreeToTermsAndConditions && (
                            <Text variant="small" style={{ color: theme.palette.redDark }}>
                                {introFormik.errors.agreeToTermsAndConditions}
                            </Text>
                        )}
                    </div>
                    <PrimaryButton className="page-base__next-btn" type="submit" disabled={!introFormik.isValid}>
                        {myStrings.signUp}
                    </PrimaryButton>
                </div>
            </form>
        </PageBase>
    );
};

export default IntroPage;
