import React from 'react';
import PageBase from '../../components/PageBase/PageBase';
import './LoginPage.scss';
import pageImg from '../../img/graphic-design/1-03-leftSide.svg';
import PageTitle from '../../components/PageTitle/PageTitle';
import Strings from '../../localization/strings';
import { Link, mergeStyles, PrimaryButton, Spinner, SpinnerSize, Text } from '@fluentui/react';
import useLogin from '../../helpers/hooks/useLogin';
import { useNavigate } from 'react-router-dom';
import RouteConfig from '../../config/RouteConfig';

const SPINNER_STYLES = { circle: { borderColor: '#fff #71afe5 #71afe5', borderWidth: '2px' }, root: { paddingLeft: '0.5rem' } };

const LoginPage = () => {
    const myStrings = Strings.loginPage;
    const { onLogin, isLoggingIn } = useLogin();
    const navigate = useNavigate();

    return (
        <PageBase imgSrc={pageImg} hideBackButton>
            <div className="login-page">
                <PageTitle title={myStrings.title} subtitle={myStrings.subtitle} />
                <div className="login-page__content">
                    <PrimaryButton className={mergeStyles("page-base__next-btn login-page__signup-btn", isLoggingIn && "login-page__signup-btn--loading" )} onClick={() => onLogin()}>
                        {myStrings.login}
                        {isLoggingIn && <Spinner size={SpinnerSize.medium} styles={SPINNER_STYLES} />}
                    </PrimaryButton>
                    <Text variant="medium" className="login-page__signup-wrap">
                        {myStrings.dontHaveAccountYet}{' '}
                        <Link
                            onClick={() => {
                                navigate(RouteConfig.pages.intro);
                            }}
                        >
                            {myStrings.signup}
                        </Link>
                    </Text>
                </div>
            </div>
        </PageBase>
    );
};

export default LoginPage;
