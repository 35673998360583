import { IconButton } from '@fluentui/react';
import { getEwayObject } from '../../../helpers/ewayObjectHelper';

const HeaderButtons: React.FC = () => {
    const eway = getEwayObject();

    if (!eway.isOutlookClient()) {
        return null;
    }

    return (
        <div className="header__buttons">
            <div
                onClick={() => {
                    eway.minimize();
                }}
            >
                <IconButton iconProps={{ iconName: 'Remove' }} />
            </div>
            <div
                onClick={() => {
                    eway.cancel();
                }}
            >
                <IconButton iconProps={{ iconName: 'Cancel' }} />
            </div>
        </div>
    );
};

export default HeaderButtons;
