import { DefaultButton, Dialog, DialogFooter, PrimaryButton, DialogType, IModalProps } from "@fluentui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageBase from "../../components/PageBase/PageBase";
import PageTitle from "../../components/PageTitle/PageTitle";
import RouteConfig from "../../config/RouteConfig";
import { getEwayObject } from "../../helpers/ewayObjectHelper";
import useLogin from "../../helpers/hooks/useLogin";
import Strings from "../../localization/strings";
import './WebServiceDeletedPage.scss';
import onPremiseImg from './../../img/ws-deleted/ws-deleted-onpremise.svg';
import cloudImg from './../../img/ws-deleted/ws-deleted-cloud.svg';
import EnvironmentHelper from "../../helpers/EnvironmentHelper";

const getIsUrlOnPremise = (urlToCheck: string) => {
    if (!urlToCheck) {
        return false;
    }

    const { hostname } = new URL(urlToCheck);

    const CLOUD_URLS = [
        'free.eway-crm.com',
        'free.eway-crm.us',
        'hosting.eway-crm.com',
        'hosting.eway-crm.us',
        'trial.eway-crm.com',
        'trial.eway-crm.us',
    ];

    if (!EnvironmentHelper.isProd()) {
        CLOUD_URLS.push('eway-trial-trial.memos.cz', 'eway-trial-hostings.memos.cz');
    }

    return !CLOUD_URLS.includes(hostname);
};

const MODAL_PROPS: IModalProps = {
    isBlocking: true,
    closeButtonAriaLabel: "Close",
    styles: {
        main: {
            maxWidth: "500px !important",
        }
    },
};

const WebServiceDeletedPage: React.FC = () => {
    const [isOfflineDialogVisible, setIsOfflineDialogVisible] = useState(false);
    const eway = getEwayObject();
    const myStrings = Strings.wsDeletedPage;
    const navigate = useNavigate();
    const { isLoggingIn, onLogin } = useLogin();
    const deletedWsUrl = eway.getDeletedWebServiceAddress?.();

    if (!deletedWsUrl) {
        // Just in case we get to this page without deleted ws url
        navigate(RouteConfig.otherPages.login);
        return null;
    }

    const isOnPremise = getIsUrlOnPremise(deletedWsUrl);

    const toggleOfflineWarningDialog = () => {
        setIsOfflineDialogVisible(prev => !prev);
    };

    const handleLogin = () => {
        onLogin();
    };

    const handleCreateNewAccount = () => {
        navigate(RouteConfig.pages.intro);
    };

    const handleWorkOffline = () => {
        eway.cancel();
    };

    const dialogContentProps = {
        type: DialogType.normal,
        title: myStrings.workOfflineDialog.title,
        subText: myStrings.workOfflineDialog.text,
    };

    return (
        <>
            <PageBase hideBackButton >
                <div className="eway-success-page">
                    <img src={isOnPremise ? onPremiseImg : cloudImg} alt="web service unavailable" className={"ws-deleted-page__img"} />
                    <PageTitle
                    title={isOnPremise ? myStrings.titleOnPremise : myStrings.titleCloud} 
                    subtitle={isOnPremise ? myStrings.subtitleOnPremise : Strings.formatString(myStrings.subtitleCloud, deletedWsUrl)}
                     variant={'large'} 
                     />
                    <div className={"ws-deleted-page__btn-wrap"}>
                        <PrimaryButton className={"ws-deleted-page__btn"} onClick={handleLogin} text={myStrings.changeAccountBtn} disabled={isLoggingIn} />
                        {!isOnPremise && <DefaultButton className={"ws-deleted-page__btn"} onClick={handleCreateNewAccount} text={myStrings.createNewAccountBtn} />}
                        <DefaultButton className={"ws-deleted-page__btn"} onClick={toggleOfflineWarningDialog} text={myStrings.workOfflineBtn} />
                    </div>
                </div>
                <Dialog
                    hidden={!isOfflineDialogVisible}
                    onDismiss={toggleOfflineWarningDialog}
                    dialogContentProps={dialogContentProps}
                    modalProps={MODAL_PROPS}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={handleWorkOffline} text={myStrings.workOfflineDialog.ok} />
                        <DefaultButton onClick={toggleOfflineWarningDialog} text={myStrings.workOfflineDialog.cancel} />
                    </DialogFooter>
                </Dialog>


            </PageBase>
        </>
    );
};


export default WebServiceDeletedPage;