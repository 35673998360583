export default class RouteConfig {
    static readonly pages = {
        intro: '/',
        emailVerification: '/email-verification',
        userInfo: '/user-info',
        choosePassword: '/choose-password',
        companyInfo: '/company-info',
        companyInfoTwo: '/company-info-2',
        finalization: '/finalization',
    };

    static readonly outlookPages = {
        finishConfiguration: '/finish-configuration',
        importData: '/import-data',
        importFromOutlook: '/import-outlook-data',
        importProgress: '/import-progress',
        importSuccess: '/import-success'
    };

    static readonly otherPages = {
        finalizationFailed: '/finalization-failed',
        loginSuccess: '/login-success',
        login: '/login',
        wsUnavailable: '/ws-unavailable',
    };
}
