/**
 * Data extracted from npm currency-list package
 * https://github.com/webplan-pro/currency-list
 */

const currencyList = {
    cs: {
        XOF: {
            name: 'CFA/BCEAO frank',
            symbol_native: 'CFA',
            symbol: 'CFA',
            code: 'XOF',
            name_plural: 'CFA francs BCEAO',
            rounding: 0,
            decimal_digits: 0,
        },
        XAF: {
            name: 'CFA/BEAC frank',
            symbol_native: 'FCFA',
            symbol: 'FCFA',
            code: 'XAF',
            name_plural: 'CFA francs BEAC',
            rounding: 0,
            decimal_digits: 0,
        },
        AED: {
            name: 'SAE dirham',
            symbol_native: 'د.إ.‏',
            symbol: 'AED',
            code: 'AED',
            name_plural: 'UAE dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        AFN: {
            name: 'afghánský afghán',
            symbol_native: '؋',
            symbol: 'Af',
            code: 'AFN',
            name_plural: 'Afghan Afghanis',
            rounding: 0,
            decimal_digits: 0,
        },
        ALL: {
            name: 'albánský lek',
            symbol_native: 'Lek',
            symbol: 'ALL',
            code: 'ALL',
            name_plural: 'Albanian lekë',
            rounding: 0,
            decimal_digits: 0,
        },
        DZD: {
            name: 'alžírský dinár',
            symbol_native: 'د.ج.‏',
            symbol: 'DA',
            code: 'DZD',
            name_plural: 'Algerian dinars',
            rounding: 0,
            decimal_digits: 2,
        },
        USD: {
            name: 'americký dolar',
            symbol_native: '$',
            symbol: '$',
            code: 'USD',
            name_plural: 'US dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        ARS: {
            name: 'argentinské peso',
            symbol_native: '$',
            symbol: 'AR$',
            code: 'ARS',
            name_plural: 'Argentine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        AMD: {
            name: 'arménský dram',
            symbol_native: 'դր.',
            symbol: 'AMD',
            code: 'AMD',
            name_plural: 'Armenian drams',
            rounding: 0,
            decimal_digits: 0,
        },
        AUD: {
            name: 'australský dolar',
            symbol_native: '$',
            symbol: 'AU$',
            code: 'AUD',
            name_plural: 'Australian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BHD: {
            name: 'bahrajnský dinár',
            symbol_native: 'د.ب.‏',
            symbol: 'BD',
            code: 'BHD',
            name_plural: 'Bahraini dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        BDT: {
            name: 'bangladéšská taka',
            symbol_native: '৳',
            symbol: 'Tk',
            code: 'BDT',
            name_plural: 'Bangladeshi takas',
            rounding: 0,
            decimal_digits: 2,
        },
        BZD: {
            name: 'belizský dolar',
            symbol_native: '$',
            symbol: 'BZ$',
            code: 'BZD',
            name_plural: 'Belize dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BOB: {
            name: 'bolivijský boliviano',
            symbol_native: 'Bs',
            symbol: 'Bs',
            code: 'BOB',
            name_plural: 'Bolivian bolivianos',
            rounding: 0,
            decimal_digits: 2,
        },
        BAM: {
            name: 'bosenská konvertibilní marka',
            symbol_native: 'KM',
            symbol: 'KM',
            code: 'BAM',
            name_plural: 'Bosnia-Herzegovina convertible marks',
            rounding: 0,
            decimal_digits: 2,
        },
        BWP: {
            name: 'botswanská pula',
            symbol_native: 'P',
            symbol: 'BWP',
            code: 'BWP',
            name_plural: 'Botswanan pulas',
            rounding: 0,
            decimal_digits: 2,
        },
        BRL: {
            name: 'brazilský real',
            symbol_native: 'R$',
            symbol: 'R$',
            code: 'BRL',
            name_plural: 'Brazilian reals',
            rounding: 0,
            decimal_digits: 2,
        },
        GBP: {
            name: 'britská libra',
            symbol_native: '£',
            symbol: '£',
            code: 'GBP',
            name_plural: 'British pounds sterling',
            rounding: 0,
            decimal_digits: 2,
        },
        BND: {
            name: 'brunejský dolar',
            symbol_native: '$',
            symbol: 'BN$',
            code: 'BND',
            name_plural: 'Brunei dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BGN: {
            name: 'bulharský lev',
            symbol_native: 'лв.',
            symbol: 'BGN',
            code: 'BGN',
            name_plural: 'Bulgarian leva',
            rounding: 0,
            decimal_digits: 2,
        },
        BIF: {
            name: 'burundský frank',
            symbol_native: 'FBu',
            symbol: 'FBu',
            code: 'BIF',
            name_plural: 'Burundian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        BYN: {
            name: 'běloruský rubl',
            symbol_native: 'руб.',
            symbol: 'Br',
            code: 'BYN',
            name_plural: 'Belarusian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        CLP: {
            name: 'chilské peso',
            symbol_native: '$',
            symbol: 'CL$',
            code: 'CLP',
            name_plural: 'Chilean pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        HRK: {
            name: 'chorvatská kuna',
            symbol_native: 'kn',
            symbol: 'kn',
            code: 'HRK',
            name_plural: 'Croatian kunas',
            rounding: 0,
            decimal_digits: 2,
        },
        DOP: {
            name: 'dominikánské peso',
            symbol_native: 'RD$',
            symbol: 'RD$',
            code: 'DOP',
            name_plural: 'Dominican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        DKK: {
            name: 'dánská koruna',
            symbol_native: 'kr',
            symbol: 'Dkr',
            code: 'DKK',
            name_plural: 'Danish kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        DJF: {
            name: 'džibutský frank',
            symbol_native: 'Fdj',
            symbol: 'Fdj',
            code: 'DJF',
            name_plural: 'Djiboutian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        EGP: {
            name: 'egyptská libra',
            symbol_native: 'ج.م.‏',
            symbol: 'EGP',
            code: 'EGP',
            name_plural: 'Egyptian pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        ERN: {
            name: 'eritrejská nakfa',
            symbol_native: 'Nfk',
            symbol: 'Nfk',
            code: 'ERN',
            name_plural: 'Eritrean nakfas',
            rounding: 0,
            decimal_digits: 2,
        },
        EEK: {
            name: 'estonská koruna',
            symbol_native: 'kr',
            symbol: 'Ekr',
            code: 'EEK',
            name_plural: 'Estonian kroons',
            rounding: 0,
            decimal_digits: 2,
        },
        ETB: {
            name: 'etiopský birr',
            symbol_native: 'Br',
            symbol: 'Br',
            code: 'ETB',
            name_plural: 'Ethiopian birrs',
            rounding: 0,
            decimal_digits: 2,
        },
        EUR: {
            name: 'euro',
            symbol_native: '€',
            symbol: '€',
            code: 'EUR',
            name_plural: 'euros',
            rounding: 0,
            decimal_digits: 2,
        },
        PHP: {
            name: 'filipínské peso',
            symbol_native: '₱',
            symbol: '₱',
            code: 'PHP',
            name_plural: 'Philippine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        GHS: {
            name: 'ghanský cedi',
            symbol_native: 'GH₵',
            symbol: 'GH₵',
            code: 'GHS',
            name_plural: 'Ghanaian cedis',
            rounding: 0,
            decimal_digits: 2,
        },
        GEL: {
            name: 'gruzínské lari',
            symbol_native: 'GEL',
            symbol: 'GEL',
            code: 'GEL',
            name_plural: 'Georgian laris',
            rounding: 0,
            decimal_digits: 2,
        },
        GTQ: {
            name: 'guatemalský quetzal',
            symbol_native: 'Q',
            symbol: 'GTQ',
            code: 'GTQ',
            name_plural: 'Guatemalan quetzals',
            rounding: 0,
            decimal_digits: 2,
        },
        GNF: {
            name: 'guinejský frank',
            symbol_native: 'FG',
            symbol: 'FG',
            code: 'GNF',
            name_plural: 'Guinean francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HNL: {
            name: 'honduraská lempira',
            symbol_native: 'L',
            symbol: 'HNL',
            code: 'HNL',
            name_plural: 'Honduran lempiras',
            rounding: 0,
            decimal_digits: 2,
        },
        HKD: {
            name: 'hongkongský dolar',
            symbol_native: '$',
            symbol: 'HK$',
            code: 'HKD',
            name_plural: 'Hong Kong dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        INR: {
            name: 'indická rupie',
            symbol_native: 'টকা',
            symbol: 'Rs',
            code: 'INR',
            name_plural: 'Indian rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        IDR: {
            name: 'indonéská rupie',
            symbol_native: 'Rp',
            symbol: 'Rp',
            code: 'IDR',
            name_plural: 'Indonesian rupiahs',
            rounding: 0,
            decimal_digits: 0,
        },
        IQD: {
            name: 'irácký dinár',
            symbol_native: 'د.ع.‏',
            symbol: 'IQD',
            code: 'IQD',
            name_plural: 'Iraqi dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        ISK: {
            name: 'islandská koruna',
            symbol_native: 'kr',
            symbol: 'Ikr',
            code: 'ISK',
            name_plural: 'Icelandic krónur',
            rounding: 0,
            decimal_digits: 0,
        },
        ILS: {
            name: 'izraelský nový šekel',
            symbol_native: '₪',
            symbol: '₪',
            code: 'ILS',
            name_plural: 'Israeli new sheqels',
            rounding: 0,
            decimal_digits: 2,
        },
        JMD: {
            name: 'jamajský dolar',
            symbol_native: '$',
            symbol: 'J$',
            code: 'JMD',
            name_plural: 'Jamaican dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        JPY: {
            name: 'japonský jen',
            symbol_native: '￥',
            symbol: '¥',
            code: 'JPY',
            name_plural: 'Japanese yen',
            rounding: 0,
            decimal_digits: 0,
        },
        YER: {
            name: 'jemenský rijál',
            symbol_native: 'ر.ي.‏',
            symbol: 'YR',
            code: 'YER',
            name_plural: 'Yemeni rials',
            rounding: 0,
            decimal_digits: 0,
        },
        ZAR: {
            name: 'jihoafrický rand',
            symbol_native: 'R',
            symbol: 'R',
            code: 'ZAR',
            name_plural: 'South African rand',
            rounding: 0,
            decimal_digits: 2,
        },
        KRW: {
            name: 'jihokorejský won',
            symbol_native: '₩',
            symbol: '₩',
            code: 'KRW',
            name_plural: 'South Korean won',
            rounding: 0,
            decimal_digits: 0,
        },
        JOD: {
            name: 'jordánský dinár',
            symbol_native: 'د.أ.‏',
            symbol: 'JD',
            code: 'JOD',
            name_plural: 'Jordanian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        KHR: {
            name: 'kambodžský riel',
            symbol_native: '៛',
            symbol: 'KHR',
            code: 'KHR',
            name_plural: 'Cambodian riels',
            rounding: 0,
            decimal_digits: 2,
        },
        CAD: {
            name: 'kanadský dolar',
            symbol_native: '$',
            symbol: 'CA$',
            code: 'CAD',
            name_plural: 'Canadian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        CVE: {
            name: 'kapverdské escudo',
            symbol_native: 'CV$',
            symbol: 'CV$',
            code: 'CVE',
            name_plural: 'Cape Verdean escudos',
            rounding: 0,
            decimal_digits: 2,
        },
        QAR: {
            name: 'katarský rijál',
            symbol_native: 'ر.ق.‏',
            symbol: 'QR',
            code: 'QAR',
            name_plural: 'Qatari rials',
            rounding: 0,
            decimal_digits: 2,
        },
        KZT: {
            name: 'kazašské tenge',
            symbol_native: 'тңг.',
            symbol: 'KZT',
            code: 'KZT',
            name_plural: 'Kazakhstani tenges',
            rounding: 0,
            decimal_digits: 2,
        },
        KES: {
            name: 'keňský šilink',
            symbol_native: 'Ksh',
            symbol: 'Ksh',
            code: 'KES',
            name_plural: 'Kenyan shillings',
            rounding: 0,
            decimal_digits: 2,
        },
        COP: {
            name: 'kolumbijské peso',
            symbol_native: '$',
            symbol: 'CO$',
            code: 'COP',
            name_plural: 'Colombian pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        KMF: {
            name: 'komorský frank',
            symbol_native: 'FC',
            symbol: 'CF',
            code: 'KMF',
            name_plural: 'Comorian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        CDF: {
            name: 'konžský frank',
            symbol_native: 'FrCD',
            symbol: 'CDF',
            code: 'CDF',
            name_plural: 'Congolese francs',
            rounding: 0,
            decimal_digits: 2,
        },
        CRC: {
            name: 'kostarický colón',
            symbol_native: '₡',
            symbol: '₡',
            code: 'CRC',
            name_plural: 'Costa Rican colóns',
            rounding: 0,
            decimal_digits: 0,
        },
        KWD: {
            name: 'kuvajtský dinár',
            symbol_native: 'د.ك.‏',
            symbol: 'KD',
            code: 'KWD',
            name_plural: 'Kuwaiti dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LBP: {
            name: 'libanonská libra',
            symbol_native: 'ل.ل.‏',
            symbol: 'LB£',
            code: 'LBP',
            name_plural: 'Lebanese pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        LYD: {
            name: 'libyjský dinár',
            symbol_native: 'د.ل.‏',
            symbol: 'LD',
            code: 'LYD',
            name_plural: 'Libyan dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LTL: {
            name: 'litevský litas',
            symbol_native: 'Lt',
            symbol: 'Lt',
            code: 'LTL',
            name_plural: 'Lithuanian litai',
            rounding: 0,
            decimal_digits: 2,
        },
        LVL: {
            name: 'lotyšský lat',
            symbol_native: 'Ls',
            symbol: 'Ls',
            code: 'LVL',
            name_plural: 'Latvian lati',
            rounding: 0,
            decimal_digits: 2,
        },
        MOP: {
            name: 'macajská pataca',
            symbol_native: 'MOP$',
            symbol: 'MOP$',
            code: 'MOP',
            name_plural: 'Macanese patacas',
            rounding: 0,
            decimal_digits: 2,
        },
        MGA: {
            name: 'madagaskarský ariary',
            symbol_native: 'MGA',
            symbol: 'MGA',
            code: 'MGA',
            name_plural: 'Malagasy Ariaries',
            rounding: 0,
            decimal_digits: 0,
        },
        MKD: {
            name: 'makedonský denár',
            symbol_native: 'MKD',
            symbol: 'MKD',
            code: 'MKD',
            name_plural: 'Macedonian denari',
            rounding: 0,
            decimal_digits: 2,
        },
        MYR: {
            name: 'malajsijský ringgit',
            symbol_native: 'RM',
            symbol: 'RM',
            code: 'MYR',
            name_plural: 'Malaysian ringgits',
            rounding: 0,
            decimal_digits: 2,
        },
        MAD: {
            name: 'marocký dinár',
            symbol_native: 'د.م.‏',
            symbol: 'MAD',
            code: 'MAD',
            name_plural: 'Moroccan dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        MUR: {
            name: 'mauricijská rupie',
            symbol_native: 'MURs',
            symbol: 'MURs',
            code: 'MUR',
            name_plural: 'Mauritian rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        HUF: {
            name: 'maďarský forint',
            symbol_native: 'Ft',
            symbol: 'Ft',
            code: 'HUF',
            name_plural: 'Hungarian forints',
            rounding: 0,
            decimal_digits: 0,
        },
        MXN: {
            name: 'mexické peso',
            symbol_native: '$',
            symbol: 'MX$',
            code: 'MXN',
            name_plural: 'Mexican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        MDL: {
            name: 'moldavský leu',
            symbol_native: 'MDL',
            symbol: 'MDL',
            code: 'MDL',
            name_plural: 'Moldovan lei',
            rounding: 0,
            decimal_digits: 2,
        },
        MZN: {
            name: 'mozambický metical',
            symbol_native: 'MTn',
            symbol: 'MTn',
            code: 'MZN',
            name_plural: 'Mozambican meticals',
            rounding: 0,
            decimal_digits: 2,
        },
        MMK: {
            name: 'myanmarský kyat',
            symbol_native: 'K',
            symbol: 'MMK',
            code: 'MMK',
            name_plural: 'Myanma kyats',
            rounding: 0,
            decimal_digits: 0,
        },
        NAD: {
            name: 'namibijský dolar',
            symbol_native: 'N$',
            symbol: 'N$',
            code: 'NAD',
            name_plural: 'Namibian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NPR: {
            name: 'nepálská rupie',
            symbol_native: 'नेरू',
            symbol: 'NPRs',
            code: 'NPR',
            name_plural: 'Nepalese rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        NGN: {
            name: 'nigerijská naira',
            symbol_native: '₦',
            symbol: '₦',
            code: 'NGN',
            name_plural: 'Nigerian nairas',
            rounding: 0,
            decimal_digits: 2,
        },
        NIO: {
            name: 'nikaragujská córdoba',
            symbol_native: 'C$',
            symbol: 'C$',
            code: 'NIO',
            name_plural: 'Nicaraguan córdobas',
            rounding: 0,
            decimal_digits: 2,
        },
        NOK: {
            name: 'norská koruna',
            symbol_native: 'kr',
            symbol: 'Nkr',
            code: 'NOK',
            name_plural: 'Norwegian kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        NZD: {
            name: 'novozélandský dolar',
            symbol_native: '$',
            symbol: 'NZ$',
            code: 'NZD',
            name_plural: 'New Zealand dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        OMR: {
            name: 'ománský rijál',
            symbol_native: 'ر.ع.‏',
            symbol: 'OMR',
            code: 'OMR',
            name_plural: 'Omani rials',
            rounding: 0,
            decimal_digits: 3,
        },
        PAB: {
            name: 'panamská balboa',
            symbol_native: 'B/.',
            symbol: 'B/.',
            code: 'PAB',
            name_plural: 'Panamanian balboas',
            rounding: 0,
            decimal_digits: 2,
        },
        PYG: {
            name: 'paraguajské guarani',
            symbol_native: '₲',
            symbol: '₲',
            code: 'PYG',
            name_plural: 'Paraguayan guaranis',
            rounding: 0,
            decimal_digits: 0,
        },
        PEN: {
            name: 'peruánský sol',
            symbol_native: 'S/.',
            symbol: 'S/.',
            code: 'PEN',
            name_plural: 'Peruvian nuevos soles',
            rounding: 0,
            decimal_digits: 2,
        },
        PLN: {
            name: 'polský zlotý',
            symbol_native: 'zł',
            symbol: 'zł',
            code: 'PLN',
            name_plural: 'Polish zlotys',
            rounding: 0,
            decimal_digits: 2,
        },
        PKR: {
            name: 'pákistánská rupie',
            symbol_native: '₨',
            symbol: 'PKRs',
            code: 'PKR',
            name_plural: 'Pakistani rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        RON: {
            name: 'rumunský leu',
            symbol_native: 'RON',
            symbol: 'RON',
            code: 'RON',
            name_plural: 'Romanian lei',
            rounding: 0,
            decimal_digits: 2,
        },
        RUB: {
            name: 'ruský rubl',
            symbol_native: '₽',
            symbol: 'RUB',
            code: 'RUB',
            name_plural: 'Russian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        RWF: {
            name: 'rwandský frank',
            symbol_native: 'FR',
            symbol: 'RWF',
            code: 'RWF',
            name_plural: 'Rwandan francs',
            rounding: 0,
            decimal_digits: 0,
        },
        SAR: {
            name: 'saúdský rijál',
            symbol_native: 'ر.س.‏',
            symbol: 'SR',
            code: 'SAR',
            name_plural: 'Saudi riyals',
            rounding: 0,
            decimal_digits: 2,
        },
        SGD: {
            name: 'singapurský dolar',
            symbol_native: '$',
            symbol: 'S$',
            code: 'SGD',
            name_plural: 'Singapore dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        SOS: {
            name: 'somálský šilink',
            symbol_native: 'Ssh',
            symbol: 'Ssh',
            code: 'SOS',
            name_plural: 'Somali shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        RSD: {
            name: 'srbský dinár',
            symbol_native: 'дин.',
            symbol: 'din.',
            code: 'RSD',
            name_plural: 'Serbian dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        LKR: {
            name: 'srílanská rupie',
            symbol_native: 'SL Re',
            symbol: 'SLRs',
            code: 'LKR',
            name_plural: 'Sri Lankan rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        SYP: {
            name: 'syrská libra',
            symbol_native: 'ل.س.‏',
            symbol: 'SY£',
            code: 'SYP',
            name_plural: 'Syrian pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        SDG: {
            name: 'súdánská libra',
            symbol_native: 'SDG',
            symbol: 'SDG',
            code: 'SDG',
            name_plural: 'Sudanese pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        TZS: {
            name: 'tanzanský šilink',
            symbol_native: 'TSh',
            symbol: 'TSh',
            code: 'TZS',
            name_plural: 'Tanzanian shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        TWD: {
            name: 'tchajwanský dolar',
            symbol_native: 'NT$',
            symbol: 'NT$',
            code: 'TWD',
            name_plural: 'New Taiwan dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        THB: {
            name: 'thajský baht',
            symbol_native: '฿',
            symbol: '฿',
            code: 'THB',
            name_plural: 'Thai baht',
            rounding: 0,
            decimal_digits: 2,
        },
        TOP: {
            name: 'tonžská paanga',
            symbol_native: 'T$',
            symbol: 'T$',
            code: 'TOP',
            name_plural: 'Tongan paʻanga',
            rounding: 0,
            decimal_digits: 2,
        },
        TTD: {
            name: 'trinidadský dolar',
            symbol_native: '$',
            symbol: 'TT$',
            code: 'TTD',
            name_plural: 'Trinidad and Tobago dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        TND: {
            name: 'tuniský dinár',
            symbol_native: 'د.ت.‏',
            symbol: 'DT',
            code: 'TND',
            name_plural: 'Tunisian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        TRY: {
            name: 'turecká lira',
            symbol_native: 'TL',
            symbol: 'TL',
            code: 'TRY',
            name_plural: 'Turkish Lira',
            rounding: 0,
            decimal_digits: 2,
        },
        UGX: {
            name: 'ugandský šilink',
            symbol_native: 'USh',
            symbol: 'USh',
            code: 'UGX',
            name_plural: 'Ugandan shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        UAH: {
            name: 'ukrajinská hřivna',
            symbol_native: '₴',
            symbol: '₴',
            code: 'UAH',
            name_plural: 'Ukrainian hryvnias',
            rounding: 0,
            decimal_digits: 2,
        },
        UYU: {
            name: 'uruguayské peso',
            symbol_native: '$',
            symbol: '$U',
            code: 'UYU',
            name_plural: 'Uruguayan pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        UZS: {
            name: 'uzbecký sum',
            symbol_native: 'UZS',
            symbol: 'UZS',
            code: 'UZS',
            name_plural: 'Uzbekistan som',
            rounding: 0,
            decimal_digits: 0,
        },
        VEF: {
            name: 'venezuelský bolívar',
            symbol_native: 'Bs.F.',
            symbol: 'Bs.F.',
            code: 'VEF',
            name_plural: 'Venezuelan bolívars',
            rounding: 0,
            decimal_digits: 2,
        },
        VND: {
            name: 'vietnamský dong',
            symbol_native: '₫',
            symbol: '₫',
            code: 'VND',
            name_plural: 'Vietnamese dong',
            rounding: 0,
            decimal_digits: 0,
        },
        ZMK: {
            name: 'zambijská kwacha',
            symbol_native: 'ZK',
            symbol: 'ZK',
            code: 'ZMK',
            name_plural: 'Zambian kwachas',
            rounding: 0,
            decimal_digits: 0,
        },
        ZWL: {
            name: 'zimbabwský dolar',
            symbol_native: 'ZWL$',
            symbol: 'ZWL$',
            code: 'ZWL',
            name_plural: 'Zimbabwean Dollar',
            rounding: 0,
            decimal_digits: 0,
        },
        AZN: {
            name: 'ázerbájdžánský manat',
            symbol_native: 'ман.',
            symbol: 'man.',
            code: 'AZN',
            name_plural: 'Azerbaijani manats',
            rounding: 0,
            decimal_digits: 2,
        },
        IRR: {
            name: 'íránský rijál',
            symbol_native: '﷼',
            symbol: 'IRR',
            code: 'IRR',
            name_plural: 'Iranian rials',
            rounding: 0,
            decimal_digits: 0,
        },
        CZK: {
            name: 'česká koruna',
            symbol_native: 'Kč',
            symbol: 'Kč',
            code: 'CZK',
            name_plural: 'Czech Republic korunas',
            rounding: 0,
            decimal_digits: 2,
        },
        CNY: {
            name: 'čínský jüan',
            symbol_native: 'CN¥',
            symbol: 'CN¥',
            code: 'CNY',
            name_plural: 'Chinese yuan',
            rounding: 0,
            decimal_digits: 2,
        },
        SEK: {
            name: 'švédská koruna',
            symbol_native: 'kr',
            symbol: 'Skr',
            code: 'SEK',
            name_plural: 'Swedish kronor',
            rounding: 0,
            decimal_digits: 2,
        },
        CHF: {
            name: 'švýcarský frank',
            symbol_native: 'CHF',
            symbol: 'CHF',
            code: 'CHF',
            name_plural: 'Swiss francs',
            rounding: 0.05,
            decimal_digits: 2,
        },
    },
    en: {
        AFN: {
            name: 'Afghan Afghani',
            symbol_native: '؋',
            symbol: 'Af',
            code: 'AFN',
            name_plural: 'Afghan Afghanis',
            rounding: 0,
            decimal_digits: 0,
        },
        ALL: {
            name: 'Albanian Lek',
            symbol_native: 'Lek',
            symbol: 'ALL',
            code: 'ALL',
            name_plural: 'Albanian lekë',
            rounding: 0,
            decimal_digits: 0,
        },
        DZD: {
            name: 'Algerian Dinar',
            symbol_native: 'د.ج.‏',
            symbol: 'DA',
            code: 'DZD',
            name_plural: 'Algerian dinars',
            rounding: 0,
            decimal_digits: 2,
        },
        ARS: {
            name: 'Argentine Peso',
            symbol_native: '$',
            symbol: 'AR$',
            code: 'ARS',
            name_plural: 'Argentine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        AMD: {
            name: 'Armenian Dram',
            symbol_native: 'դր.',
            symbol: 'AMD',
            code: 'AMD',
            name_plural: 'Armenian drams',
            rounding: 0,
            decimal_digits: 0,
        },
        AUD: {
            name: 'Australian Dollar',
            symbol_native: '$',
            symbol: 'AU$',
            code: 'AUD',
            name_plural: 'Australian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        AZN: {
            name: 'Azerbaijani Manat',
            symbol_native: 'ман.',
            symbol: 'man.',
            code: 'AZN',
            name_plural: 'Azerbaijani manats',
            rounding: 0,
            decimal_digits: 2,
        },
        BHD: {
            name: 'Bahraini Dinar',
            symbol_native: 'د.ب.‏',
            symbol: 'BD',
            code: 'BHD',
            name_plural: 'Bahraini dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        BDT: {
            name: 'Bangladeshi Taka',
            symbol_native: '৳',
            symbol: 'Tk',
            code: 'BDT',
            name_plural: 'Bangladeshi takas',
            rounding: 0,
            decimal_digits: 2,
        },
        BYN: {
            name: 'Belarusian Ruble',
            symbol_native: 'руб.',
            symbol: 'Br',
            code: 'BYN',
            name_plural: 'Belarusian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        BZD: {
            name: 'Belize Dollar',
            symbol_native: '$',
            symbol: 'BZ$',
            code: 'BZD',
            name_plural: 'Belize dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BOB: {
            name: 'Bolivian Boliviano',
            symbol_native: 'Bs',
            symbol: 'Bs',
            code: 'BOB',
            name_plural: 'Bolivian bolivianos',
            rounding: 0,
            decimal_digits: 2,
        },
        BAM: {
            name: 'Bosnia-Herzegovina Convertible Mark',
            symbol_native: 'KM',
            symbol: 'KM',
            code: 'BAM',
            name_plural: 'Bosnia-Herzegovina convertible marks',
            rounding: 0,
            decimal_digits: 2,
        },
        BWP: {
            name: 'Botswanan Pula',
            symbol_native: 'P',
            symbol: 'BWP',
            code: 'BWP',
            name_plural: 'Botswanan pulas',
            rounding: 0,
            decimal_digits: 2,
        },
        BRL: {
            name: 'Brazilian Real',
            symbol_native: 'R$',
            symbol: 'R$',
            code: 'BRL',
            name_plural: 'Brazilian reals',
            rounding: 0,
            decimal_digits: 2,
        },
        GBP: {
            name: 'British Pound',
            symbol_native: '£',
            symbol: '£',
            code: 'GBP',
            name_plural: 'British pounds sterling',
            rounding: 0,
            decimal_digits: 2,
        },
        BND: {
            name: 'Brunei Dollar',
            symbol_native: '$',
            symbol: 'BN$',
            code: 'BND',
            name_plural: 'Brunei dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BGN: {
            name: 'Bulgarian Lev',
            symbol_native: 'лв.',
            symbol: 'BGN',
            code: 'BGN',
            name_plural: 'Bulgarian leva',
            rounding: 0,
            decimal_digits: 2,
        },
        BIF: {
            name: 'Burundian Franc',
            symbol_native: 'FBu',
            symbol: 'FBu',
            code: 'BIF',
            name_plural: 'Burundian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        KHR: {
            name: 'Cambodian Riel',
            symbol_native: '៛',
            symbol: 'KHR',
            code: 'KHR',
            name_plural: 'Cambodian riels',
            rounding: 0,
            decimal_digits: 2,
        },
        CAD: {
            name: 'Canadian Dollar',
            symbol_native: '$',
            symbol: 'CA$',
            code: 'CAD',
            name_plural: 'Canadian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        CVE: {
            name: 'Cape Verdean Escudo',
            symbol_native: 'CV$',
            symbol: 'CV$',
            code: 'CVE',
            name_plural: 'Cape Verdean escudos',
            rounding: 0,
            decimal_digits: 2,
        },
        XAF: {
            name: 'Central African CFA Franc',
            symbol_native: 'FCFA',
            symbol: 'FCFA',
            code: 'XAF',
            name_plural: 'CFA francs BEAC',
            rounding: 0,
            decimal_digits: 0,
        },
        CLP: {
            name: 'Chilean Peso',
            symbol_native: '$',
            symbol: 'CL$',
            code: 'CLP',
            name_plural: 'Chilean pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        CNY: {
            name: 'Chinese Yuan',
            symbol_native: 'CN¥',
            symbol: 'CN¥',
            code: 'CNY',
            name_plural: 'Chinese yuan',
            rounding: 0,
            decimal_digits: 2,
        },
        COP: {
            name: 'Colombian Peso',
            symbol_native: '$',
            symbol: 'CO$',
            code: 'COP',
            name_plural: 'Colombian pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        KMF: {
            name: 'Comorian Franc',
            symbol_native: 'FC',
            symbol: 'CF',
            code: 'KMF',
            name_plural: 'Comorian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        CDF: {
            name: 'Congolese Franc',
            symbol_native: 'FrCD',
            symbol: 'CDF',
            code: 'CDF',
            name_plural: 'Congolese francs',
            rounding: 0,
            decimal_digits: 2,
        },
        CRC: {
            name: 'Costa Rican Colón',
            symbol_native: '₡',
            symbol: '₡',
            code: 'CRC',
            name_plural: 'Costa Rican colóns',
            rounding: 0,
            decimal_digits: 0,
        },
        HRK: {
            name: 'Croatian Kuna',
            symbol_native: 'kn',
            symbol: 'kn',
            code: 'HRK',
            name_plural: 'Croatian kunas',
            rounding: 0,
            decimal_digits: 2,
        },
        CZK: {
            name: 'Czech Koruna',
            symbol_native: 'Kč',
            symbol: 'Kč',
            code: 'CZK',
            name_plural: 'Czech Republic korunas',
            rounding: 0,
            decimal_digits: 2,
        },
        DKK: {
            name: 'Danish Krone',
            symbol_native: 'kr',
            symbol: 'Dkr',
            code: 'DKK',
            name_plural: 'Danish kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        DJF: {
            name: 'Djiboutian Franc',
            symbol_native: 'Fdj',
            symbol: 'Fdj',
            code: 'DJF',
            name_plural: 'Djiboutian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        DOP: {
            name: 'Dominican Peso',
            symbol_native: 'RD$',
            symbol: 'RD$',
            code: 'DOP',
            name_plural: 'Dominican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        EGP: {
            name: 'Egyptian Pound',
            symbol_native: 'ج.م.‏',
            symbol: 'EGP',
            code: 'EGP',
            name_plural: 'Egyptian pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        ERN: {
            name: 'Eritrean Nakfa',
            symbol_native: 'Nfk',
            symbol: 'Nfk',
            code: 'ERN',
            name_plural: 'Eritrean nakfas',
            rounding: 0,
            decimal_digits: 2,
        },
        EEK: {
            name: 'Estonian Kroon',
            symbol_native: 'kr',
            symbol: 'Ekr',
            code: 'EEK',
            name_plural: 'Estonian kroons',
            rounding: 0,
            decimal_digits: 2,
        },
        ETB: {
            name: 'Ethiopian Birr',
            symbol_native: 'Br',
            symbol: 'Br',
            code: 'ETB',
            name_plural: 'Ethiopian birrs',
            rounding: 0,
            decimal_digits: 2,
        },
        EUR: {
            name: 'Euro',
            symbol_native: '€',
            symbol: '€',
            code: 'EUR',
            name_plural: 'euros',
            rounding: 0,
            decimal_digits: 2,
        },
        GEL: {
            name: 'Georgian Lari',
            symbol_native: 'GEL',
            symbol: 'GEL',
            code: 'GEL',
            name_plural: 'Georgian laris',
            rounding: 0,
            decimal_digits: 2,
        },
        GHS: {
            name: 'Ghanaian Cedi',
            symbol_native: 'GH₵',
            symbol: 'GH₵',
            code: 'GHS',
            name_plural: 'Ghanaian cedis',
            rounding: 0,
            decimal_digits: 2,
        },
        GTQ: {
            name: 'Guatemalan Quetzal',
            symbol_native: 'Q',
            symbol: 'GTQ',
            code: 'GTQ',
            name_plural: 'Guatemalan quetzals',
            rounding: 0,
            decimal_digits: 2,
        },
        GNF: {
            name: 'Guinean Franc',
            symbol_native: 'FG',
            symbol: 'FG',
            code: 'GNF',
            name_plural: 'Guinean francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HNL: {
            name: 'Honduran Lempira',
            symbol_native: 'L',
            symbol: 'HNL',
            code: 'HNL',
            name_plural: 'Honduran lempiras',
            rounding: 0,
            decimal_digits: 2,
        },
        HKD: {
            name: 'Hong Kong Dollar',
            symbol_native: '$',
            symbol: 'HK$',
            code: 'HKD',
            name_plural: 'Hong Kong dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        HUF: {
            name: 'Hungarian Forint',
            symbol_native: 'Ft',
            symbol: 'Ft',
            code: 'HUF',
            name_plural: 'Hungarian forints',
            rounding: 0,
            decimal_digits: 0,
        },
        ISK: {
            name: 'Icelandic Króna',
            symbol_native: 'kr',
            symbol: 'Ikr',
            code: 'ISK',
            name_plural: 'Icelandic krónur',
            rounding: 0,
            decimal_digits: 0,
        },
        INR: {
            name: 'Indian Rupee',
            symbol_native: 'টকা',
            symbol: 'Rs',
            code: 'INR',
            name_plural: 'Indian rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        IDR: {
            name: 'Indonesian Rupiah',
            symbol_native: 'Rp',
            symbol: 'Rp',
            code: 'IDR',
            name_plural: 'Indonesian rupiahs',
            rounding: 0,
            decimal_digits: 0,
        },
        IRR: {
            name: 'Iranian Rial',
            symbol_native: '﷼',
            symbol: 'IRR',
            code: 'IRR',
            name_plural: 'Iranian rials',
            rounding: 0,
            decimal_digits: 0,
        },
        IQD: {
            name: 'Iraqi Dinar',
            symbol_native: 'د.ع.‏',
            symbol: 'IQD',
            code: 'IQD',
            name_plural: 'Iraqi dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        ILS: {
            name: 'Israeli New Shekel',
            symbol_native: '₪',
            symbol: '₪',
            code: 'ILS',
            name_plural: 'Israeli new sheqels',
            rounding: 0,
            decimal_digits: 2,
        },
        JMD: {
            name: 'Jamaican Dollar',
            symbol_native: '$',
            symbol: 'J$',
            code: 'JMD',
            name_plural: 'Jamaican dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        JPY: {
            name: 'Japanese Yen',
            symbol_native: '￥',
            symbol: '¥',
            code: 'JPY',
            name_plural: 'Japanese yen',
            rounding: 0,
            decimal_digits: 0,
        },
        JOD: {
            name: 'Jordanian Dinar',
            symbol_native: 'د.أ.‏',
            symbol: 'JD',
            code: 'JOD',
            name_plural: 'Jordanian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        KZT: {
            name: 'Kazakhstani Tenge',
            symbol_native: 'тңг.',
            symbol: 'KZT',
            code: 'KZT',
            name_plural: 'Kazakhstani tenges',
            rounding: 0,
            decimal_digits: 2,
        },
        KES: {
            name: 'Kenyan Shilling',
            symbol_native: 'Ksh',
            symbol: 'Ksh',
            code: 'KES',
            name_plural: 'Kenyan shillings',
            rounding: 0,
            decimal_digits: 2,
        },
        KWD: {
            name: 'Kuwaiti Dinar',
            symbol_native: 'د.ك.‏',
            symbol: 'KD',
            code: 'KWD',
            name_plural: 'Kuwaiti dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LVL: {
            name: 'Latvian Lats',
            symbol_native: 'Ls',
            symbol: 'Ls',
            code: 'LVL',
            name_plural: 'Latvian lati',
            rounding: 0,
            decimal_digits: 2,
        },
        LBP: {
            name: 'Lebanese Pound',
            symbol_native: 'ل.ل.‏',
            symbol: 'LB£',
            code: 'LBP',
            name_plural: 'Lebanese pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        LYD: {
            name: 'Libyan Dinar',
            symbol_native: 'د.ل.‏',
            symbol: 'LD',
            code: 'LYD',
            name_plural: 'Libyan dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LTL: {
            name: 'Lithuanian Litas',
            symbol_native: 'Lt',
            symbol: 'Lt',
            code: 'LTL',
            name_plural: 'Lithuanian litai',
            rounding: 0,
            decimal_digits: 2,
        },
        MOP: {
            name: 'Macanese Pataca',
            symbol_native: 'MOP$',
            symbol: 'MOP$',
            code: 'MOP',
            name_plural: 'Macanese patacas',
            rounding: 0,
            decimal_digits: 2,
        },
        MKD: {
            name: 'Macedonian Denar',
            symbol_native: 'MKD',
            symbol: 'MKD',
            code: 'MKD',
            name_plural: 'Macedonian denari',
            rounding: 0,
            decimal_digits: 2,
        },
        MGA: {
            name: 'Malagasy Ariary',
            symbol_native: 'MGA',
            symbol: 'MGA',
            code: 'MGA',
            name_plural: 'Malagasy Ariaries',
            rounding: 0,
            decimal_digits: 0,
        },
        MYR: {
            name: 'Malaysian Ringgit',
            symbol_native: 'RM',
            symbol: 'RM',
            code: 'MYR',
            name_plural: 'Malaysian ringgits',
            rounding: 0,
            decimal_digits: 2,
        },
        MUR: {
            name: 'Mauritian Rupee',
            symbol_native: 'MURs',
            symbol: 'MURs',
            code: 'MUR',
            name_plural: 'Mauritian rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        MXN: {
            name: 'Mexican Peso',
            symbol_native: '$',
            symbol: 'MX$',
            code: 'MXN',
            name_plural: 'Mexican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        MDL: {
            name: 'Moldovan Leu',
            symbol_native: 'MDL',
            symbol: 'MDL',
            code: 'MDL',
            name_plural: 'Moldovan lei',
            rounding: 0,
            decimal_digits: 2,
        },
        MAD: {
            name: 'Moroccan Dirham',
            symbol_native: 'د.م.‏',
            symbol: 'MAD',
            code: 'MAD',
            name_plural: 'Moroccan dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        MZN: {
            name: 'Mozambican Metical',
            symbol_native: 'MTn',
            symbol: 'MTn',
            code: 'MZN',
            name_plural: 'Mozambican meticals',
            rounding: 0,
            decimal_digits: 2,
        },
        MMK: {
            name: 'Myanmar Kyat',
            symbol_native: 'K',
            symbol: 'MMK',
            code: 'MMK',
            name_plural: 'Myanma kyats',
            rounding: 0,
            decimal_digits: 0,
        },
        NAD: {
            name: 'Namibian Dollar',
            symbol_native: 'N$',
            symbol: 'N$',
            code: 'NAD',
            name_plural: 'Namibian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NPR: {
            name: 'Nepalese Rupee',
            symbol_native: 'नेरू',
            symbol: 'NPRs',
            code: 'NPR',
            name_plural: 'Nepalese rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        TWD: {
            name: 'New Taiwan Dollar',
            symbol_native: 'NT$',
            symbol: 'NT$',
            code: 'TWD',
            name_plural: 'New Taiwan dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NZD: {
            name: 'New Zealand Dollar',
            symbol_native: '$',
            symbol: 'NZ$',
            code: 'NZD',
            name_plural: 'New Zealand dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NIO: {
            name: 'Nicaraguan Córdoba',
            symbol_native: 'C$',
            symbol: 'C$',
            code: 'NIO',
            name_plural: 'Nicaraguan córdobas',
            rounding: 0,
            decimal_digits: 2,
        },
        NGN: {
            name: 'Nigerian Naira',
            symbol_native: '₦',
            symbol: '₦',
            code: 'NGN',
            name_plural: 'Nigerian nairas',
            rounding: 0,
            decimal_digits: 2,
        },
        NOK: {
            name: 'Norwegian Krone',
            symbol_native: 'kr',
            symbol: 'Nkr',
            code: 'NOK',
            name_plural: 'Norwegian kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        OMR: {
            name: 'Omani Rial',
            symbol_native: 'ر.ع.‏',
            symbol: 'OMR',
            code: 'OMR',
            name_plural: 'Omani rials',
            rounding: 0,
            decimal_digits: 3,
        },
        PKR: {
            name: 'Pakistani Rupee',
            symbol_native: '₨',
            symbol: 'PKRs',
            code: 'PKR',
            name_plural: 'Pakistani rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        PAB: {
            name: 'Panamanian Balboa',
            symbol_native: 'B/.',
            symbol: 'B/.',
            code: 'PAB',
            name_plural: 'Panamanian balboas',
            rounding: 0,
            decimal_digits: 2,
        },
        PYG: {
            name: 'Paraguayan Guarani',
            symbol_native: '₲',
            symbol: '₲',
            code: 'PYG',
            name_plural: 'Paraguayan guaranis',
            rounding: 0,
            decimal_digits: 0,
        },
        PEN: {
            name: 'Peruvian Sol',
            symbol_native: 'S/.',
            symbol: 'S/.',
            code: 'PEN',
            name_plural: 'Peruvian nuevos soles',
            rounding: 0,
            decimal_digits: 2,
        },
        PHP: {
            name: 'Philippine Peso',
            symbol_native: '₱',
            symbol: '₱',
            code: 'PHP',
            name_plural: 'Philippine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        PLN: {
            name: 'Polish Zloty',
            symbol_native: 'zł',
            symbol: 'zł',
            code: 'PLN',
            name_plural: 'Polish zlotys',
            rounding: 0,
            decimal_digits: 2,
        },
        QAR: {
            name: 'Qatari Rial',
            symbol_native: 'ر.ق.‏',
            symbol: 'QR',
            code: 'QAR',
            name_plural: 'Qatari rials',
            rounding: 0,
            decimal_digits: 2,
        },
        RON: {
            name: 'Romanian Leu',
            symbol_native: 'RON',
            symbol: 'RON',
            code: 'RON',
            name_plural: 'Romanian lei',
            rounding: 0,
            decimal_digits: 2,
        },
        RUB: {
            name: 'Russian Ruble',
            symbol_native: '₽',
            symbol: 'RUB',
            code: 'RUB',
            name_plural: 'Russian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        RWF: {
            name: 'Rwandan Franc',
            symbol_native: 'FR',
            symbol: 'RWF',
            code: 'RWF',
            name_plural: 'Rwandan francs',
            rounding: 0,
            decimal_digits: 0,
        },
        SAR: {
            name: 'Saudi Riyal',
            symbol_native: 'ر.س.‏',
            symbol: 'SR',
            code: 'SAR',
            name_plural: 'Saudi riyals',
            rounding: 0,
            decimal_digits: 2,
        },
        RSD: {
            name: 'Serbian Dinar',
            symbol_native: 'дин.',
            symbol: 'din.',
            code: 'RSD',
            name_plural: 'Serbian dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        SGD: {
            name: 'Singapore Dollar',
            symbol_native: '$',
            symbol: 'S$',
            code: 'SGD',
            name_plural: 'Singapore dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        SOS: {
            name: 'Somali Shilling',
            symbol_native: 'Ssh',
            symbol: 'Ssh',
            code: 'SOS',
            name_plural: 'Somali shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        ZAR: {
            name: 'South African Rand',
            symbol_native: 'R',
            symbol: 'R',
            code: 'ZAR',
            name_plural: 'South African rand',
            rounding: 0,
            decimal_digits: 2,
        },
        KRW: {
            name: 'South Korean Won',
            symbol_native: '₩',
            symbol: '₩',
            code: 'KRW',
            name_plural: 'South Korean won',
            rounding: 0,
            decimal_digits: 0,
        },
        LKR: {
            name: 'Sri Lankan Rupee',
            symbol_native: 'SL Re',
            symbol: 'SLRs',
            code: 'LKR',
            name_plural: 'Sri Lankan rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        SDG: {
            name: 'Sudanese Pound',
            symbol_native: 'SDG',
            symbol: 'SDG',
            code: 'SDG',
            name_plural: 'Sudanese pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        SEK: {
            name: 'Swedish Krona',
            symbol_native: 'kr',
            symbol: 'Skr',
            code: 'SEK',
            name_plural: 'Swedish kronor',
            rounding: 0,
            decimal_digits: 2,
        },
        CHF: {
            name: 'Swiss Franc',
            symbol_native: 'CHF',
            symbol: 'CHF',
            code: 'CHF',
            name_plural: 'Swiss francs',
            rounding: 0.05,
            decimal_digits: 2,
        },
        SYP: {
            name: 'Syrian Pound',
            symbol_native: 'ل.س.‏',
            symbol: 'SY£',
            code: 'SYP',
            name_plural: 'Syrian pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        TZS: {
            name: 'Tanzanian Shilling',
            symbol_native: 'TSh',
            symbol: 'TSh',
            code: 'TZS',
            name_plural: 'Tanzanian shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        THB: {
            name: 'Thai Baht',
            symbol_native: '฿',
            symbol: '฿',
            code: 'THB',
            name_plural: 'Thai baht',
            rounding: 0,
            decimal_digits: 2,
        },
        TOP: {
            name: 'Tongan Paʻanga',
            symbol_native: 'T$',
            symbol: 'T$',
            code: 'TOP',
            name_plural: 'Tongan paʻanga',
            rounding: 0,
            decimal_digits: 2,
        },
        TTD: {
            name: 'Trinidad & Tobago Dollar',
            symbol_native: '$',
            symbol: 'TT$',
            code: 'TTD',
            name_plural: 'Trinidad and Tobago dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        TND: {
            name: 'Tunisian Dinar',
            symbol_native: 'د.ت.‏',
            symbol: 'DT',
            code: 'TND',
            name_plural: 'Tunisian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        TRY: {
            name: 'Turkish Lira',
            symbol_native: 'TL',
            symbol: 'TL',
            code: 'TRY',
            name_plural: 'Turkish Lira',
            rounding: 0,
            decimal_digits: 2,
        },
        USD: {
            name: 'US Dollar',
            symbol_native: '$',
            symbol: '$',
            code: 'USD',
            name_plural: 'US dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        UGX: {
            name: 'Ugandan Shilling',
            symbol_native: 'USh',
            symbol: 'USh',
            code: 'UGX',
            name_plural: 'Ugandan shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        UAH: {
            name: 'Ukrainian Hryvnia',
            symbol_native: '₴',
            symbol: '₴',
            code: 'UAH',
            name_plural: 'Ukrainian hryvnias',
            rounding: 0,
            decimal_digits: 2,
        },
        AED: {
            name: 'United Arab Emirates Dirham',
            symbol_native: 'د.إ.‏',
            symbol: 'AED',
            code: 'AED',
            name_plural: 'UAE dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        UYU: {
            name: 'Uruguayan Peso',
            symbol_native: '$',
            symbol: '$U',
            code: 'UYU',
            name_plural: 'Uruguayan pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        UZS: {
            name: 'Uzbekistani Som',
            symbol_native: 'UZS',
            symbol: 'UZS',
            code: 'UZS',
            name_plural: 'Uzbekistan som',
            rounding: 0,
            decimal_digits: 0,
        },
        VEF: {
            name: 'Venezuelan Bolívar',
            symbol_native: 'Bs.F.',
            symbol: 'Bs.F.',
            code: 'VEF',
            name_plural: 'Venezuelan bolívars',
            rounding: 0,
            decimal_digits: 2,
        },
        VND: {
            name: 'Vietnamese Dong',
            symbol_native: '₫',
            symbol: '₫',
            code: 'VND',
            name_plural: 'Vietnamese dong',
            rounding: 0,
            decimal_digits: 0,
        },
        XOF: {
            name: 'West African CFA Franc',
            symbol_native: 'CFA',
            symbol: 'CFA',
            code: 'XOF',
            name_plural: 'CFA francs BCEAO',
            rounding: 0,
            decimal_digits: 0,
        },
        YER: {
            name: 'Yemeni Rial',
            symbol_native: 'ر.ي.‏',
            symbol: 'YR',
            code: 'YER',
            name_plural: 'Yemeni rials',
            rounding: 0,
            decimal_digits: 0,
        },
        ZMK: {
            name: 'Zambian Kwacha',
            symbol_native: 'ZK',
            symbol: 'ZK',
            code: 'ZMK',
            name_plural: 'Zambian kwachas',
            rounding: 0,
            decimal_digits: 0,
        },
        ZWL: {
            name: 'Zimbabwean Dollar',
            symbol_native: 'ZWL$',
            symbol: 'ZWL$',
            code: 'ZWL',
            name_plural: 'Zimbabwean Dollar',
            rounding: 0,
            decimal_digits: 0,
        },
    },
    ru: {
        AUD: {
            name: 'Австралийский доллар',
            symbol_native: '$',
            symbol: 'AU$',
            code: 'AUD',
            name_plural: 'Australian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        AZN: {
            name: 'Азербайджанский манат',
            symbol_native: 'ман.',
            symbol: 'man.',
            code: 'AZN',
            name_plural: 'Azerbaijani manats',
            rounding: 0,
            decimal_digits: 2,
        },
        ALL: {
            name: 'Албанский лек',
            symbol_native: 'Lek',
            symbol: 'ALL',
            code: 'ALL',
            name_plural: 'Albanian lekë',
            rounding: 0,
            decimal_digits: 0,
        },
        DZD: {
            name: 'Алжирский динар',
            symbol_native: 'د.ج.‏',
            symbol: 'DA',
            code: 'DZD',
            name_plural: 'Algerian dinars',
            rounding: 0,
            decimal_digits: 2,
        },
        ARS: {
            name: 'Аргентинское песо',
            symbol_native: '$',
            symbol: 'AR$',
            code: 'ARS',
            name_plural: 'Argentine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        AMD: {
            name: 'Армянский драм',
            symbol_native: 'դր.',
            symbol: 'AMD',
            code: 'AMD',
            name_plural: 'Armenian drams',
            rounding: 0,
            decimal_digits: 0,
        },
        AFN: {
            name: 'Афгани',
            symbol_native: '؋',
            symbol: 'Af',
            code: 'AFN',
            name_plural: 'Afghan Afghanis',
            rounding: 0,
            decimal_digits: 0,
        },
        BDT: {
            name: 'Бангладешская така',
            symbol_native: '৳',
            symbol: 'Tk',
            code: 'BDT',
            name_plural: 'Bangladeshi takas',
            rounding: 0,
            decimal_digits: 2,
        },
        BHD: {
            name: 'Бахрейнский динар',
            symbol_native: 'د.ب.‏',
            symbol: 'BD',
            code: 'BHD',
            name_plural: 'Bahraini dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        BZD: {
            name: 'Белизский доллар',
            symbol_native: '$',
            symbol: 'BZ$',
            code: 'BZD',
            name_plural: 'Belize dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BYN: {
            name: 'Белорусский рубль',
            symbol_native: 'руб.',
            symbol: 'Br',
            code: 'BYN',
            name_plural: 'Belarusian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        BGN: {
            name: 'Болгарский лев',
            symbol_native: 'лв.',
            symbol: 'BGN',
            code: 'BGN',
            name_plural: 'Bulgarian leva',
            rounding: 0,
            decimal_digits: 2,
        },
        BOB: {
            name: 'Боливийский боливиано',
            symbol_native: 'Bs',
            symbol: 'Bs',
            code: 'BOB',
            name_plural: 'Bolivian bolivianos',
            rounding: 0,
            decimal_digits: 2,
        },
        BWP: {
            name: 'Ботсванская пула',
            symbol_native: 'P',
            symbol: 'BWP',
            code: 'BWP',
            name_plural: 'Botswanan pulas',
            rounding: 0,
            decimal_digits: 2,
        },
        BRL: {
            name: 'Бразильский реал',
            symbol_native: 'R$',
            symbol: 'R$',
            code: 'BRL',
            name_plural: 'Brazilian reals',
            rounding: 0,
            decimal_digits: 2,
        },
        GBP: {
            name: 'Британский фунт стерлингов',
            symbol_native: '£',
            symbol: '£',
            code: 'GBP',
            name_plural: 'British pounds sterling',
            rounding: 0,
            decimal_digits: 2,
        },
        BND: {
            name: 'Брунейский доллар',
            symbol_native: '$',
            symbol: 'BN$',
            code: 'BND',
            name_plural: 'Brunei dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BIF: {
            name: 'Бурундийский франк',
            symbol_native: 'FBu',
            symbol: 'FBu',
            code: 'BIF',
            name_plural: 'Burundian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HUF: {
            name: 'Венгерский форинт',
            symbol_native: 'Ft',
            symbol: 'Ft',
            code: 'HUF',
            name_plural: 'Hungarian forints',
            rounding: 0,
            decimal_digits: 0,
        },
        VEF: {
            name: 'Венесуэльский боливар',
            symbol_native: 'Bs.F.',
            symbol: 'Bs.F.',
            code: 'VEF',
            name_plural: 'Venezuelan bolívars',
            rounding: 0,
            decimal_digits: 2,
        },
        VND: {
            name: 'Вьетнамский донг',
            symbol_native: '₫',
            symbol: '₫',
            code: 'VND',
            name_plural: 'Vietnamese dong',
            rounding: 0,
            decimal_digits: 0,
        },
        GHS: {
            name: 'Ганский седи',
            symbol_native: 'GH₵',
            symbol: 'GH₵',
            code: 'GHS',
            name_plural: 'Ghanaian cedis',
            rounding: 0,
            decimal_digits: 2,
        },
        GTQ: {
            name: 'Гватемальский кетсаль',
            symbol_native: 'Q',
            symbol: 'GTQ',
            code: 'GTQ',
            name_plural: 'Guatemalan quetzals',
            rounding: 0,
            decimal_digits: 2,
        },
        GNF: {
            name: 'Гвинейский франк',
            symbol_native: 'FG',
            symbol: 'FG',
            code: 'GNF',
            name_plural: 'Guinean francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HNL: {
            name: 'Гондурасская лемпира',
            symbol_native: 'L',
            symbol: 'HNL',
            code: 'HNL',
            name_plural: 'Honduran lempiras',
            rounding: 0,
            decimal_digits: 2,
        },
        HKD: {
            name: 'Гонконгский доллар',
            symbol_native: '$',
            symbol: 'HK$',
            code: 'HKD',
            name_plural: 'Hong Kong dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        GEL: {
            name: 'Грузинский лари',
            symbol_native: 'GEL',
            symbol: 'GEL',
            code: 'GEL',
            name_plural: 'Georgian laris',
            rounding: 0,
            decimal_digits: 2,
        },
        DKK: {
            name: 'Датская крона',
            symbol_native: 'kr',
            symbol: 'Dkr',
            code: 'DKK',
            name_plural: 'Danish kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        AED: {
            name: 'Дирхам ОАЭ',
            symbol_native: 'د.إ.‏',
            symbol: 'AED',
            code: 'AED',
            name_plural: 'UAE dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        ZWL: {
            name: 'Доллар Зимбабве',
            symbol_native: 'ZWL$',
            symbol: 'ZWL$',
            code: 'ZWL',
            name_plural: 'Zimbabwean Dollar',
            rounding: 0,
            decimal_digits: 0,
        },
        NAD: {
            name: 'Доллар Намибии',
            symbol_native: 'N$',
            symbol: 'N$',
            code: 'NAD',
            name_plural: 'Namibian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        USD: {
            name: 'Доллар США',
            symbol_native: '$',
            symbol: '$',
            code: 'USD',
            name_plural: 'US dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        TTD: {
            name: 'Доллар Тринидада и Тобаго',
            symbol_native: '$',
            symbol: 'TT$',
            code: 'TTD',
            name_plural: 'Trinidad and Tobago dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        DOP: {
            name: 'Доминиканское песо',
            symbol_native: 'RD$',
            symbol: 'RD$',
            code: 'DOP',
            name_plural: 'Dominican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        EUR: {
            name: 'Евро',
            symbol_native: '€',
            symbol: '€',
            code: 'EUR',
            name_plural: 'euros',
            rounding: 0,
            decimal_digits: 2,
        },
        EGP: {
            name: 'Египетский фунт',
            symbol_native: 'ج.م.‏',
            symbol: 'EGP',
            code: 'EGP',
            name_plural: 'Egyptian pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        INR: {
            name: 'Индийская рупия',
            symbol_native: 'টকা',
            symbol: 'Rs',
            code: 'INR',
            name_plural: 'Indian rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        IDR: {
            name: 'Индонезийская рупия',
            symbol_native: 'Rp',
            symbol: 'Rp',
            code: 'IDR',
            name_plural: 'Indonesian rupiahs',
            rounding: 0,
            decimal_digits: 0,
        },
        JOD: {
            name: 'Иорданский динар',
            symbol_native: 'د.أ.‏',
            symbol: 'JD',
            code: 'JOD',
            name_plural: 'Jordanian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        IQD: {
            name: 'Иракский динар',
            symbol_native: 'د.ع.‏',
            symbol: 'IQD',
            code: 'IQD',
            name_plural: 'Iraqi dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        IRR: {
            name: 'Иранский риал',
            symbol_native: '﷼',
            symbol: 'IRR',
            code: 'IRR',
            name_plural: 'Iranian rials',
            rounding: 0,
            decimal_digits: 0,
        },
        ISK: {
            name: 'Исландская крона',
            symbol_native: 'kr',
            symbol: 'Ikr',
            code: 'ISK',
            name_plural: 'Icelandic krónur',
            rounding: 0,
            decimal_digits: 0,
        },
        YER: {
            name: 'Йеменский риал',
            symbol_native: 'ر.ي.‏',
            symbol: 'YR',
            code: 'YER',
            name_plural: 'Yemeni rials',
            rounding: 0,
            decimal_digits: 0,
        },
        KZT: {
            name: 'Казахский тенге',
            symbol_native: 'тңг.',
            symbol: 'KZT',
            code: 'KZT',
            name_plural: 'Kazakhstani tenges',
            rounding: 0,
            decimal_digits: 2,
        },
        KHR: {
            name: 'Камбоджийский риель',
            symbol_native: '៛',
            symbol: 'KHR',
            code: 'KHR',
            name_plural: 'Cambodian riels',
            rounding: 0,
            decimal_digits: 2,
        },
        CAD: {
            name: 'Канадский доллар',
            symbol_native: '$',
            symbol: 'CA$',
            code: 'CAD',
            name_plural: 'Canadian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        QAR: {
            name: 'Катарский риал',
            symbol_native: 'ر.ق.‏',
            symbol: 'QR',
            code: 'QAR',
            name_plural: 'Qatari rials',
            rounding: 0,
            decimal_digits: 2,
        },
        ZMK: {
            name: 'Квача',
            symbol_native: 'ZK',
            symbol: 'ZK',
            code: 'ZMK',
            name_plural: 'Zambian kwachas',
            rounding: 0,
            decimal_digits: 0,
        },
        KES: {
            name: 'Кенийский шиллинг',
            symbol_native: 'Ksh',
            symbol: 'Ksh',
            code: 'KES',
            name_plural: 'Kenyan shillings',
            rounding: 0,
            decimal_digits: 2,
        },
        CNY: {
            name: 'Китайский юань',
            symbol_native: 'CN¥',
            symbol: 'CN¥',
            code: 'CNY',
            name_plural: 'Chinese yuan',
            rounding: 0,
            decimal_digits: 2,
        },
        COP: {
            name: 'Колумбийское песо',
            symbol_native: '$',
            symbol: 'CO$',
            code: 'COP',
            name_plural: 'Colombian pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        BAM: {
            name: 'Конвертируемая марка Боснии и Герцеговины',
            symbol_native: 'KM',
            symbol: 'KM',
            code: 'BAM',
            name_plural: 'Bosnia-Herzegovina convertible marks',
            rounding: 0,
            decimal_digits: 2,
        },
        CDF: {
            name: 'Конголезский франк',
            symbol_native: 'FrCD',
            symbol: 'CDF',
            code: 'CDF',
            name_plural: 'Congolese francs',
            rounding: 0,
            decimal_digits: 2,
        },
        CRC: {
            name: 'Костариканский колон',
            symbol_native: '₡',
            symbol: '₡',
            code: 'CRC',
            name_plural: 'Costa Rican colóns',
            rounding: 0,
            decimal_digits: 0,
        },
        KWD: {
            name: 'Кувейтский динар',
            symbol_native: 'د.ك.‏',
            symbol: 'KD',
            code: 'KWD',
            name_plural: 'Kuwaiti dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LVL: {
            name: 'Латвийский лат',
            symbol_native: 'Ls',
            symbol: 'Ls',
            code: 'LVL',
            name_plural: 'Latvian lati',
            rounding: 0,
            decimal_digits: 2,
        },
        LBP: {
            name: 'Ливанский фунт',
            symbol_native: 'ل.ل.‏',
            symbol: 'LB£',
            code: 'LBP',
            name_plural: 'Lebanese pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        LYD: {
            name: 'Ливийский динар',
            symbol_native: 'د.ل.‏',
            symbol: 'LD',
            code: 'LYD',
            name_plural: 'Libyan dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LTL: {
            name: 'Литовский лит',
            symbol_native: 'Lt',
            symbol: 'Lt',
            code: 'LTL',
            name_plural: 'Lithuanian litai',
            rounding: 0,
            decimal_digits: 2,
        },
        MUR: {
            name: 'Маврикийская рупия',
            symbol_native: 'MURs',
            symbol: 'MURs',
            code: 'MUR',
            name_plural: 'Mauritian rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        MKD: {
            name: 'Македонский денар',
            symbol_native: 'MKD',
            symbol: 'MKD',
            code: 'MKD',
            name_plural: 'Macedonian denari',
            rounding: 0,
            decimal_digits: 2,
        },
        MGA: {
            name: 'Малагасийский ариари',
            symbol_native: 'MGA',
            symbol: 'MGA',
            code: 'MGA',
            name_plural: 'Malagasy Ariaries',
            rounding: 0,
            decimal_digits: 0,
        },
        MYR: {
            name: 'Малайзийский ринггит',
            symbol_native: 'RM',
            symbol: 'RM',
            code: 'MYR',
            name_plural: 'Malaysian ringgits',
            rounding: 0,
            decimal_digits: 2,
        },
        MAD: {
            name: 'Марокканский дирхам',
            symbol_native: 'د.م.‏',
            symbol: 'MAD',
            code: 'MAD',
            name_plural: 'Moroccan dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        MXN: {
            name: 'Мексиканское песо',
            symbol_native: '$',
            symbol: 'MX$',
            code: 'MXN',
            name_plural: 'Mexican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        MZN: {
            name: 'Мозамбикский метикал',
            symbol_native: 'MTn',
            symbol: 'MTn',
            code: 'MZN',
            name_plural: 'Mozambican meticals',
            rounding: 0,
            decimal_digits: 2,
        },
        MDL: {
            name: 'Молдавский лей',
            symbol_native: 'MDL',
            symbol: 'MDL',
            code: 'MDL',
            name_plural: 'Moldovan lei',
            rounding: 0,
            decimal_digits: 2,
        },
        MMK: {
            name: 'Мьянманский кьят',
            symbol_native: 'K',
            symbol: 'MMK',
            code: 'MMK',
            name_plural: 'Myanma kyats',
            rounding: 0,
            decimal_digits: 0,
        },
        ERN: {
            name: 'Накфа',
            symbol_native: 'Nfk',
            symbol: 'Nfk',
            code: 'ERN',
            name_plural: 'Eritrean nakfas',
            rounding: 0,
            decimal_digits: 2,
        },
        NPR: {
            name: 'Непальская рупия',
            symbol_native: 'नेरू',
            symbol: 'NPRs',
            code: 'NPR',
            name_plural: 'Nepalese rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        NGN: {
            name: 'Нигерийская найра',
            symbol_native: '₦',
            symbol: '₦',
            code: 'NGN',
            name_plural: 'Nigerian nairas',
            rounding: 0,
            decimal_digits: 2,
        },
        NIO: {
            name: 'Никарагуанская кордоба',
            symbol_native: 'C$',
            symbol: 'C$',
            code: 'NIO',
            name_plural: 'Nicaraguan córdobas',
            rounding: 0,
            decimal_digits: 2,
        },
        NZD: {
            name: 'Новозеландский доллар',
            symbol_native: '$',
            symbol: 'NZ$',
            code: 'NZD',
            name_plural: 'New Zealand dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        ILS: {
            name: 'Новый израильский шекель',
            symbol_native: '₪',
            symbol: '₪',
            code: 'ILS',
            name_plural: 'Israeli new sheqels',
            rounding: 0,
            decimal_digits: 2,
        },
        TWD: {
            name: 'Новый тайваньский доллар',
            symbol_native: 'NT$',
            symbol: 'NT$',
            code: 'TWD',
            name_plural: 'New Taiwan dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NOK: {
            name: 'Норвежская крона',
            symbol_native: 'kr',
            symbol: 'Nkr',
            code: 'NOK',
            name_plural: 'Norwegian kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        OMR: {
            name: 'Оманский риал',
            symbol_native: 'ر.ع.‏',
            symbol: 'OMR',
            code: 'OMR',
            name_plural: 'Omani rials',
            rounding: 0,
            decimal_digits: 3,
        },
        PKR: {
            name: 'Пакистанская рупия',
            symbol_native: '₨',
            symbol: 'PKRs',
            code: 'PKR',
            name_plural: 'Pakistani rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        PAB: {
            name: 'Панамское бальбоа',
            symbol_native: 'B/.',
            symbol: 'B/.',
            code: 'PAB',
            name_plural: 'Panamanian balboas',
            rounding: 0,
            decimal_digits: 2,
        },
        PYG: {
            name: 'Парагвайский гуарани',
            symbol_native: '₲',
            symbol: '₲',
            code: 'PYG',
            name_plural: 'Paraguayan guaranis',
            rounding: 0,
            decimal_digits: 0,
        },
        MOP: {
            name: 'Патака Макао',
            symbol_native: 'MOP$',
            symbol: 'MOP$',
            code: 'MOP',
            name_plural: 'Macanese patacas',
            rounding: 0,
            decimal_digits: 2,
        },
        PEN: {
            name: 'Перуанский соль',
            symbol_native: 'S/.',
            symbol: 'S/.',
            code: 'PEN',
            name_plural: 'Peruvian nuevos soles',
            rounding: 0,
            decimal_digits: 2,
        },
        PLN: {
            name: 'Польский злотый',
            symbol_native: 'zł',
            symbol: 'zł',
            code: 'PLN',
            name_plural: 'Polish zlotys',
            rounding: 0,
            decimal_digits: 2,
        },
        RUB: {
            name: 'Российский рубль',
            symbol_native: '₽',
            symbol: 'RUB',
            code: 'RUB',
            name_plural: 'Russian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        RON: {
            name: 'Румынский лей',
            symbol_native: 'RON',
            symbol: 'RON',
            code: 'RON',
            name_plural: 'Romanian lei',
            rounding: 0,
            decimal_digits: 2,
        },
        SAR: {
            name: 'Саудовский риял',
            symbol_native: 'ر.س.‏',
            symbol: 'SR',
            code: 'SAR',
            name_plural: 'Saudi riyals',
            rounding: 0,
            decimal_digits: 2,
        },
        RSD: {
            name: 'Сербский динар',
            symbol_native: 'дин.',
            symbol: 'din.',
            code: 'RSD',
            name_plural: 'Serbian dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        SGD: {
            name: 'Сингапурский доллар',
            symbol_native: '$',
            symbol: 'S$',
            code: 'SGD',
            name_plural: 'Singapore dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        SYP: {
            name: 'Сирийский фунт',
            symbol_native: 'ل.س.‏',
            symbol: 'SY£',
            code: 'SYP',
            name_plural: 'Syrian pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        SOS: {
            name: 'Сомалийский шиллинг',
            symbol_native: 'Ssh',
            symbol: 'Ssh',
            code: 'SOS',
            name_plural: 'Somali shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        SDG: {
            name: 'Суданский фунт',
            symbol_native: 'SDG',
            symbol: 'SDG',
            code: 'SDG',
            name_plural: 'Sudanese pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        THB: {
            name: 'Таиландский бат',
            symbol_native: '฿',
            symbol: '฿',
            code: 'THB',
            name_plural: 'Thai baht',
            rounding: 0,
            decimal_digits: 2,
        },
        TZS: {
            name: 'Танзанийский шиллинг',
            symbol_native: 'TSh',
            symbol: 'TSh',
            code: 'TZS',
            name_plural: 'Tanzanian shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        TOP: {
            name: 'Тонганская паанга',
            symbol_native: 'T$',
            symbol: 'T$',
            code: 'TOP',
            name_plural: 'Tongan paʻanga',
            rounding: 0,
            decimal_digits: 2,
        },
        TND: {
            name: 'Тунисский динар',
            symbol_native: 'د.ت.‏',
            symbol: 'DT',
            code: 'TND',
            name_plural: 'Tunisian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        TRY: {
            name: 'Турецкая лира',
            symbol_native: 'TL',
            symbol: 'TL',
            code: 'TRY',
            name_plural: 'Turkish Lira',
            rounding: 0,
            decimal_digits: 2,
        },
        UGX: {
            name: 'Угандийский шиллинг',
            symbol_native: 'USh',
            symbol: 'USh',
            code: 'UGX',
            name_plural: 'Ugandan shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        UZS: {
            name: 'Узбекский сум',
            symbol_native: 'UZS',
            symbol: 'UZS',
            code: 'UZS',
            name_plural: 'Uzbekistan som',
            rounding: 0,
            decimal_digits: 0,
        },
        UAH: {
            name: 'Украинская гривна',
            symbol_native: '₴',
            symbol: '₴',
            code: 'UAH',
            name_plural: 'Ukrainian hryvnias',
            rounding: 0,
            decimal_digits: 2,
        },
        UYU: {
            name: 'Уругвайское песо',
            symbol_native: '$',
            symbol: '$U',
            code: 'UYU',
            name_plural: 'Uruguayan pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        PHP: {
            name: 'Филиппинское песо',
            symbol_native: '₱',
            symbol: '₱',
            code: 'PHP',
            name_plural: 'Philippine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        DJF: {
            name: 'Франк Джибути',
            symbol_native: 'Fdj',
            symbol: 'Fdj',
            code: 'DJF',
            name_plural: 'Djiboutian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        XAF: {
            name: 'Франк КФА ВЕАС',
            symbol_native: 'FCFA',
            symbol: 'FCFA',
            code: 'XAF',
            name_plural: 'CFA francs BEAC',
            rounding: 0,
            decimal_digits: 0,
        },
        XOF: {
            name: 'Франк КФА ВСЕАО',
            symbol_native: 'CFA',
            symbol: 'CFA',
            code: 'XOF',
            name_plural: 'CFA francs BCEAO',
            rounding: 0,
            decimal_digits: 0,
        },
        KMF: {
            name: 'Франк Коморских Островов',
            symbol_native: 'FC',
            symbol: 'CF',
            code: 'KMF',
            name_plural: 'Comorian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        RWF: {
            name: 'Франк Руанды',
            symbol_native: 'FR',
            symbol: 'RWF',
            code: 'RWF',
            name_plural: 'Rwandan francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HRK: {
            name: 'Хорватская куна',
            symbol_native: 'kn',
            symbol: 'kn',
            code: 'HRK',
            name_plural: 'Croatian kunas',
            rounding: 0,
            decimal_digits: 2,
        },
        CZK: {
            name: 'Чешская крона',
            symbol_native: 'Kč',
            symbol: 'Kč',
            code: 'CZK',
            name_plural: 'Czech Republic korunas',
            rounding: 0,
            decimal_digits: 2,
        },
        CLP: {
            name: 'Чилийское песо',
            symbol_native: '$',
            symbol: 'CL$',
            code: 'CLP',
            name_plural: 'Chilean pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        SEK: {
            name: 'Шведская крона',
            symbol_native: 'kr',
            symbol: 'Skr',
            code: 'SEK',
            name_plural: 'Swedish kronor',
            rounding: 0,
            decimal_digits: 2,
        },
        CHF: {
            name: 'Швейцарский франк',
            symbol_native: 'CHF',
            symbol: 'CHF',
            code: 'CHF',
            name_plural: 'Swiss francs',
            rounding: 0.05,
            decimal_digits: 2,
        },
        LKR: {
            name: 'Шри-ланкийская рупия',
            symbol_native: 'SL Re',
            symbol: 'SLRs',
            code: 'LKR',
            name_plural: 'Sri Lankan rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        CVE: {
            name: 'Эскудо Кабо-Верде',
            symbol_native: 'CV$',
            symbol: 'CV$',
            code: 'CVE',
            name_plural: 'Cape Verdean escudos',
            rounding: 0,
            decimal_digits: 2,
        },
        EEK: {
            name: 'Эстонская крона',
            symbol_native: 'kr',
            symbol: 'Ekr',
            code: 'EEK',
            name_plural: 'Estonian kroons',
            rounding: 0,
            decimal_digits: 2,
        },
        ETB: {
            name: 'Эфиопский быр',
            symbol_native: 'Br',
            symbol: 'Br',
            code: 'ETB',
            name_plural: 'Ethiopian birrs',
            rounding: 0,
            decimal_digits: 2,
        },
        ZAR: {
            name: 'Южноафриканский рэнд',
            symbol_native: 'R',
            symbol: 'R',
            code: 'ZAR',
            name_plural: 'South African rand',
            rounding: 0,
            decimal_digits: 2,
        },
        KRW: {
            name: 'Южнокорейская вона',
            symbol_native: '₩',
            symbol: '₩',
            code: 'KRW',
            name_plural: 'South Korean won',
            rounding: 0,
            decimal_digits: 0,
        },
        JMD: {
            name: 'Ямайский доллар',
            symbol_native: '$',
            symbol: 'J$',
            code: 'JMD',
            name_plural: 'Jamaican dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        JPY: {
            name: 'Японская иена',
            symbol_native: '￥',
            symbol: '¥',
            code: 'JPY',
            name_plural: 'Japanese yen',
            rounding: 0,
            decimal_digits: 0,
        },
    },
    sk: {
        EEK: {
            name: 'Estónska kroon',
            symbol_native: 'kr',
            symbol: 'Ekr',
            code: 'EEK',
            name_plural: 'Estonian kroons',
            rounding: 0,
            decimal_digits: 2,
        },
        LVL: {
            name: 'Lotyšský lat',
            symbol_native: 'Ls',
            symbol: 'Ls',
            code: 'LVL',
            name_plural: 'Latvian lati',
            rounding: 0,
            decimal_digits: 2,
        },
        AED: {
            name: 'SAE dirham',
            symbol_native: 'د.إ.‏',
            symbol: 'AED',
            code: 'AED',
            name_plural: 'UAE dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        ZMK: {
            name: 'Zambijská kwacha',
            symbol_native: 'ZK',
            symbol: 'ZK',
            code: 'ZMK',
            name_plural: 'Zambian kwachas',
            rounding: 0,
            decimal_digits: 0,
        },
        ZWL: {
            name: 'Zimbabwiansky dolár',
            symbol_native: 'ZWL$',
            symbol: 'ZWL$',
            code: 'ZWL',
            name_plural: 'Zimbabwean Dollar',
            rounding: 0,
            decimal_digits: 0,
        },
        AFN: {
            name: 'afganský afgání',
            symbol_native: '؋',
            symbol: 'Af',
            code: 'AFN',
            name_plural: 'Afghan Afghanis',
            rounding: 0,
            decimal_digits: 0,
        },
        ALL: {
            name: 'albánsky lek',
            symbol_native: 'Lek',
            symbol: 'ALL',
            code: 'ALL',
            name_plural: 'Albanian lekë',
            rounding: 0,
            decimal_digits: 0,
        },
        DZD: {
            name: 'alžírsky dinár',
            symbol_native: 'د.ج.‏',
            symbol: 'DA',
            code: 'DZD',
            name_plural: 'Algerian dinars',
            rounding: 0,
            decimal_digits: 2,
        },
        USD: {
            name: 'americký dolár',
            symbol_native: '$',
            symbol: '$',
            code: 'USD',
            name_plural: 'US dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        ARS: {
            name: 'argentínske peso',
            symbol_native: '$',
            symbol: 'AR$',
            code: 'ARS',
            name_plural: 'Argentine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        AMD: {
            name: 'arménsky dram',
            symbol_native: 'դր.',
            symbol: 'AMD',
            code: 'AMD',
            name_plural: 'Armenian drams',
            rounding: 0,
            decimal_digits: 0,
        },
        AUD: {
            name: 'austrálsky dolár',
            symbol_native: '$',
            symbol: 'AU$',
            code: 'AUD',
            name_plural: 'Australian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        AZN: {
            name: 'azerbajdžanský manat',
            symbol_native: 'ман.',
            symbol: 'man.',
            code: 'AZN',
            name_plural: 'Azerbaijani manats',
            rounding: 0,
            decimal_digits: 2,
        },
        BHD: {
            name: 'bahrajnský dinár',
            symbol_native: 'د.ب.‏',
            symbol: 'BD',
            code: 'BHD',
            name_plural: 'Bahraini dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        BDT: {
            name: 'bangladéšska taka',
            symbol_native: '৳',
            symbol: 'Tk',
            code: 'BDT',
            name_plural: 'Bangladeshi takas',
            rounding: 0,
            decimal_digits: 2,
        },
        BZD: {
            name: 'belizský dolár',
            symbol_native: '$',
            symbol: 'BZ$',
            code: 'BZD',
            name_plural: 'Belize dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BYN: {
            name: 'bieloruský rubeľ',
            symbol_native: 'руб.',
            symbol: 'Br',
            code: 'BYN',
            name_plural: 'Belarusian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        BOB: {
            name: 'bolívijské boliviano',
            symbol_native: 'Bs',
            symbol: 'Bs',
            code: 'BOB',
            name_plural: 'Bolivian bolivianos',
            rounding: 0,
            decimal_digits: 2,
        },
        BWP: {
            name: 'botswanská pula',
            symbol_native: 'P',
            symbol: 'BWP',
            code: 'BWP',
            name_plural: 'Botswanan pulas',
            rounding: 0,
            decimal_digits: 2,
        },
        BRL: {
            name: 'brazílsky real',
            symbol_native: 'R$',
            symbol: 'R$',
            code: 'BRL',
            name_plural: 'Brazilian reals',
            rounding: 0,
            decimal_digits: 2,
        },
        GBP: {
            name: 'britská libra',
            symbol_native: '£',
            symbol: '£',
            code: 'GBP',
            name_plural: 'British pounds sterling',
            rounding: 0,
            decimal_digits: 2,
        },
        BND: {
            name: 'brunejský dolár',
            symbol_native: '$',
            symbol: 'BN$',
            code: 'BND',
            name_plural: 'Brunei dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BGN: {
            name: 'bulharský lev',
            symbol_native: 'лв.',
            symbol: 'BGN',
            code: 'BGN',
            name_plural: 'Bulgarian leva',
            rounding: 0,
            decimal_digits: 2,
        },
        BIF: {
            name: 'burundský frank',
            symbol_native: 'FBu',
            symbol: 'FBu',
            code: 'BIF',
            name_plural: 'Burundian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HRK: {
            name: 'chorvátska kuna',
            symbol_native: 'kn',
            symbol: 'kn',
            code: 'HRK',
            name_plural: 'Croatian kunas',
            rounding: 0,
            decimal_digits: 2,
        },
        DOP: {
            name: 'dominikánske peso',
            symbol_native: 'RD$',
            symbol: 'RD$',
            code: 'DOP',
            name_plural: 'Dominican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        DKK: {
            name: 'dánska koruna',
            symbol_native: 'kr',
            symbol: 'Dkr',
            code: 'DKK',
            name_plural: 'Danish kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        DJF: {
            name: 'džibutský frank',
            symbol_native: 'Fdj',
            symbol: 'Fdj',
            code: 'DJF',
            name_plural: 'Djiboutian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        EGP: {
            name: 'egyptská libra',
            symbol_native: 'ج.م.‏',
            symbol: 'EGP',
            code: 'EGP',
            name_plural: 'Egyptian pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        ERN: {
            name: 'eritrejská nakfa',
            symbol_native: 'Nfk',
            symbol: 'Nfk',
            code: 'ERN',
            name_plural: 'Eritrean nakfas',
            rounding: 0,
            decimal_digits: 2,
        },
        ETB: {
            name: 'etiópsky birr',
            symbol_native: 'Br',
            symbol: 'Br',
            code: 'ETB',
            name_plural: 'Ethiopian birrs',
            rounding: 0,
            decimal_digits: 2,
        },
        EUR: {
            name: 'euro',
            symbol_native: '€',
            symbol: '€',
            code: 'EUR',
            name_plural: 'euros',
            rounding: 0,
            decimal_digits: 2,
        },
        PHP: {
            name: 'filipínske peso',
            symbol_native: '₱',
            symbol: '₱',
            code: 'PHP',
            name_plural: 'Philippine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        GHS: {
            name: 'ghanské cedi',
            symbol_native: 'GH₵',
            symbol: 'GH₵',
            code: 'GHS',
            name_plural: 'Ghanaian cedis',
            rounding: 0,
            decimal_digits: 2,
        },
        GEL: {
            name: 'gruzínske lari',
            symbol_native: 'GEL',
            symbol: 'GEL',
            code: 'GEL',
            name_plural: 'Georgian laris',
            rounding: 0,
            decimal_digits: 2,
        },
        GTQ: {
            name: 'guatemalský quetzal',
            symbol_native: 'Q',
            symbol: 'GTQ',
            code: 'GTQ',
            name_plural: 'Guatemalan quetzals',
            rounding: 0,
            decimal_digits: 2,
        },
        GNF: {
            name: 'guinejský frank',
            symbol_native: 'FG',
            symbol: 'FG',
            code: 'GNF',
            name_plural: 'Guinean francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HNL: {
            name: 'honduraská lempira',
            symbol_native: 'L',
            symbol: 'HNL',
            code: 'HNL',
            name_plural: 'Honduran lempiras',
            rounding: 0,
            decimal_digits: 2,
        },
        HKD: {
            name: 'hongkonský dolár',
            symbol_native: '$',
            symbol: 'HK$',
            code: 'HKD',
            name_plural: 'Hong Kong dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        INR: {
            name: 'indická rupia',
            symbol_native: 'টকা',
            symbol: 'Rs',
            code: 'INR',
            name_plural: 'Indian rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        IDR: {
            name: 'indonézska rupia',
            symbol_native: 'Rp',
            symbol: 'Rp',
            code: 'IDR',
            name_plural: 'Indonesian rupiahs',
            rounding: 0,
            decimal_digits: 0,
        },
        IQD: {
            name: 'iracký dinár',
            symbol_native: 'د.ع.‏',
            symbol: 'IQD',
            code: 'IQD',
            name_plural: 'Iraqi dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        IRR: {
            name: 'iránsky rial',
            symbol_native: '﷼',
            symbol: 'IRR',
            code: 'IRR',
            name_plural: 'Iranian rials',
            rounding: 0,
            decimal_digits: 0,
        },
        ISK: {
            name: 'islandská koruna',
            symbol_native: 'kr',
            symbol: 'Ikr',
            code: 'ISK',
            name_plural: 'Icelandic krónur',
            rounding: 0,
            decimal_digits: 0,
        },
        ILS: {
            name: 'izraelský šekel',
            symbol_native: '₪',
            symbol: '₪',
            code: 'ILS',
            name_plural: 'Israeli new sheqels',
            rounding: 0,
            decimal_digits: 2,
        },
        JMD: {
            name: 'jamajský dolár',
            symbol_native: '$',
            symbol: 'J$',
            code: 'JMD',
            name_plural: 'Jamaican dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        JPY: {
            name: 'japonský jen',
            symbol_native: '￥',
            symbol: '¥',
            code: 'JPY',
            name_plural: 'Japanese yen',
            rounding: 0,
            decimal_digits: 0,
        },
        YER: {
            name: 'jemenský rial',
            symbol_native: 'ر.ي.‏',
            symbol: 'YR',
            code: 'YER',
            name_plural: 'Yemeni rials',
            rounding: 0,
            decimal_digits: 0,
        },
        JOD: {
            name: 'jordánsky dinár',
            symbol_native: 'د.أ.‏',
            symbol: 'JD',
            code: 'JOD',
            name_plural: 'Jordanian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        ZAR: {
            name: 'juhoafrický rand',
            symbol_native: 'R',
            symbol: 'R',
            code: 'ZAR',
            name_plural: 'South African rand',
            rounding: 0,
            decimal_digits: 2,
        },
        KRW: {
            name: 'juhokórejský won',
            symbol_native: '₩',
            symbol: '₩',
            code: 'KRW',
            name_plural: 'South Korean won',
            rounding: 0,
            decimal_digits: 0,
        },
        KHR: {
            name: 'kambodžský riel',
            symbol_native: '៛',
            symbol: 'KHR',
            code: 'KHR',
            name_plural: 'Cambodian riels',
            rounding: 0,
            decimal_digits: 2,
        },
        CAD: {
            name: 'kanadský dolár',
            symbol_native: '$',
            symbol: 'CA$',
            code: 'CAD',
            name_plural: 'Canadian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        CVE: {
            name: 'kapverdské escudo',
            symbol_native: 'CV$',
            symbol: 'CV$',
            code: 'CVE',
            name_plural: 'Cape Verdean escudos',
            rounding: 0,
            decimal_digits: 2,
        },
        QAR: {
            name: 'katarský rial',
            symbol_native: 'ر.ق.‏',
            symbol: 'QR',
            code: 'QAR',
            name_plural: 'Qatari rials',
            rounding: 0,
            decimal_digits: 2,
        },
        KZT: {
            name: 'kazašské tenge',
            symbol_native: 'тңг.',
            symbol: 'KZT',
            code: 'KZT',
            name_plural: 'Kazakhstani tenges',
            rounding: 0,
            decimal_digits: 2,
        },
        KES: {
            name: 'kenský šiling',
            symbol_native: 'Ksh',
            symbol: 'Ksh',
            code: 'KES',
            name_plural: 'Kenyan shillings',
            rounding: 0,
            decimal_digits: 2,
        },
        COP: {
            name: 'kolumbijské peso',
            symbol_native: '$',
            symbol: 'CO$',
            code: 'COP',
            name_plural: 'Colombian pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        KMF: {
            name: 'komorský frank',
            symbol_native: 'FC',
            symbol: 'CF',
            code: 'KMF',
            name_plural: 'Comorian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        BAM: {
            name: 'konvertibilná marka',
            symbol_native: 'KM',
            symbol: 'KM',
            code: 'BAM',
            name_plural: 'Bosnia-Herzegovina convertible marks',
            rounding: 0,
            decimal_digits: 2,
        },
        CDF: {
            name: 'konžský frank',
            symbol_native: 'FrCD',
            symbol: 'CDF',
            code: 'CDF',
            name_plural: 'Congolese francs',
            rounding: 0,
            decimal_digits: 2,
        },
        CRC: {
            name: 'kostarický colón',
            symbol_native: '₡',
            symbol: '₡',
            code: 'CRC',
            name_plural: 'Costa Rican colóns',
            rounding: 0,
            decimal_digits: 0,
        },
        KWD: {
            name: 'kuvajtský dinár',
            symbol_native: 'د.ك.‏',
            symbol: 'KD',
            code: 'KWD',
            name_plural: 'Kuwaiti dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LBP: {
            name: 'libanonská libra',
            symbol_native: 'ل.ل.‏',
            symbol: 'LB£',
            code: 'LBP',
            name_plural: 'Lebanese pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        LTL: {
            name: 'litovský litas',
            symbol_native: 'Lt',
            symbol: 'Lt',
            code: 'LTL',
            name_plural: 'Lithuanian litai',
            rounding: 0,
            decimal_digits: 2,
        },
        LYD: {
            name: 'líbyjský dinár',
            symbol_native: 'د.ل.‏',
            symbol: 'LD',
            code: 'LYD',
            name_plural: 'Libyan dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        MOP: {
            name: 'macajská pataca',
            symbol_native: 'MOP$',
            symbol: 'MOP$',
            code: 'MOP',
            name_plural: 'Macanese patacas',
            rounding: 0,
            decimal_digits: 2,
        },
        MKD: {
            name: 'macedónsky denár',
            symbol_native: 'MKD',
            symbol: 'MKD',
            code: 'MKD',
            name_plural: 'Macedonian denari',
            rounding: 0,
            decimal_digits: 2,
        },
        MYR: {
            name: 'malajzijský ringgit',
            symbol_native: 'RM',
            symbol: 'RM',
            code: 'MYR',
            name_plural: 'Malaysian ringgits',
            rounding: 0,
            decimal_digits: 2,
        },
        MGA: {
            name: 'malgašský ariary',
            symbol_native: 'MGA',
            symbol: 'MGA',
            code: 'MGA',
            name_plural: 'Malagasy Ariaries',
            rounding: 0,
            decimal_digits: 0,
        },
        MAD: {
            name: 'marocký dirham',
            symbol_native: 'د.م.‏',
            symbol: 'MAD',
            code: 'MAD',
            name_plural: 'Moroccan dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        MUR: {
            name: 'maurícijská rupia',
            symbol_native: 'MURs',
            symbol: 'MURs',
            code: 'MUR',
            name_plural: 'Mauritian rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        HUF: {
            name: 'maďarský forint',
            symbol_native: 'Ft',
            symbol: 'Ft',
            code: 'HUF',
            name_plural: 'Hungarian forints',
            rounding: 0,
            decimal_digits: 0,
        },
        MXN: {
            name: 'mexické peso',
            symbol_native: '$',
            symbol: 'MX$',
            code: 'MXN',
            name_plural: 'Mexican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        MMK: {
            name: 'mjanmarský kyat',
            symbol_native: 'K',
            symbol: 'MMK',
            code: 'MMK',
            name_plural: 'Myanma kyats',
            rounding: 0,
            decimal_digits: 0,
        },
        MDL: {
            name: 'moldavský lei',
            symbol_native: 'MDL',
            symbol: 'MDL',
            code: 'MDL',
            name_plural: 'Moldovan lei',
            rounding: 0,
            decimal_digits: 2,
        },
        MZN: {
            name: 'mozambický metical',
            symbol_native: 'MTn',
            symbol: 'MTn',
            code: 'MZN',
            name_plural: 'Mozambican meticals',
            rounding: 0,
            decimal_digits: 2,
        },
        NAD: {
            name: 'namíbijský dolár',
            symbol_native: 'N$',
            symbol: 'N$',
            code: 'NAD',
            name_plural: 'Namibian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NPR: {
            name: 'nepálska rupia',
            symbol_native: 'नेरू',
            symbol: 'NPRs',
            code: 'NPR',
            name_plural: 'Nepalese rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        NGN: {
            name: 'nigérijská naira',
            symbol_native: '₦',
            symbol: '₦',
            code: 'NGN',
            name_plural: 'Nigerian nairas',
            rounding: 0,
            decimal_digits: 2,
        },
        NIO: {
            name: 'nikaragujská córdoba',
            symbol_native: 'C$',
            symbol: 'C$',
            code: 'NIO',
            name_plural: 'Nicaraguan córdobas',
            rounding: 0,
            decimal_digits: 2,
        },
        NZD: {
            name: 'novozélandský dolár',
            symbol_native: '$',
            symbol: 'NZ$',
            code: 'NZD',
            name_plural: 'New Zealand dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        TWD: {
            name: 'nový taiwanský dolár',
            symbol_native: 'NT$',
            symbol: 'NT$',
            code: 'TWD',
            name_plural: 'New Taiwan dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NOK: {
            name: 'nórska koruna',
            symbol_native: 'kr',
            symbol: 'Nkr',
            code: 'NOK',
            name_plural: 'Norwegian kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        OMR: {
            name: 'ománsky rial',
            symbol_native: 'ر.ع.‏',
            symbol: 'OMR',
            code: 'OMR',
            name_plural: 'Omani rials',
            rounding: 0,
            decimal_digits: 3,
        },
        PKR: {
            name: 'pakistanská rupia',
            symbol_native: '₨',
            symbol: 'PKRs',
            code: 'PKR',
            name_plural: 'Pakistani rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        PAB: {
            name: 'panamská balboa',
            symbol_native: 'B/.',
            symbol: 'B/.',
            code: 'PAB',
            name_plural: 'Panamanian balboas',
            rounding: 0,
            decimal_digits: 2,
        },
        PYG: {
            name: 'paraguajské guaraní',
            symbol_native: '₲',
            symbol: '₲',
            code: 'PYG',
            name_plural: 'Paraguayan guaranis',
            rounding: 0,
            decimal_digits: 0,
        },
        PEN: {
            name: 'peruánsky sol',
            symbol_native: 'S/.',
            symbol: 'S/.',
            code: 'PEN',
            name_plural: 'Peruvian nuevos soles',
            rounding: 0,
            decimal_digits: 2,
        },
        PLN: {
            name: 'poľský zlotý',
            symbol_native: 'zł',
            symbol: 'zł',
            code: 'PLN',
            name_plural: 'Polish zlotys',
            rounding: 0,
            decimal_digits: 2,
        },
        RON: {
            name: 'rumunský lei',
            symbol_native: 'RON',
            symbol: 'RON',
            code: 'RON',
            name_plural: 'Romanian lei',
            rounding: 0,
            decimal_digits: 2,
        },
        RUB: {
            name: 'ruský rubeľ',
            symbol_native: '₽',
            symbol: 'RUB',
            code: 'RUB',
            name_plural: 'Russian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        RWF: {
            name: 'rwandský frank',
            symbol_native: 'FR',
            symbol: 'RWF',
            code: 'RWF',
            name_plural: 'Rwandan francs',
            rounding: 0,
            decimal_digits: 0,
        },
        SAR: {
            name: 'saudskoarabský rial',
            symbol_native: 'ر.س.‏',
            symbol: 'SR',
            code: 'SAR',
            name_plural: 'Saudi riyals',
            rounding: 0,
            decimal_digits: 2,
        },
        SGD: {
            name: 'singapurský dolár',
            symbol_native: '$',
            symbol: 'S$',
            code: 'SGD',
            name_plural: 'Singapore dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        SOS: {
            name: 'somálsky šiling',
            symbol_native: 'Ssh',
            symbol: 'Ssh',
            code: 'SOS',
            name_plural: 'Somali shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        RSD: {
            name: 'srbský dinár',
            symbol_native: 'дин.',
            symbol: 'din.',
            code: 'RSD',
            name_plural: 'Serbian dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        LKR: {
            name: 'srílanská rupia',
            symbol_native: 'SL Re',
            symbol: 'SLRs',
            code: 'LKR',
            name_plural: 'Sri Lankan rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        XAF: {
            name: 'stredoafrický frank',
            symbol_native: 'FCFA',
            symbol: 'FCFA',
            code: 'XAF',
            name_plural: 'CFA francs BEAC',
            rounding: 0,
            decimal_digits: 0,
        },
        SDG: {
            name: 'sudánska libra',
            symbol_native: 'SDG',
            symbol: 'SDG',
            code: 'SDG',
            name_plural: 'Sudanese pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        SYP: {
            name: 'sýrska libra',
            symbol_native: 'ل.س.‏',
            symbol: 'SY£',
            code: 'SYP',
            name_plural: 'Syrian pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        TZS: {
            name: 'tanzánsky šiling',
            symbol_native: 'TSh',
            symbol: 'TSh',
            code: 'TZS',
            name_plural: 'Tanzanian shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        THB: {
            name: 'thajský baht',
            symbol_native: '฿',
            symbol: '฿',
            code: 'THB',
            name_plural: 'Thai baht',
            rounding: 0,
            decimal_digits: 2,
        },
        TOP: {
            name: 'tongská paʻanga',
            symbol_native: 'T$',
            symbol: 'T$',
            code: 'TOP',
            name_plural: 'Tongan paʻanga',
            rounding: 0,
            decimal_digits: 2,
        },
        TTD: {
            name: 'trinidadsko-tobažský dolár',
            symbol_native: '$',
            symbol: 'TT$',
            code: 'TTD',
            name_plural: 'Trinidad and Tobago dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        TND: {
            name: 'tuniský dinár',
            symbol_native: 'د.ت.‏',
            symbol: 'DT',
            code: 'TND',
            name_plural: 'Tunisian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        TRY: {
            name: 'turecká líra',
            symbol_native: 'TL',
            symbol: 'TL',
            code: 'TRY',
            name_plural: 'Turkish Lira',
            rounding: 0,
            decimal_digits: 2,
        },
        UGX: {
            name: 'ugandský šiling',
            symbol_native: 'USh',
            symbol: 'USh',
            code: 'UGX',
            name_plural: 'Ugandan shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        UAH: {
            name: 'ukrajinská hrivna',
            symbol_native: '₴',
            symbol: '₴',
            code: 'UAH',
            name_plural: 'Ukrainian hryvnias',
            rounding: 0,
            decimal_digits: 2,
        },
        UYU: {
            name: 'uruguajské peso',
            symbol_native: '$',
            symbol: '$U',
            code: 'UYU',
            name_plural: 'Uruguayan pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        UZS: {
            name: 'uzbecký sum',
            symbol_native: 'UZS',
            symbol: 'UZS',
            code: 'UZS',
            name_plural: 'Uzbekistan som',
            rounding: 0,
            decimal_digits: 0,
        },
        VEF: {
            name: 'venezuelský bolívar',
            symbol_native: 'Bs.F.',
            symbol: 'Bs.F.',
            code: 'VEF',
            name_plural: 'Venezuelan bolívars',
            rounding: 0,
            decimal_digits: 2,
        },
        VND: {
            name: 'vietnamský dong',
            symbol_native: '₫',
            symbol: '₫',
            code: 'VND',
            name_plural: 'Vietnamese dong',
            rounding: 0,
            decimal_digits: 0,
        },
        XOF: {
            name: 'západoafrický frank',
            symbol_native: 'CFA',
            symbol: 'CFA',
            code: 'XOF',
            name_plural: 'CFA francs BCEAO',
            rounding: 0,
            decimal_digits: 0,
        },
        CZK: {
            name: 'česká koruna',
            symbol_native: 'Kč',
            symbol: 'Kč',
            code: 'CZK',
            name_plural: 'Czech Republic korunas',
            rounding: 0,
            decimal_digits: 2,
        },
        CLP: {
            name: 'čilské peso',
            symbol_native: '$',
            symbol: 'CL$',
            code: 'CLP',
            name_plural: 'Chilean pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        CNY: {
            name: 'čínsky jüan',
            symbol_native: 'CN¥',
            symbol: 'CN¥',
            code: 'CNY',
            name_plural: 'Chinese yuan',
            rounding: 0,
            decimal_digits: 2,
        },
        CHF: {
            name: 'švajčiarsky frank',
            symbol_native: 'CHF',
            symbol: 'CHF',
            code: 'CHF',
            name_plural: 'Swiss francs',
            rounding: 0.05,
            decimal_digits: 2,
        },
        SEK: {
            name: 'švédska koruna',
            symbol_native: 'kr',
            symbol: 'Skr',
            code: 'SEK',
            name_plural: 'Swedish kronor',
            rounding: 0,
            decimal_digits: 2,
        },
    },
    no: {
        HKD: {
            name: 'Hongkong-dollar',
            symbol_native: '$',
            symbol: 'HK$',
            code: 'HKD',
            name_plural: 'Hong Kong dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        AFN: {
            name: 'afghanske afghani',
            symbol_native: '؋',
            symbol: 'Af',
            code: 'AFN',
            name_plural: 'Afghan Afghanis',
            rounding: 0,
            decimal_digits: 0,
        },
        ALL: {
            name: 'albanske lek',
            symbol_native: 'Lek',
            symbol: 'ALL',
            code: 'ALL',
            name_plural: 'Albanian lekë',
            rounding: 0,
            decimal_digits: 0,
        },
        DZD: {
            name: 'algeriske dinarer',
            symbol_native: 'د.ج.‏',
            symbol: 'DA',
            code: 'DZD',
            name_plural: 'Algerian dinars',
            rounding: 0,
            decimal_digits: 2,
        },
        USD: {
            name: 'amerikanske dollar',
            symbol_native: '$',
            symbol: '$',
            code: 'USD',
            name_plural: 'US dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        ARS: {
            name: 'argentinske pesos',
            symbol_native: '$',
            symbol: 'AR$',
            code: 'ARS',
            name_plural: 'Argentine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        AMD: {
            name: 'armenske dram',
            symbol_native: 'դր.',
            symbol: 'AMD',
            code: 'AMD',
            name_plural: 'Armenian drams',
            rounding: 0,
            decimal_digits: 0,
        },
        AZN: {
            name: 'aserbajdsjanske manat',
            symbol_native: 'ман.',
            symbol: 'man.',
            code: 'AZN',
            name_plural: 'Azerbaijani manats',
            rounding: 0,
            decimal_digits: 2,
        },
        AUD: {
            name: 'australske dollar',
            symbol_native: '$',
            symbol: 'AU$',
            code: 'AUD',
            name_plural: 'Australian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BHD: {
            name: 'bahrainske dinarer',
            symbol_native: 'د.ب.‏',
            symbol: 'BD',
            code: 'BHD',
            name_plural: 'Bahraini dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        BDT: {
            name: 'bangladeshiske taka',
            symbol_native: '৳',
            symbol: 'Tk',
            code: 'BDT',
            name_plural: 'Bangladeshi takas',
            rounding: 0,
            decimal_digits: 2,
        },
        BZD: {
            name: 'beliziske dollar',
            symbol_native: '$',
            symbol: 'BZ$',
            code: 'BZD',
            name_plural: 'Belize dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BOB: {
            name: 'bolivianske boliviano',
            symbol_native: 'Bs',
            symbol: 'Bs',
            code: 'BOB',
            name_plural: 'Bolivian bolivianos',
            rounding: 0,
            decimal_digits: 2,
        },
        BAM: {
            name: 'bosnisk-hercegovinske konvertible mark',
            symbol_native: 'KM',
            symbol: 'KM',
            code: 'BAM',
            name_plural: 'Bosnia-Herzegovina convertible marks',
            rounding: 0,
            decimal_digits: 2,
        },
        BWP: {
            name: 'botswanske pula',
            symbol_native: 'P',
            symbol: 'BWP',
            code: 'BWP',
            name_plural: 'Botswanan pulas',
            rounding: 0,
            decimal_digits: 2,
        },
        BRL: {
            name: 'brasilianske real',
            symbol_native: 'R$',
            symbol: 'R$',
            code: 'BRL',
            name_plural: 'Brazilian reals',
            rounding: 0,
            decimal_digits: 2,
        },
        GBP: {
            name: 'britiske pund',
            symbol_native: '£',
            symbol: '£',
            code: 'GBP',
            name_plural: 'British pounds sterling',
            rounding: 0,
            decimal_digits: 2,
        },
        BND: {
            name: 'bruneiske dollar',
            symbol_native: '$',
            symbol: 'BN$',
            code: 'BND',
            name_plural: 'Brunei dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BGN: {
            name: 'bulgarske lev',
            symbol_native: 'лв.',
            symbol: 'BGN',
            code: 'BGN',
            name_plural: 'Bulgarian leva',
            rounding: 0,
            decimal_digits: 2,
        },
        BIF: {
            name: 'burundiske franc',
            symbol_native: 'FBu',
            symbol: 'FBu',
            code: 'BIF',
            name_plural: 'Burundian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        CLP: {
            name: 'chilenske pesos',
            symbol_native: '$',
            symbol: 'CL$',
            code: 'CLP',
            name_plural: 'Chilean pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        COP: {
            name: 'colombianske pesos',
            symbol_native: '$',
            symbol: 'CO$',
            code: 'COP',
            name_plural: 'Colombian pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        CRC: {
            name: 'costaricanske colón',
            symbol_native: '₡',
            symbol: '₡',
            code: 'CRC',
            name_plural: 'Costa Rican colóns',
            rounding: 0,
            decimal_digits: 0,
        },
        DKK: {
            name: 'danske kroner',
            symbol_native: 'kr',
            symbol: 'Dkr',
            code: 'DKK',
            name_plural: 'Danish kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        DJF: {
            name: 'djiboutiske franc',
            symbol_native: 'Fdj',
            symbol: 'Fdj',
            code: 'DJF',
            name_plural: 'Djiboutian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        DOP: {
            name: 'dominikanske pesos',
            symbol_native: 'RD$',
            symbol: 'RD$',
            code: 'DOP',
            name_plural: 'Dominican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        EGP: {
            name: 'egyptiske pund',
            symbol_native: 'ج.م.‏',
            symbol: 'EGP',
            code: 'EGP',
            name_plural: 'Egyptian pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        AED: {
            name: 'emiratarabiske dirham',
            symbol_native: 'د.إ.‏',
            symbol: 'AED',
            code: 'AED',
            name_plural: 'UAE dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        ERN: {
            name: 'eritreiske nakfa',
            symbol_native: 'Nfk',
            symbol: 'Nfk',
            code: 'ERN',
            name_plural: 'Eritrean nakfas',
            rounding: 0,
            decimal_digits: 2,
        },
        EEK: {
            name: 'estiske kroon',
            symbol_native: 'kr',
            symbol: 'Ekr',
            code: 'EEK',
            name_plural: 'Estonian kroons',
            rounding: 0,
            decimal_digits: 2,
        },
        ETB: {
            name: 'etiopiske birr',
            symbol_native: 'Br',
            symbol: 'Br',
            code: 'ETB',
            name_plural: 'Ethiopian birrs',
            rounding: 0,
            decimal_digits: 2,
        },
        EUR: {
            name: 'euro',
            symbol_native: '€',
            symbol: '€',
            code: 'EUR',
            name_plural: 'euros',
            rounding: 0,
            decimal_digits: 2,
        },
        PHP: {
            name: 'filippinske pesos',
            symbol_native: '₱',
            symbol: '₱',
            code: 'PHP',
            name_plural: 'Philippine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        GEL: {
            name: 'georgiske lari',
            symbol_native: 'GEL',
            symbol: 'GEL',
            code: 'GEL',
            name_plural: 'Georgian laris',
            rounding: 0,
            decimal_digits: 2,
        },
        GHS: {
            name: 'ghanesiske cedi',
            symbol_native: 'GH₵',
            symbol: 'GH₵',
            code: 'GHS',
            name_plural: 'Ghanaian cedis',
            rounding: 0,
            decimal_digits: 2,
        },
        GTQ: {
            name: 'guatemalanske quetzal',
            symbol_native: 'Q',
            symbol: 'GTQ',
            code: 'GTQ',
            name_plural: 'Guatemalan quetzals',
            rounding: 0,
            decimal_digits: 2,
        },
        GNF: {
            name: 'guineanske franc',
            symbol_native: 'FG',
            symbol: 'FG',
            code: 'GNF',
            name_plural: 'Guinean francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HNL: {
            name: 'honduranske lempira',
            symbol_native: 'L',
            symbol: 'HNL',
            code: 'HNL',
            name_plural: 'Honduran lempiras',
            rounding: 0,
            decimal_digits: 2,
        },
        BYN: {
            name: 'hviterussiske rubler',
            symbol_native: 'руб.',
            symbol: 'Br',
            code: 'BYN',
            name_plural: 'Belarusian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        INR: {
            name: 'indiske rupier',
            symbol_native: 'টকা',
            symbol: 'Rs',
            code: 'INR',
            name_plural: 'Indian rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        IDR: {
            name: 'indonesiske rupier',
            symbol_native: 'Rp',
            symbol: 'Rp',
            code: 'IDR',
            name_plural: 'Indonesian rupiahs',
            rounding: 0,
            decimal_digits: 0,
        },
        IQD: {
            name: 'irakske dinarer',
            symbol_native: 'د.ع.‏',
            symbol: 'IQD',
            code: 'IQD',
            name_plural: 'Iraqi dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        IRR: {
            name: 'iranske rialer',
            symbol_native: '﷼',
            symbol: 'IRR',
            code: 'IRR',
            name_plural: 'Iranian rials',
            rounding: 0,
            decimal_digits: 0,
        },
        ISK: {
            name: 'islandske kroner',
            symbol_native: 'kr',
            symbol: 'Ikr',
            code: 'ISK',
            name_plural: 'Icelandic krónur',
            rounding: 0,
            decimal_digits: 0,
        },
        JMD: {
            name: 'jamaikanske dollar',
            symbol_native: '$',
            symbol: 'J$',
            code: 'JMD',
            name_plural: 'Jamaican dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        JPY: {
            name: 'japanske yen',
            symbol_native: '￥',
            symbol: '¥',
            code: 'JPY',
            name_plural: 'Japanese yen',
            rounding: 0,
            decimal_digits: 0,
        },
        YER: {
            name: 'jemenittiske rialer',
            symbol_native: 'ر.ي.‏',
            symbol: 'YR',
            code: 'YER',
            name_plural: 'Yemeni rials',
            rounding: 0,
            decimal_digits: 0,
        },
        JOD: {
            name: 'jordanske dinarer',
            symbol_native: 'د.أ.‏',
            symbol: 'JD',
            code: 'JOD',
            name_plural: 'Jordanian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        KHR: {
            name: 'kambodsjanske riel',
            symbol_native: '៛',
            symbol: 'KHR',
            code: 'KHR',
            name_plural: 'Cambodian riels',
            rounding: 0,
            decimal_digits: 2,
        },
        CAD: {
            name: 'kanadiske dollar',
            symbol_native: '$',
            symbol: 'CA$',
            code: 'CAD',
            name_plural: 'Canadian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        CVE: {
            name: 'kappverdiske escudos',
            symbol_native: 'CV$',
            symbol: 'CV$',
            code: 'CVE',
            name_plural: 'Cape Verdean escudos',
            rounding: 0,
            decimal_digits: 2,
        },
        KZT: {
            name: 'kasakhstanske tenge',
            symbol_native: 'тңг.',
            symbol: 'KZT',
            code: 'KZT',
            name_plural: 'Kazakhstani tenges',
            rounding: 0,
            decimal_digits: 2,
        },
        KES: {
            name: 'kenyanske shilling',
            symbol_native: 'Ksh',
            symbol: 'Ksh',
            code: 'KES',
            name_plural: 'Kenyan shillings',
            rounding: 0,
            decimal_digits: 2,
        },
        CNY: {
            name: 'kinesiske yuan',
            symbol_native: 'CN¥',
            symbol: 'CN¥',
            code: 'CNY',
            name_plural: 'Chinese yuan',
            rounding: 0,
            decimal_digits: 2,
        },
        KMF: {
            name: 'komoriske franc',
            symbol_native: 'FC',
            symbol: 'CF',
            code: 'KMF',
            name_plural: 'Comorian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        CDF: {
            name: 'kongolesiske franc',
            symbol_native: 'FrCD',
            symbol: 'CDF',
            code: 'CDF',
            name_plural: 'Congolese francs',
            rounding: 0,
            decimal_digits: 2,
        },
        HRK: {
            name: 'kroatiske kuna',
            symbol_native: 'kn',
            symbol: 'kn',
            code: 'HRK',
            name_plural: 'Croatian kunas',
            rounding: 0,
            decimal_digits: 2,
        },
        KWD: {
            name: 'kuwaitiske dinarer',
            symbol_native: 'د.ك.‏',
            symbol: 'KD',
            code: 'KWD',
            name_plural: 'Kuwaiti dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LVL: {
            name: 'latviske lats',
            symbol_native: 'Ls',
            symbol: 'Ls',
            code: 'LVL',
            name_plural: 'Latvian lati',
            rounding: 0,
            decimal_digits: 2,
        },
        LBP: {
            name: 'libanesiske pund',
            symbol_native: 'ل.ل.‏',
            symbol: 'LB£',
            code: 'LBP',
            name_plural: 'Lebanese pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        LYD: {
            name: 'libyske dinarer',
            symbol_native: 'د.ل.‏',
            symbol: 'LD',
            code: 'LYD',
            name_plural: 'Libyan dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LTL: {
            name: 'litauiske litas',
            symbol_native: 'Lt',
            symbol: 'Lt',
            code: 'LTL',
            name_plural: 'Lithuanian litai',
            rounding: 0,
            decimal_digits: 2,
        },
        MGA: {
            name: 'madagassiske ariary',
            symbol_native: 'MGA',
            symbol: 'MGA',
            code: 'MGA',
            name_plural: 'Malagasy Ariaries',
            rounding: 0,
            decimal_digits: 0,
        },
        MOP: {
            name: 'makaoiske pataca',
            symbol_native: 'MOP$',
            symbol: 'MOP$',
            code: 'MOP',
            name_plural: 'Macanese patacas',
            rounding: 0,
            decimal_digits: 2,
        },
        MKD: {
            name: 'makedonske denarer',
            symbol_native: 'MKD',
            symbol: 'MKD',
            code: 'MKD',
            name_plural: 'Macedonian denari',
            rounding: 0,
            decimal_digits: 2,
        },
        MYR: {
            name: 'malaysiske ringgit',
            symbol_native: 'RM',
            symbol: 'RM',
            code: 'MYR',
            name_plural: 'Malaysian ringgits',
            rounding: 0,
            decimal_digits: 2,
        },
        MAD: {
            name: 'marokkanske dirham',
            symbol_native: 'د.م.‏',
            symbol: 'MAD',
            code: 'MAD',
            name_plural: 'Moroccan dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        MUR: {
            name: 'mauritiske rupier',
            symbol_native: 'MURs',
            symbol: 'MURs',
            code: 'MUR',
            name_plural: 'Mauritian rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        MXN: {
            name: 'meksikanske pesos',
            symbol_native: '$',
            symbol: 'MX$',
            code: 'MXN',
            name_plural: 'Mexican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        MDL: {
            name: 'moldovske leu',
            symbol_native: 'MDL',
            symbol: 'MDL',
            code: 'MDL',
            name_plural: 'Moldovan lei',
            rounding: 0,
            decimal_digits: 2,
        },
        MZN: {
            name: 'mosambikiske metical',
            symbol_native: 'MTn',
            symbol: 'MTn',
            code: 'MZN',
            name_plural: 'Mozambican meticals',
            rounding: 0,
            decimal_digits: 2,
        },
        MMK: {
            name: 'myanmarske kyat',
            symbol_native: 'K',
            symbol: 'MMK',
            code: 'MMK',
            name_plural: 'Myanma kyats',
            rounding: 0,
            decimal_digits: 0,
        },
        NAD: {
            name: 'namibiske dollar',
            symbol_native: 'N$',
            symbol: 'N$',
            code: 'NAD',
            name_plural: 'Namibian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NPR: {
            name: 'nepalske rupier',
            symbol_native: 'नेरू',
            symbol: 'NPRs',
            code: 'NPR',
            name_plural: 'Nepalese rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        NZD: {
            name: 'newzealandske dollar',
            symbol_native: '$',
            symbol: 'NZ$',
            code: 'NZD',
            name_plural: 'New Zealand dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NIO: {
            name: 'nicaraguanske córdoba',
            symbol_native: 'C$',
            symbol: 'C$',
            code: 'NIO',
            name_plural: 'Nicaraguan córdobas',
            rounding: 0,
            decimal_digits: 2,
        },
        NGN: {
            name: 'nigerianske naira',
            symbol_native: '₦',
            symbol: '₦',
            code: 'NGN',
            name_plural: 'Nigerian nairas',
            rounding: 0,
            decimal_digits: 2,
        },
        NOK: {
            name: 'norske kroner',
            symbol_native: 'kr',
            symbol: 'Nkr',
            code: 'NOK',
            name_plural: 'Norwegian kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        ILS: {
            name: 'nye israelske shekler',
            symbol_native: '₪',
            symbol: '₪',
            code: 'ILS',
            name_plural: 'Israeli new sheqels',
            rounding: 0,
            decimal_digits: 2,
        },
        TWD: {
            name: 'nye taiwanske dollar',
            symbol_native: 'NT$',
            symbol: 'NT$',
            code: 'TWD',
            name_plural: 'New Taiwan dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        OMR: {
            name: 'omanske rialer',
            symbol_native: 'ر.ع.‏',
            symbol: 'OMR',
            code: 'OMR',
            name_plural: 'Omani rials',
            rounding: 0,
            decimal_digits: 3,
        },
        PKR: {
            name: 'pakistanske rupier',
            symbol_native: '₨',
            symbol: 'PKRs',
            code: 'PKR',
            name_plural: 'Pakistani rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        PAB: {
            name: 'panamanske balboa',
            symbol_native: 'B/.',
            symbol: 'B/.',
            code: 'PAB',
            name_plural: 'Panamanian balboas',
            rounding: 0,
            decimal_digits: 2,
        },
        PYG: {
            name: 'paraguayanske guarani',
            symbol_native: '₲',
            symbol: '₲',
            code: 'PYG',
            name_plural: 'Paraguayan guaranis',
            rounding: 0,
            decimal_digits: 0,
        },
        PEN: {
            name: 'peruanske sol',
            symbol_native: 'S/.',
            symbol: 'S/.',
            code: 'PEN',
            name_plural: 'Peruvian nuevos soles',
            rounding: 0,
            decimal_digits: 2,
        },
        PLN: {
            name: 'polske zloty',
            symbol_native: 'zł',
            symbol: 'zł',
            code: 'PLN',
            name_plural: 'Polish zlotys',
            rounding: 0,
            decimal_digits: 2,
        },
        QAR: {
            name: 'qatarske rialer',
            symbol_native: 'ر.ق.‏',
            symbol: 'QR',
            code: 'QAR',
            name_plural: 'Qatari rials',
            rounding: 0,
            decimal_digits: 2,
        },
        RON: {
            name: 'rumenske leu',
            symbol_native: 'RON',
            symbol: 'RON',
            code: 'RON',
            name_plural: 'Romanian lei',
            rounding: 0,
            decimal_digits: 2,
        },
        RUB: {
            name: 'russiske rubler',
            symbol_native: '₽',
            symbol: 'RUB',
            code: 'RUB',
            name_plural: 'Russian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        RWF: {
            name: 'rwandiske franc',
            symbol_native: 'FR',
            symbol: 'RWF',
            code: 'RWF',
            name_plural: 'Rwandan francs',
            rounding: 0,
            decimal_digits: 0,
        },
        SAR: {
            name: 'saudiarabiske riyaler',
            symbol_native: 'ر.س.‏',
            symbol: 'SR',
            code: 'SAR',
            name_plural: 'Saudi riyals',
            rounding: 0,
            decimal_digits: 2,
        },
        XAF: {
            name: 'sentralafrikanske CFA-franc',
            symbol_native: 'FCFA',
            symbol: 'FCFA',
            code: 'XAF',
            name_plural: 'CFA francs BEAC',
            rounding: 0,
            decimal_digits: 0,
        },
        RSD: {
            name: 'serbiske dinarer',
            symbol_native: 'дин.',
            symbol: 'din.',
            code: 'RSD',
            name_plural: 'Serbian dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        SGD: {
            name: 'singaporske dollar',
            symbol_native: '$',
            symbol: 'S$',
            code: 'SGD',
            name_plural: 'Singapore dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        SOS: {
            name: 'somaliske shilling',
            symbol_native: 'Ssh',
            symbol: 'Ssh',
            code: 'SOS',
            name_plural: 'Somali shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        LKR: {
            name: 'srilankiske rupier',
            symbol_native: 'SL Re',
            symbol: 'SLRs',
            code: 'LKR',
            name_plural: 'Sri Lankan rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        SDG: {
            name: 'sudanske pund',
            symbol_native: 'SDG',
            symbol: 'SDG',
            code: 'SDG',
            name_plural: 'Sudanese pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        CHF: {
            name: 'sveitsiske franc',
            symbol_native: 'CHF',
            symbol: 'CHF',
            code: 'CHF',
            name_plural: 'Swiss francs',
            rounding: 0.05,
            decimal_digits: 2,
        },
        SEK: {
            name: 'svenske kroner',
            symbol_native: 'kr',
            symbol: 'Skr',
            code: 'SEK',
            name_plural: 'Swedish kronor',
            rounding: 0,
            decimal_digits: 2,
        },
        SYP: {
            name: 'syriske pund',
            symbol_native: 'ل.س.‏',
            symbol: 'SY£',
            code: 'SYP',
            name_plural: 'Syrian pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        ZAR: {
            name: 'sørafrikanske rand',
            symbol_native: 'R',
            symbol: 'R',
            code: 'ZAR',
            name_plural: 'South African rand',
            rounding: 0,
            decimal_digits: 2,
        },
        KRW: {
            name: 'sørkoreanske won',
            symbol_native: '₩',
            symbol: '₩',
            code: 'KRW',
            name_plural: 'South Korean won',
            rounding: 0,
            decimal_digits: 0,
        },
        TZS: {
            name: 'tanzanianske shilling',
            symbol_native: 'TSh',
            symbol: 'TSh',
            code: 'TZS',
            name_plural: 'Tanzanian shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        THB: {
            name: 'thailandske baht',
            symbol_native: '฿',
            symbol: '฿',
            code: 'THB',
            name_plural: 'Thai baht',
            rounding: 0,
            decimal_digits: 2,
        },
        TOP: {
            name: 'tonganske paʻanga',
            symbol_native: 'T$',
            symbol: 'T$',
            code: 'TOP',
            name_plural: 'Tongan paʻanga',
            rounding: 0,
            decimal_digits: 2,
        },
        TTD: {
            name: 'trinidadiske dollar',
            symbol_native: '$',
            symbol: 'TT$',
            code: 'TTD',
            name_plural: 'Trinidad and Tobago dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        CZK: {
            name: 'tsjekkiske koruna',
            symbol_native: 'Kč',
            symbol: 'Kč',
            code: 'CZK',
            name_plural: 'Czech Republic korunas',
            rounding: 0,
            decimal_digits: 2,
        },
        TND: {
            name: 'tunisiske dinarer',
            symbol_native: 'د.ت.‏',
            symbol: 'DT',
            code: 'TND',
            name_plural: 'Tunisian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        TRY: {
            name: 'tyrkiske lire',
            symbol_native: 'TL',
            symbol: 'TL',
            code: 'TRY',
            name_plural: 'Turkish Lira',
            rounding: 0,
            decimal_digits: 2,
        },
        UGX: {
            name: 'ugandiske shilling',
            symbol_native: 'USh',
            symbol: 'USh',
            code: 'UGX',
            name_plural: 'Ugandan shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        UAH: {
            name: 'ukrainske hryvnia',
            symbol_native: '₴',
            symbol: '₴',
            code: 'UAH',
            name_plural: 'Ukrainian hryvnias',
            rounding: 0,
            decimal_digits: 2,
        },
        HUF: {
            name: 'ungarske forinter',
            symbol_native: 'Ft',
            symbol: 'Ft',
            code: 'HUF',
            name_plural: 'Hungarian forints',
            rounding: 0,
            decimal_digits: 0,
        },
        UYU: {
            name: 'uruguayanske pesos',
            symbol_native: '$',
            symbol: '$U',
            code: 'UYU',
            name_plural: 'Uruguayan pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        UZS: {
            name: 'usbekiske som',
            symbol_native: 'UZS',
            symbol: 'UZS',
            code: 'UZS',
            name_plural: 'Uzbekistan som',
            rounding: 0,
            decimal_digits: 0,
        },
        VEF: {
            name: 'venezuelanske bolivar',
            symbol_native: 'Bs.F.',
            symbol: 'Bs.F.',
            code: 'VEF',
            name_plural: 'Venezuelan bolívars',
            rounding: 0,
            decimal_digits: 2,
        },
        XOF: {
            name: 'vestafrikanske CFA-franc',
            symbol_native: 'CFA',
            symbol: 'CFA',
            code: 'XOF',
            name_plural: 'CFA francs BCEAO',
            rounding: 0,
            decimal_digits: 0,
        },
        VND: {
            name: 'vietnamesiske dong',
            symbol_native: '₫',
            symbol: '₫',
            code: 'VND',
            name_plural: 'Vietnamese dong',
            rounding: 0,
            decimal_digits: 0,
        },
        ZMK: {
            name: 'zambiske kwacha',
            symbol_native: 'ZK',
            symbol: 'ZK',
            code: 'ZMK',
            name_plural: 'Zambian kwachas',
            rounding: 0,
            decimal_digits: 0,
        },
        ZWL: {
            name: 'zimbabwisk dollar',
            symbol_native: 'ZWL$',
            symbol: 'ZWL$',
            code: 'ZWL',
            name_plural: 'Zimbabwean Dollar',
            rounding: 0,
            decimal_digits: 0,
        },
    },
    de: {
        AFN: {
            name: 'Afghanischer Afghani',
            symbol_native: '؋',
            symbol: 'Af',
            code: 'AFN',
            name_plural: 'Afghan Afghanis',
            rounding: 0,
            decimal_digits: 0,
        },
        ALL: {
            name: 'Albanischer Lek',
            symbol_native: 'Lek',
            symbol: 'ALL',
            code: 'ALL',
            name_plural: 'Albanian lekë',
            rounding: 0,
            decimal_digits: 0,
        },
        DZD: {
            name: 'Algerischer Dinar',
            symbol_native: 'د.ج.‏',
            symbol: 'DA',
            code: 'DZD',
            name_plural: 'Algerian dinars',
            rounding: 0,
            decimal_digits: 2,
        },
        ARS: {
            name: 'Argentinischer Peso',
            symbol_native: '$',
            symbol: 'AR$',
            code: 'ARS',
            name_plural: 'Argentine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        AMD: {
            name: 'Armenischer Dram',
            symbol_native: 'դր.',
            symbol: 'AMD',
            code: 'AMD',
            name_plural: 'Armenian drams',
            rounding: 0,
            decimal_digits: 0,
        },
        AZN: {
            name: 'Aserbaidschan-Manat',
            symbol_native: 'ман.',
            symbol: 'man.',
            code: 'AZN',
            name_plural: 'Azerbaijani manats',
            rounding: 0,
            decimal_digits: 2,
        },
        AUD: {
            name: 'Australischer Dollar',
            symbol_native: '$',
            symbol: 'AU$',
            code: 'AUD',
            name_plural: 'Australian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BHD: {
            name: 'Bahrain-Dinar',
            symbol_native: 'د.ب.‏',
            symbol: 'BD',
            code: 'BHD',
            name_plural: 'Bahraini dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        BDT: {
            name: 'Bangladesch-Taka',
            symbol_native: '৳',
            symbol: 'Tk',
            code: 'BDT',
            name_plural: 'Bangladeshi takas',
            rounding: 0,
            decimal_digits: 2,
        },
        BZD: {
            name: 'Belize-Dollar',
            symbol_native: '$',
            symbol: 'BZ$',
            code: 'BZD',
            name_plural: 'Belize dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BOB: {
            name: 'Bolivanischer Boliviano',
            symbol_native: 'Bs',
            symbol: 'Bs',
            code: 'BOB',
            name_plural: 'Bolivian bolivianos',
            rounding: 0,
            decimal_digits: 2,
        },
        BAM: {
            name: 'Bosnien und Herzegowina Konvertierbare Mark',
            symbol_native: 'KM',
            symbol: 'KM',
            code: 'BAM',
            name_plural: 'Bosnia-Herzegovina convertible marks',
            rounding: 0,
            decimal_digits: 2,
        },
        BWP: {
            name: 'Botswanischer Pula',
            symbol_native: 'P',
            symbol: 'BWP',
            code: 'BWP',
            name_plural: 'Botswanan pulas',
            rounding: 0,
            decimal_digits: 2,
        },
        BRL: {
            name: 'Brasilianischer Real',
            symbol_native: 'R$',
            symbol: 'R$',
            code: 'BRL',
            name_plural: 'Brazilian reals',
            rounding: 0,
            decimal_digits: 2,
        },
        GBP: {
            name: 'Britisches Pfund',
            symbol_native: '£',
            symbol: '£',
            code: 'GBP',
            name_plural: 'British pounds sterling',
            rounding: 0,
            decimal_digits: 2,
        },
        BND: {
            name: 'Brunei-Dollar',
            symbol_native: '$',
            symbol: 'BN$',
            code: 'BND',
            name_plural: 'Brunei dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        BGN: {
            name: 'Bulgarischer Lew',
            symbol_native: 'лв.',
            symbol: 'BGN',
            code: 'BGN',
            name_plural: 'Bulgarian leva',
            rounding: 0,
            decimal_digits: 2,
        },
        BIF: {
            name: 'Burundi-Franc',
            symbol_native: 'FBu',
            symbol: 'FBu',
            code: 'BIF',
            name_plural: 'Burundian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        XOF: {
            name: 'CFA-Franc',
            symbol_native: 'CFA',
            symbol: 'CFA',
            code: 'XOF',
            name_plural: 'CFA francs BCEAO',
            rounding: 0,
            decimal_digits: 0,
        },
        XAF: {
            name: 'CFA-Franc',
            symbol_native: 'FCFA',
            symbol: 'FCFA',
            code: 'XAF',
            name_plural: 'CFA francs BEAC',
            rounding: 0,
            decimal_digits: 0,
        },
        CVE: {
            name: 'Cabo-Verde-Escudo',
            symbol_native: 'CV$',
            symbol: 'CV$',
            code: 'CVE',
            name_plural: 'Cape Verdean escudos',
            rounding: 0,
            decimal_digits: 2,
        },
        CLP: {
            name: 'Chilenischer Peso',
            symbol_native: '$',
            symbol: 'CL$',
            code: 'CLP',
            name_plural: 'Chilean pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        CRC: {
            name: 'Costa-Rica-Colón',
            symbol_native: '₡',
            symbol: '₡',
            code: 'CRC',
            name_plural: 'Costa Rican colóns',
            rounding: 0,
            decimal_digits: 0,
        },
        DOP: {
            name: 'Dominikanischer Peso',
            symbol_native: 'RD$',
            symbol: 'RD$',
            code: 'DOP',
            name_plural: 'Dominican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        DJF: {
            name: 'Dschibuti-Franc',
            symbol_native: 'Fdj',
            symbol: 'Fdj',
            code: 'DJF',
            name_plural: 'Djiboutian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        DKK: {
            name: 'Dänische Krone',
            symbol_native: 'kr',
            symbol: 'Dkr',
            code: 'DKK',
            name_plural: 'Danish kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        ERN: {
            name: 'Eritreischer Nakfa',
            symbol_native: 'Nfk',
            symbol: 'Nfk',
            code: 'ERN',
            name_plural: 'Eritrean nakfas',
            rounding: 0,
            decimal_digits: 2,
        },
        EEK: {
            name: 'Estnische Krone',
            symbol_native: 'kr',
            symbol: 'Ekr',
            code: 'EEK',
            name_plural: 'Estonian kroons',
            rounding: 0,
            decimal_digits: 2,
        },
        EUR: {
            name: 'Euro',
            symbol_native: '€',
            symbol: '€',
            code: 'EUR',
            name_plural: 'euros',
            rounding: 0,
            decimal_digits: 2,
        },
        GEL: {
            name: 'Georgischer Lari',
            symbol_native: 'GEL',
            symbol: 'GEL',
            code: 'GEL',
            name_plural: 'Georgian laris',
            rounding: 0,
            decimal_digits: 2,
        },
        GHS: {
            name: 'Ghanaischer Cedi',
            symbol_native: 'GH₵',
            symbol: 'GH₵',
            code: 'GHS',
            name_plural: 'Ghanaian cedis',
            rounding: 0,
            decimal_digits: 2,
        },
        GTQ: {
            name: 'Guatemaltekischer Quetzal',
            symbol_native: 'Q',
            symbol: 'GTQ',
            code: 'GTQ',
            name_plural: 'Guatemalan quetzals',
            rounding: 0,
            decimal_digits: 2,
        },
        GNF: {
            name: 'Guinea-Franc',
            symbol_native: 'FG',
            symbol: 'FG',
            code: 'GNF',
            name_plural: 'Guinean francs',
            rounding: 0,
            decimal_digits: 0,
        },
        HNL: {
            name: 'Honduras-Lempira',
            symbol_native: 'L',
            symbol: 'HNL',
            code: 'HNL',
            name_plural: 'Honduran lempiras',
            rounding: 0,
            decimal_digits: 2,
        },
        HKD: {
            name: 'Hongkong-Dollar',
            symbol_native: '$',
            symbol: 'HK$',
            code: 'HKD',
            name_plural: 'Hong Kong dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        INR: {
            name: 'Indische Rupie',
            symbol_native: 'টকা',
            symbol: 'Rs',
            code: 'INR',
            name_plural: 'Indian rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        IDR: {
            name: 'Indonesische Rupiah',
            symbol_native: 'Rp',
            symbol: 'Rp',
            code: 'IDR',
            name_plural: 'Indonesian rupiahs',
            rounding: 0,
            decimal_digits: 0,
        },
        IQD: {
            name: 'Irakischer Dinar',
            symbol_native: 'د.ع.‏',
            symbol: 'IQD',
            code: 'IQD',
            name_plural: 'Iraqi dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        IRR: {
            name: 'Iranischer Rial',
            symbol_native: '﷼',
            symbol: 'IRR',
            code: 'IRR',
            name_plural: 'Iranian rials',
            rounding: 0,
            decimal_digits: 0,
        },
        ISK: {
            name: 'Isländische Krone',
            symbol_native: 'kr',
            symbol: 'Ikr',
            code: 'ISK',
            name_plural: 'Icelandic krónur',
            rounding: 0,
            decimal_digits: 0,
        },
        ILS: {
            name: 'Israelischer Neuer Schekel',
            symbol_native: '₪',
            symbol: '₪',
            code: 'ILS',
            name_plural: 'Israeli new sheqels',
            rounding: 0,
            decimal_digits: 2,
        },
        JMD: {
            name: 'Jamaika-Dollar',
            symbol_native: '$',
            symbol: 'J$',
            code: 'JMD',
            name_plural: 'Jamaican dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        JPY: {
            name: 'Japanischer Yen',
            symbol_native: '￥',
            symbol: '¥',
            code: 'JPY',
            name_plural: 'Japanese yen',
            rounding: 0,
            decimal_digits: 0,
        },
        YER: {
            name: 'Jemen-Rial',
            symbol_native: 'ر.ي.‏',
            symbol: 'YR',
            code: 'YER',
            name_plural: 'Yemeni rials',
            rounding: 0,
            decimal_digits: 0,
        },
        JOD: {
            name: 'Jordanischer Dinar',
            symbol_native: 'د.أ.‏',
            symbol: 'JD',
            code: 'JOD',
            name_plural: 'Jordanian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        KHR: {
            name: 'Kambodschanischer Riel',
            symbol_native: '៛',
            symbol: 'KHR',
            code: 'KHR',
            name_plural: 'Cambodian riels',
            rounding: 0,
            decimal_digits: 2,
        },
        CAD: {
            name: 'Kanadischer Dollar',
            symbol_native: '$',
            symbol: 'CA$',
            code: 'CAD',
            name_plural: 'Canadian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        KZT: {
            name: 'Kasachischer Tenge',
            symbol_native: 'тңг.',
            symbol: 'KZT',
            code: 'KZT',
            name_plural: 'Kazakhstani tenges',
            rounding: 0,
            decimal_digits: 2,
        },
        QAR: {
            name: 'Katar-Riyal',
            symbol_native: 'ر.ق.‏',
            symbol: 'QR',
            code: 'QAR',
            name_plural: 'Qatari rials',
            rounding: 0,
            decimal_digits: 2,
        },
        KES: {
            name: 'Kenia-Schilling',
            symbol_native: 'Ksh',
            symbol: 'Ksh',
            code: 'KES',
            name_plural: 'Kenyan shillings',
            rounding: 0,
            decimal_digits: 2,
        },
        COP: {
            name: 'Kolumbianischer Peso',
            symbol_native: '$',
            symbol: 'CO$',
            code: 'COP',
            name_plural: 'Colombian pesos',
            rounding: 0,
            decimal_digits: 0,
        },
        KMF: {
            name: 'Komoren-Franc',
            symbol_native: 'FC',
            symbol: 'CF',
            code: 'KMF',
            name_plural: 'Comorian francs',
            rounding: 0,
            decimal_digits: 0,
        },
        CDF: {
            name: 'Kongo-Franc',
            symbol_native: 'FrCD',
            symbol: 'CDF',
            code: 'CDF',
            name_plural: 'Congolese francs',
            rounding: 0,
            decimal_digits: 2,
        },
        HRK: {
            name: 'Kroatischer Kuna',
            symbol_native: 'kn',
            symbol: 'kn',
            code: 'HRK',
            name_plural: 'Croatian kunas',
            rounding: 0,
            decimal_digits: 2,
        },
        KWD: {
            name: 'Kuwait-Dinar',
            symbol_native: 'د.ك.‏',
            symbol: 'KD',
            code: 'KWD',
            name_plural: 'Kuwaiti dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        ZMK: {
            name: 'Kwacha',
            symbol_native: 'ZK',
            symbol: 'ZK',
            code: 'ZMK',
            name_plural: 'Zambian kwachas',
            rounding: 0,
            decimal_digits: 0,
        },
        LVL: {
            name: 'Lettischer Lats',
            symbol_native: 'Ls',
            symbol: 'Ls',
            code: 'LVL',
            name_plural: 'Latvian lati',
            rounding: 0,
            decimal_digits: 2,
        },
        LBP: {
            name: 'Libanesisches Pfund',
            symbol_native: 'ل.ل.‏',
            symbol: 'LB£',
            code: 'LBP',
            name_plural: 'Lebanese pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        LYD: {
            name: 'Libyscher Dinar',
            symbol_native: 'د.ل.‏',
            symbol: 'LD',
            code: 'LYD',
            name_plural: 'Libyan dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        LTL: {
            name: 'Litauischer Litas',
            symbol_native: 'Lt',
            symbol: 'Lt',
            code: 'LTL',
            name_plural: 'Lithuanian litai',
            rounding: 0,
            decimal_digits: 2,
        },
        MOP: {
            name: 'Macao-Pataca',
            symbol_native: 'MOP$',
            symbol: 'MOP$',
            code: 'MOP',
            name_plural: 'Macanese patacas',
            rounding: 0,
            decimal_digits: 2,
        },
        MGA: {
            name: 'Madagaskar-Ariary',
            symbol_native: 'MGA',
            symbol: 'MGA',
            code: 'MGA',
            name_plural: 'Malagasy Ariaries',
            rounding: 0,
            decimal_digits: 0,
        },
        MYR: {
            name: 'Malaysischer Ringgit',
            symbol_native: 'RM',
            symbol: 'RM',
            code: 'MYR',
            name_plural: 'Malaysian ringgits',
            rounding: 0,
            decimal_digits: 2,
        },
        MAD: {
            name: 'Marokkanischer Dirham',
            symbol_native: 'د.م.‏',
            symbol: 'MAD',
            code: 'MAD',
            name_plural: 'Moroccan dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        MUR: {
            name: 'Mauritius-Rupie',
            symbol_native: 'MURs',
            symbol: 'MURs',
            code: 'MUR',
            name_plural: 'Mauritian rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        MKD: {
            name: 'Mazedonischer Denar',
            symbol_native: 'MKD',
            symbol: 'MKD',
            code: 'MKD',
            name_plural: 'Macedonian denari',
            rounding: 0,
            decimal_digits: 2,
        },
        MXN: {
            name: 'Mexikanischer Peso',
            symbol_native: '$',
            symbol: 'MX$',
            code: 'MXN',
            name_plural: 'Mexican pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        MDL: {
            name: 'Moldau-Leu',
            symbol_native: 'MDL',
            symbol: 'MDL',
            code: 'MDL',
            name_plural: 'Moldovan lei',
            rounding: 0,
            decimal_digits: 2,
        },
        MZN: {
            name: 'Mosambikanischer Metical',
            symbol_native: 'MTn',
            symbol: 'MTn',
            code: 'MZN',
            name_plural: 'Mozambican meticals',
            rounding: 0,
            decimal_digits: 2,
        },
        MMK: {
            name: 'Myanmarischer Kyat',
            symbol_native: 'K',
            symbol: 'MMK',
            code: 'MMK',
            name_plural: 'Myanma kyats',
            rounding: 0,
            decimal_digits: 0,
        },
        NAD: {
            name: 'Namibia-Dollar',
            symbol_native: 'N$',
            symbol: 'N$',
            code: 'NAD',
            name_plural: 'Namibian dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NPR: {
            name: 'Nepalesische Rupie',
            symbol_native: 'नेरू',
            symbol: 'NPRs',
            code: 'NPR',
            name_plural: 'Nepalese rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        TWD: {
            name: 'Neuer Taiwan-Dollar',
            symbol_native: 'NT$',
            symbol: 'NT$',
            code: 'TWD',
            name_plural: 'New Taiwan dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NZD: {
            name: 'Neuseeland-Dollar',
            symbol_native: '$',
            symbol: 'NZ$',
            code: 'NZD',
            name_plural: 'New Zealand dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        NIO: {
            name: 'Nicaragua-Córdoba',
            symbol_native: 'C$',
            symbol: 'C$',
            code: 'NIO',
            name_plural: 'Nicaraguan córdobas',
            rounding: 0,
            decimal_digits: 2,
        },
        NGN: {
            name: 'Nigerianischer Naira',
            symbol_native: '₦',
            symbol: '₦',
            code: 'NGN',
            name_plural: 'Nigerian nairas',
            rounding: 0,
            decimal_digits: 2,
        },
        NOK: {
            name: 'Norwegische Krone',
            symbol_native: 'kr',
            symbol: 'Nkr',
            code: 'NOK',
            name_plural: 'Norwegian kroner',
            rounding: 0,
            decimal_digits: 2,
        },
        OMR: {
            name: 'Omanischer Rial',
            symbol_native: 'ر.ع.‏',
            symbol: 'OMR',
            code: 'OMR',
            name_plural: 'Omani rials',
            rounding: 0,
            decimal_digits: 3,
        },
        PKR: {
            name: 'Pakistanische Rupie',
            symbol_native: '₨',
            symbol: 'PKRs',
            code: 'PKR',
            name_plural: 'Pakistani rupees',
            rounding: 0,
            decimal_digits: 0,
        },
        PAB: {
            name: 'Panamaischer Balboa',
            symbol_native: 'B/.',
            symbol: 'B/.',
            code: 'PAB',
            name_plural: 'Panamanian balboas',
            rounding: 0,
            decimal_digits: 2,
        },
        PYG: {
            name: 'Paraguayischer Guaraní',
            symbol_native: '₲',
            symbol: '₲',
            code: 'PYG',
            name_plural: 'Paraguayan guaranis',
            rounding: 0,
            decimal_digits: 0,
        },
        PEN: {
            name: 'Peruanischer Sol',
            symbol_native: 'S/.',
            symbol: 'S/.',
            code: 'PEN',
            name_plural: 'Peruvian nuevos soles',
            rounding: 0,
            decimal_digits: 2,
        },
        PHP: {
            name: 'Philippinischer Peso',
            symbol_native: '₱',
            symbol: '₱',
            code: 'PHP',
            name_plural: 'Philippine pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        PLN: {
            name: 'Polnischer Złoty',
            symbol_native: 'zł',
            symbol: 'zł',
            code: 'PLN',
            name_plural: 'Polish zlotys',
            rounding: 0,
            decimal_digits: 2,
        },
        CNY: {
            name: 'Renminbi Yuan',
            symbol_native: 'CN¥',
            symbol: 'CN¥',
            code: 'CNY',
            name_plural: 'Chinese yuan',
            rounding: 0,
            decimal_digits: 2,
        },
        RWF: {
            name: 'Ruanda-Franc',
            symbol_native: 'FR',
            symbol: 'RWF',
            code: 'RWF',
            name_plural: 'Rwandan francs',
            rounding: 0,
            decimal_digits: 0,
        },
        RON: {
            name: 'Rumänischer Leu',
            symbol_native: 'RON',
            symbol: 'RON',
            code: 'RON',
            name_plural: 'Romanian lei',
            rounding: 0,
            decimal_digits: 2,
        },
        RUB: {
            name: 'Russischer Rubel',
            symbol_native: '₽',
            symbol: 'RUB',
            code: 'RUB',
            name_plural: 'Russian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        SAR: {
            name: 'Saudi-Rial',
            symbol_native: 'ر.س.‏',
            symbol: 'SR',
            code: 'SAR',
            name_plural: 'Saudi riyals',
            rounding: 0,
            decimal_digits: 2,
        },
        SEK: {
            name: 'Schwedische Krone',
            symbol_native: 'kr',
            symbol: 'Skr',
            code: 'SEK',
            name_plural: 'Swedish kronor',
            rounding: 0,
            decimal_digits: 2,
        },
        CHF: {
            name: 'Schweizer Franken',
            symbol_native: 'CHF',
            symbol: 'CHF',
            code: 'CHF',
            name_plural: 'Swiss francs',
            rounding: 0.05,
            decimal_digits: 2,
        },
        RSD: {
            name: 'Serbischer Dinar',
            symbol_native: 'дин.',
            symbol: 'din.',
            code: 'RSD',
            name_plural: 'Serbian dinars',
            rounding: 0,
            decimal_digits: 0,
        },
        ZWL: {
            name: 'Simbabwe-Dollar',
            symbol_native: 'ZWL$',
            symbol: 'ZWL$',
            code: 'ZWL',
            name_plural: 'Zimbabwean Dollar',
            rounding: 0,
            decimal_digits: 0,
        },
        SGD: {
            name: 'Singapur-Dollar',
            symbol_native: '$',
            symbol: 'S$',
            code: 'SGD',
            name_plural: 'Singapore dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        SOS: {
            name: 'Somalia-Schilling',
            symbol_native: 'Ssh',
            symbol: 'Ssh',
            code: 'SOS',
            name_plural: 'Somali shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        LKR: {
            name: 'Sri-Lanka-Rupie',
            symbol_native: 'SL Re',
            symbol: 'SLRs',
            code: 'LKR',
            name_plural: 'Sri Lankan rupees',
            rounding: 0,
            decimal_digits: 2,
        },
        SDG: {
            name: 'Sudanesisches Pfund',
            symbol_native: 'SDG',
            symbol: 'SDG',
            code: 'SDG',
            name_plural: 'Sudanese pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        SYP: {
            name: 'Syrisches Pfund',
            symbol_native: 'ل.س.‏',
            symbol: 'SY£',
            code: 'SYP',
            name_plural: 'Syrian pounds',
            rounding: 0,
            decimal_digits: 0,
        },
        ZAR: {
            name: 'Südafrikanischer Rand',
            symbol_native: 'R',
            symbol: 'R',
            code: 'ZAR',
            name_plural: 'South African rand',
            rounding: 0,
            decimal_digits: 2,
        },
        KRW: {
            name: 'Südkoreanischer Won',
            symbol_native: '₩',
            symbol: '₩',
            code: 'KRW',
            name_plural: 'South Korean won',
            rounding: 0,
            decimal_digits: 0,
        },
        TZS: {
            name: 'Tansania-Schilling',
            symbol_native: 'TSh',
            symbol: 'TSh',
            code: 'TZS',
            name_plural: 'Tanzanian shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        THB: {
            name: 'Thailändischer Baht',
            symbol_native: '฿',
            symbol: '฿',
            code: 'THB',
            name_plural: 'Thai baht',
            rounding: 0,
            decimal_digits: 2,
        },
        TOP: {
            name: 'Tongaischer Paʻanga',
            symbol_native: 'T$',
            symbol: 'T$',
            code: 'TOP',
            name_plural: 'Tongan paʻanga',
            rounding: 0,
            decimal_digits: 2,
        },
        TTD: {
            name: 'Trinidad und Tobago-Dollar',
            symbol_native: '$',
            symbol: 'TT$',
            code: 'TTD',
            name_plural: 'Trinidad and Tobago dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        CZK: {
            name: 'Tschechische Krone',
            symbol_native: 'Kč',
            symbol: 'Kč',
            code: 'CZK',
            name_plural: 'Czech Republic korunas',
            rounding: 0,
            decimal_digits: 2,
        },
        TND: {
            name: 'Tunesischer Dinar',
            symbol_native: 'د.ت.‏',
            symbol: 'DT',
            code: 'TND',
            name_plural: 'Tunisian dinars',
            rounding: 0,
            decimal_digits: 3,
        },
        TRY: {
            name: 'Türkische Lira',
            symbol_native: 'TL',
            symbol: 'TL',
            code: 'TRY',
            name_plural: 'Turkish Lira',
            rounding: 0,
            decimal_digits: 2,
        },
        USD: {
            name: 'US-Dollar',
            symbol_native: '$',
            symbol: '$',
            code: 'USD',
            name_plural: 'US dollars',
            rounding: 0,
            decimal_digits: 2,
        },
        UGX: {
            name: 'Uganda-Schilling',
            symbol_native: 'USh',
            symbol: 'USh',
            code: 'UGX',
            name_plural: 'Ugandan shillings',
            rounding: 0,
            decimal_digits: 0,
        },
        UAH: {
            name: 'Ukrainische Hrywnja',
            symbol_native: '₴',
            symbol: '₴',
            code: 'UAH',
            name_plural: 'Ukrainian hryvnias',
            rounding: 0,
            decimal_digits: 2,
        },
        HUF: {
            name: 'Ungarischer Forint',
            symbol_native: 'Ft',
            symbol: 'Ft',
            code: 'HUF',
            name_plural: 'Hungarian forints',
            rounding: 0,
            decimal_digits: 0,
        },
        UYU: {
            name: 'Uruguayischer Peso',
            symbol_native: '$',
            symbol: '$U',
            code: 'UYU',
            name_plural: 'Uruguayan pesos',
            rounding: 0,
            decimal_digits: 2,
        },
        UZS: {
            name: 'Usbekistan-Sum',
            symbol_native: 'UZS',
            symbol: 'UZS',
            code: 'UZS',
            name_plural: 'Uzbekistan som',
            rounding: 0,
            decimal_digits: 0,
        },
        AED: {
            name: 'VAE-Dirham',
            symbol_native: 'د.إ.‏',
            symbol: 'AED',
            code: 'AED',
            name_plural: 'UAE dirhams',
            rounding: 0,
            decimal_digits: 2,
        },
        VEF: {
            name: 'Venezolanischer Bolívar',
            symbol_native: 'Bs.F.',
            symbol: 'Bs.F.',
            code: 'VEF',
            name_plural: 'Venezuelan bolívars',
            rounding: 0,
            decimal_digits: 2,
        },
        VND: {
            name: 'Vietnamesischer Dong',
            symbol_native: '₫',
            symbol: '₫',
            code: 'VND',
            name_plural: 'Vietnamese dong',
            rounding: 0,
            decimal_digits: 0,
        },
        BYN: {
            name: 'Weißrussischer Rubel',
            symbol_native: 'руб.',
            symbol: 'Br',
            code: 'BYN',
            name_plural: 'Belarusian rubles',
            rounding: 0,
            decimal_digits: 2,
        },
        EGP: {
            name: 'Ägyptisches Pfund',
            symbol_native: 'ج.م.‏',
            symbol: 'EGP',
            code: 'EGP',
            name_plural: 'Egyptian pounds',
            rounding: 0,
            decimal_digits: 2,
        },
        ETB: {
            name: 'Äthiopischer Birr',
            symbol_native: 'Br',
            symbol: 'Br',
            code: 'ETB',
            name_plural: 'Ethiopian birrs',
            rounding: 0,
            decimal_digits: 2,
        },
    },
};

export default currencyList;
