type TMsGraphProfileData = {
    id: string;
    givenName: string | null;
    surname: string | null;
    userPrincipalName: string | null;
    mail: string | null;
    mobilePhone: string | null;
    businessPhones: string[];
    companyName: string | null;
    usageLocation: string | null;
};

export default class MsGraph {
    static readonly graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me?$select=companyName,givenName,surname,userPrincipalName,mobilePhone,id,usageLocation,mail,businessPhones';

    /**
     * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
     */
    static readonly getUserData = async (accessToken: string) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append('Authorization', bearer);

        const options = {
            method: 'GET',
            headers: headers,
        };

        const msGraphResponse = await fetch(MsGraph.graphMeEndpoint, options);

        return msGraphResponse.json() as Promise<TMsGraphProfileData>;
    };
}
