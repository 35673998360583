import { RegisterAction } from "../providers/FormProvider";

export default class RegisterActionHelper {
    static readonly isFreePlan = (registerAction: RegisterAction | null) => {
        switch (registerAction) {
            case RegisterAction.SignInFree:
            case RegisterAction.SignInNoImportFree:
            case RegisterAction.SignUpFree:
                return true;

            default:
                return false;
        }
    };

    static readonly isSignIn = (registerAction: RegisterAction | null) => {
        switch (registerAction) {
            case RegisterAction.SignIn:
            case RegisterAction.SignInFree:
            case RegisterAction.SignInNoImport:
            case RegisterAction.SignInNoImportFree:
                return true;

            default:
                return false;
        }
    };

    static readonly canShowImportDataOptions = (registerAction: RegisterAction | null, isOutlookClient?: boolean) => {
        if (isOutlookClient) {
            switch (registerAction) {
                case RegisterAction.SignIn:
                case RegisterAction.SignInFree:
                case RegisterAction.SignUpFree:
                case RegisterAction.SignUpPremium:
                    return true;

                default:
                    return false;
            }
        }

        // Is on web
        switch (registerAction) {
            case RegisterAction.SignIn:
            case RegisterAction.SignInFree:
                return true;

            default:
                return false;
        }
    };
}