import { mergeStyles } from '@fluentui/react';
import React from 'react';
import Header from './Header/Header';
import './PageBase.scss';

type TPageBaseProps = {
    maxContentWidth?: string;
    imgSrc?: string;
    imgSrcAlt?: string;
    imgComponent?: JSX.Element;
    imgClassName?: string;
    hideBackButton?: boolean;
};

const PageBase: React.FC<TPageBaseProps> = ({ children, maxContentWidth, imgSrc, imgComponent, imgClassName, hideBackButton, imgSrcAlt }) => {
    const hasImage = imgSrc || imgComponent || imgSrcAlt;

    return (
        <div className="page-base">
            <Header hideBackButton={hideBackButton}/>
            <div className="page-base__body">
                {(imgSrc || imgComponent) && 
                    <div className={mergeStyles("page-base__image-wrap", imgClassName)}>
                        {imgComponent ?? <img src={imgSrc} key={imgSrc} className="page-base__image page-base__fadein" alt="main" />}
                    </div>
                }
                <div className={hasImage ? "page-base__content-with-images" : "page-base__content-only"} style={maxContentWidth ? { maxWidth: maxContentWidth } : undefined}>
                    {children}
                </div>
                {imgSrcAlt && 
                    <div className="page-base__image-alt-wrap">
                        <img src={imgSrcAlt} key={imgSrcAlt} className="page-base__image page-base__fadein" alt="main" />
                    </div>
                }
            </div>
            <div className="page-base__footer" />
        </div>
    );
};

export default PageBase;
