import { Text, Link as FluentLink, ActionButton, Spinner, SpinnerSize, mergeStyles } from '@fluentui/react';
import { useNavigate } from 'react-router';
import RouteConfig from '../../../config/RouteConfig';
import Strings from '../../../localization/strings';
import './Header.scss';
import ewLogo from '@eway-crm/js-resources/assets/ewayLogo/eWayCRM/eWayCRM-red.svg';
import useLogin from '../../../helpers/hooks/useLogin';
import HeaderButtons from './HeaderButtons';
import { getEwayObject } from '../../../helpers/ewayObjectHelper';
import LanguageSwitch from './LanguageSwitch/LanguageSwitch';
import { useContext } from 'react';
import { LanguageContext } from '../../../providers/LanguageProvider';
import usePageMatch from '../../../helpers/hooks/usePageMatch';

const EWAY_HOMEPAGE_LINK = `https://www.eway-crm.com/`;

type THeaderProps = {
    hideBackButton?: boolean;
};

const Header: React.FC<THeaderProps> = ({ hideBackButton }) => {
    const myStrings = Strings.introPage;
    const { language } = useContext(LanguageContext);
    const isOnIntro = usePageMatch(RouteConfig.pages.intro);
    const isOnLogin = usePageMatch(RouteConfig.otherPages.login);
    const isOnDeletedWebServicePage = usePageMatch(RouteConfig.otherPages.wsUnavailable);
    const { isLoggingIn, onLogin } = useLogin();

    const navigate = useNavigate();

    const eway = getEwayObject();
    const isOutlook = eway.isOutlookClient();

    const logo = <a href={`${EWAY_HOMEPAGE_LINK}${language}`} target="_blank" rel="noreferrer"><img src={ewLogo} alt="eWay-CRM" className={mergeStyles('header__logo', isOutlook && 'is-outlook')} /></a>;

    return (
        <>
            <div className="header">
                {isOnIntro || isOnLogin || isOnDeletedWebServicePage ? (
                    <>
                        <div className={mergeStyles("header__logo-wrap", isOutlook && "is-outlook")}>{logo}</div>
                        <div className={mergeStyles('header__already-have-account-wrap', isOutlook && 'is-outlook')}>
                            {isOnIntro && (
                                <div className={mergeStyles('header__already-have-account', isOutlook && 'is-outlook')}>
                                    {isLoggingIn ? (
                                        <Spinner className="header__login-spinner" size={SpinnerSize.medium} />
                                    ) : (
                                        <Text className="header__already-have-account__text-wrap">
                                            <span>{myStrings.alreadyHaveAccount}</span>
                                            <FluentLink className="header__already-have-account__link" onClick={() => onLogin()}>
                                                {myStrings.logIn}
                                            </FluentLink>
                                        </Text>
                                    )}
                                </div>
                            )}
                            <LanguageSwitch />
                        </div>
                    </>
                ) : (
                    <>
                        {!hideBackButton ? (
                            <ActionButton className={'header__back-btn'} iconProps={{ iconName: 'Back' }} onClick={() => navigate(-1)}>
                                {Strings.back}
                            </ActionButton>
                        ) : (
                            <div />
                        )}
                        <div className={mergeStyles("header__logo-wrap", isOutlook && "is-outlook")}>{logo}</div>
                    </>
                )}
            </div>
            <HeaderButtons />
        </>
    );
};

export default Header;
