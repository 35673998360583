import teamSizeImg1 from '../../img/graphic-design/b_1-01.svg';
import teamSizeImg5 from '../../img/graphic-design/b_5-01.svg';
import teamSizeImg10 from '../../img/graphic-design/b_10.svg';
import teamSizeImg25 from '../../img/graphic-design/b_25-01.svg';
import teamSizeImg50 from '../../img/graphic-design/b_50-01.svg';
import teamSizeImg200 from '../../img/graphic-design/b_200-01.svg';
import teamSizeImg1000 from '../../img/graphic-design/b_1000-01.svg';
import teamSizeImg10000 from '../../img/graphic-design/b_10000-01.svg';
import teamSizeImgMore from '../../img/graphic-design/b_more-01.svg';
import newOutlookForWindowsEnImg from '../../img/graphic-design/NewOutlookForWindows_en.svg';
import newOutlookForWindowsCsImg from '../../img/graphic-design/NewOutlookForWindows_cs.svg';
import classicOutlookForWindowsImg from '../../img/graphic-design/OutlookClassicForWindows.svg';
import outlookForMacImg from '../../img/graphic-design/OutlookForMac.svg';
import outlookOnlineImg from '../../img/graphic-design/OutlookOnline.svg';
import noneOutlookYet from '../../img/graphic-design/NoneOutlookYet.svg';
import bronzeImg from '../../img/graphic-design/Bronze.svg';
import silverImg from '../../img/graphic-design/Silver.svg';
import goldImg from '../../img/graphic-design/Gold.svg';
import moduleCompaniesImg from '../../img/graphic-design/modules/Companies.svg';
import moduleEmailsImg from '../../img/graphic-design/modules/Emails.svg';
import moduleLeadsImg from '../../img/graphic-design/modules/Leads.svg';
import moduleMarketingImg from '../../img/graphic-design/modules/Marketing.svg';
import moduleProjectsImg from '../../img/graphic-design/modules/Projects.svg';
import { ReactComponent as LanguageBubbleImg } from '../../img/graphic-design/language_bubble.svg';
import { FormContext } from '../../providers/FormProvider';
import { useRef, useContext, useEffect } from 'react';
import { allCompanyIndustries } from './data/companyIndustry';
import industryImgDefault from '../../img/graphic-design/povolani_select_all-02-01-01.svg';
import { OUTLOOK_TYPE_GUIDS } from './data/getCompanyInfoTwoDropdownOptions';
import LocalizationHelper from '../../localization/LocalizationHelper';
import { PURPOSE_GUIDS } from './data/getCompanyInfoDropdownOptions';
import { mergeStyles } from '@fluentui/react';

const CompanyPageGraphics: React.FC = () => {
    const { companyInfoFormik, companyInfoTwoFormik } = useContext(FormContext);
    const { industry, teamSize, language, purpose } = companyInfoFormik.values;
    const { crmExperience, outlookType, role } = companyInfoTwoFormik.values;
    const languageBubbleRef = useRef<SVGSVGElement>(null);

    const industryObj = allCompanyIndustries.find((ci) => ci.itemGuid === industry?.key);
    const industryImg = industryObj?.img ?? industryImgDefault;

    let teamSizeImg = null;
    switch (teamSize?.key) {
        case 1: {
            teamSizeImg = teamSizeImg1;
            break;
        }
        case 5: {
            teamSizeImg = teamSizeImg5;
            break;
        }
        case 10: {
            teamSizeImg = teamSizeImg10;
            break;
        }
        case 25: {
            teamSizeImg = teamSizeImg25;
            break;
        }
        case 50: {
            teamSizeImg = teamSizeImg50;
            break;
        }
        case 200: {
            teamSizeImg = teamSizeImg200;
            break;
        }
        case 1000: {
            teamSizeImg = teamSizeImg1000;
            break;
        }
        case 10000: {
            teamSizeImg = teamSizeImg10000;
            break;
        }
        case 10001: {
            teamSizeImg = teamSizeImgMore;
            break;
        }
        default: {
            teamSizeImg = null;
            break;
        }
    }

    let crmExperienceImg = null;
    switch (crmExperience?.key) {
        case 1: {
            crmExperienceImg = null;
            break;
        }
        case 2: {
            crmExperienceImg = bronzeImg;
            break;
        }
        case 3: {
            crmExperienceImg = silverImg;
            break;
        }
        case 4: {
            crmExperienceImg = goldImg;
            break;
        }
        default: {
            crmExperienceImg = null;
            break;
        }
    }

    let outlookTypeImg = null;
    switch (outlookType[0]) {
        case OUTLOOK_TYPE_GUIDS.desktopClassic:
            outlookTypeImg = classicOutlookForWindowsImg;
            break;
        case OUTLOOK_TYPE_GUIDS.desktopNew:
            outlookTypeImg = LocalizationHelper.isLanguageCs() ? newOutlookForWindowsCsImg : newOutlookForWindowsEnImg;
            break;
        case OUTLOOK_TYPE_GUIDS.mac:
            outlookTypeImg = outlookForMacImg;
            break;
        case OUTLOOK_TYPE_GUIDS.online:
            outlookTypeImg = outlookOnlineImg;
            break;
        case OUTLOOK_TYPE_GUIDS.noneYet:
            outlookTypeImg = noneOutlookYet;
            break;
    }


    useEffect(() => {
        if (!language) {
            return;
        }
        const languageBubbleTextElm = languageBubbleRef.current?.querySelector('.language-bubble-text');
        if (languageBubbleTextElm) {
            let languageText = '';
            switch (language) {
                case 'cs':
                case 'sk': {
                    languageText = 'Ahoj!';
                    break;
                }
                case 'de': {
                    languageText = 'Hallo!';
                    break;
                }
                case 'no': {
                    languageText = 'Hei!';
                    break;
                }
                case 'ru': {
                    languageText = 'Привет!';
                    break;
                }
                case 'en':
                default: {
                    languageText = 'Hi!';
                    break;
                }
            }

            languageBubbleTextElm.innerHTML = languageText;
            if (language === 'ru') {
                languageBubbleTextElm.classList.add('language-bubble-text__sm');
            } else {
                languageBubbleTextElm.classList.remove('language-bubble-text__sm');
            }

            if (['cs', 'sk', 'de'].includes(language)) {
                languageBubbleTextElm.classList.add('language-bubble-text__md');
            } else {
                languageBubbleTextElm.classList.remove('language-bubble-text__md');
            }
        }
    }, [language]);

    return (
        <div className="company-info-page__graphic page-base__fadein">
            <img className="company-info-page__graphic__industry" src={industryImg} alt="Industry" />
            {teamSizeImg && <img className="company-info-page__graphic__teamsize" src={teamSizeImg} alt="Team Size" />}
            <LanguageBubbleImg className="company-info-page__graphic__language-bubble" ref={languageBubbleRef} />
            {outlookTypeImg && <img src={outlookTypeImg} className="company-info-page__graphic__outlook-type" alt="Outlook Type" />}
            {role && (
                <div className={"company-info-page__graphic__role"}>
                    <div className={"company-info-page__graphic__role-text"}>{role}</div>
                    {crmExperienceImg ?
                        <img src={crmExperienceImg} className={"company-info-page__graphic__role-crm-experience"} alt="CRM Experience" />
                        : <div className={"company-info-page__graphic__role-crm-experience--empty"} />
                    }
                </div>
            )}
            <div className="company-info-page__graphic__purpose">
                <PurposeImg src={moduleCompaniesImg} isOn={purpose.includes(PURPOSE_GUIDS.contactsAndCompanies)} />
                <PurposeImg src={moduleEmailsImg} isOn={purpose.includes(PURPOSE_GUIDS.trackingActivities)} />
                <PurposeImg src={moduleLeadsImg} isOn={purpose.includes(PURPOSE_GUIDS.managingSales)} />
                <PurposeImg src={moduleProjectsImg} isOn={purpose.includes(PURPOSE_GUIDS.managingJobs)} />
                <PurposeImg src={moduleMarketingImg} isOn={purpose.includes(PURPOSE_GUIDS.emailMarketing)} />
            </div>
        </div>
    );
};

type TPurposeImgProps = {
    src: string;
    isOn?: boolean;
};

const PurposeImg: React.FC<TPurposeImgProps> = ({ src, isOn }) => {
    return <img src={src} className={mergeStyles("company-info-page__graphic__purpose-img", !isOn && "company-info-page__graphic__purpose-img--off")} alt="Purpose" />;
};

export default CompanyPageGraphics;